import React from 'react';

import styled from 'styled-components';

import { RecommendationType } from 'types/recommendations';

import RecommendationCard from './RecommendationCard';
import RecommendationsNotFound from './RecommendationsNotFound';

const CardContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

interface RecommendationListProps {
  data: RecommendationType[] | undefined;
}

const RecommendationList = ({ data }: RecommendationListProps) => {
  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return <RecommendationsNotFound />;
  }

  return (
    <CardContainer>
      {data.map((r) => (
        <RecommendationCard key={r.id} recommendation={r} />
      ))}
    </CardContainer>
  );
};

export default RecommendationList;
