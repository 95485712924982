import React from 'react';

import styled from 'styled-components';

import Button from 'components/lib/Button';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const LeftButton = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  left: -30px;
  top: -5px;
  cursor: pointer;
`;

const RightButton = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  right: 0px;
  top: -5px;
  cursor: pointer;
`;

const Label = styled.div`
  overflow: hidden;
  max-width: 180px;
  word-break: break-word;
`;

type Props = {
  index: number;
  community: any;
  filteredCompetitorsCount: number;
  totalCompetitorsCount: number;
  disableLeft: boolean;
  disableRight: boolean;
  displayedNumberOfCommunities: number;
  scrollLeft: () => void;
  scrollRight: () => void;
};

const CMASummaryTableColumnHeader = ({
  index,
  community,
  filteredCompetitorsCount,
  totalCompetitorsCount,
  disableLeft,
  disableRight,
  displayedNumberOfCommunities,
  scrollLeft,
  scrollRight,
}: Props) => {
  let leftButton, rightButton;
  if (totalCompetitorsCount > displayedNumberOfCommunities) {
    leftButton = (
      <LeftButton onClick={scrollLeft} disabled={disableLeft}>
        <img src="/icons/chevron-left.svg" style={{ width: '24px', height: '24px' }} />
      </LeftButton>
    );
    rightButton = (
      <RightButton onClick={scrollRight} disabled={disableRight}>
        <img src="/icons/chevron-right.svg" style={{ width: '24px', height: '24px' }} />
      </RightButton>
    );
  }

  return (
    <Container title={community?.comunity_name}>
      {index === 0 && leftButton}
      <Label>{community?.comunity_name}</Label>
      {index === filteredCompetitorsCount - 1 && rightButton}
    </Container>
  );
};

export default CMASummaryTableColumnHeader;
