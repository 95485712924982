import React, { useRef, useState } from 'react';

import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';
import useArrayBoundary from 'utils/hooks/useArrayBoundary';

import CMASummaryStyledTable from './CMASummaryStyledTable';
import { generateCMASummaryTableColumns, getAntdRowClassName } from './CMASummaryTableHelper';
import { INITIAL_DISPLAYED_NUMBER_OF_COMMUNITIES } from './constants';
import { useCMASummaryTableStickyHeader, useSetNumberOfDisplayedCommunities } from './customHooks';
import { CMASummaryFilters } from './types';
import { prepareCMASummaryDataRowsForAntdTable } from './utils';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin: 15px 20px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  background: white;
  padding-top: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  font-size: 18px;
  font-family: var(--font-bold);
  padding-left: 20px;
`;

const CMASummaryTable = () => {
  const { community, competitors, CMASummaryData, viewFilters } = useCommunityViewContext();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [displayedNumberOfCommunities, setDisplayedNumberOfCommunities] = useState<number>(
    INITIAL_DISPLAYED_NUMBER_OF_COMMUNITIES
  );
  const tableRef = useRef<HTMLInputElement>(null);

  const filters = viewFilters as CMASummaryFilters;
  const filteredCompetitors = filters?.competitors?.length
    ? competitors.filter((competitor) => filters?.competitors.includes(competitor.id))
    : competitors;

  const {
    minimum: competitorsMinIndex,
    maximum: competitorsMaxIndex,
    moveLeft: scrollCompetitorsLeft,
    moveRight: scrollCompetitorsRight,
    resetMinimum,
  } = useArrayBoundary({ initialMinimum: 0, initialMaximum: displayedNumberOfCommunities - 1 });
  useSetNumberOfDisplayedCommunities(tableRef, (i: number) => {
    setDisplayedNumberOfCommunities(i);
    resetMinimum();
  });
  useCMASummaryTableStickyHeader(competitorsMaxIndex);

  const diplayedCompetitors = filteredCompetitors?.filter(
    (competitor: any, index: number) => index >= competitorsMinIndex && index <= competitorsMaxIndex
  );

  const columns = generateCMASummaryTableColumns({
    community,
    competitors: filteredCompetitors,
    filteredCompetitors: diplayedCompetitors,
    scrollCompetitorsLeft,
    scrollCompetitorsRight,
    expandedRowKeys,
    setExpandedRowKeys,
    competitorsMinIndex,
    competitorsMaxIndex,
    displayedNumberOfCommunities,
  });

  return (
    <Container ref={tableRef}>
      <Title>CMA Summary</Title>
      <CMASummaryStyledTable
        dataSource={prepareCMASummaryDataRowsForAntdTable(expandedRowKeys, CMASummaryData?.slice(1))}
        columns={columns}
        pagination={false}
        rowClassName={getAntdRowClassName}
        scroll={{ y: 'max-content' }}
        rowKey="key"
      />
    </Container>
  );
};

export default CMASummaryTable;
