import React from 'react';

import dayjs from 'dayjs';

import { useAppSelector } from 'store/hook';

import { LivingInfoDataType, NOTE_MAXIMUM_LENGTH } from './constants';
import { EditableTable } from '../../../common/Table';
import { INPUT_TYPES } from '../../../constants';
import { CareFeeDateType, CommunityFeeDateType, FEE_NOTE_MAXIMUM_LENGTH } from '../Fees/constants';

interface Props {
  loading: boolean;
  data: LivingInfoDataType[];
  livingTypeName: string;
  onCreate: (record: Partial<LivingInfoDataType>) => void;
  onUpdate: (record: Partial<LivingInfoDataType>) => void;
  onDelete: (record: Partial<LivingInfoDataType>) => void;
}

const LivingInfoDataTable = ({ loading, data, livingTypeName, onCreate, onUpdate, onDelete }: Props) => {
  const apartment_types = useAppSelector((state) => state.global.apartmenttypes);

  const accommodation_options = apartment_types.map((val) => ({
    label: val.type,
    value: val.id,
  }));

  const uniqueApartmentTypeValidator = async (record: LivingInfoDataType) => {
    const filteredData = data.filter((entry) => entry.id != record?.id);

    const hasLivingTypeEntry = filteredData.some((entry) => entry.apartmentTypeId === record.apartmentTypeId);
    if (hasLivingTypeEntry) {
      return Promise.reject('This entry matches an existing record with the same Accommodation.');
    }

    return Promise.resolve();
  };

  const futureDatesValidator = async (record: LivingInfoDataType) => {
    const isFutureDate = record.date > dayjs().endOf('day');
    if (isFutureDate) {
      return Promise.reject('Future dates are not acceptable. Choose a valid date.');
    }
    return Promise.resolve();
  };

  const notesLengthValidator = async (record: LivingInfoDataType) => {
    if (record.notes?.length > NOTE_MAXIMUM_LENGTH) {
      return Promise.reject(`Note length exceed maximum size of ${NOTE_MAXIMUM_LENGTH} characters.`);
    }
    return Promise.resolve();
  };

  const columns = [
    {
      title: 'Accommodation',
      dataIndex: 'apartmentTypeId',
      inputType: INPUT_TYPES.SELECT,
      options: accommodation_options,
      editable: true,
      required: true,
      width: '340px',
      validator: uniqueApartmentTypeValidator,
    },
    {
      title: 'Square Footage',
      dataIndex: 'squareFootage',
      inputType: INPUT_TYPES.NUMBER_POSITIVE,
      editable: true,
      width: '200px',
    },
    {
      title: 'Rate',
      dataIndex: 'baseRent',
      inputType: INPUT_TYPES.CURRENCY,
      required: true,
      editable: true,
      width: '200px',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      inputType: INPUT_TYPES.TEXTAREA,
      editable: true,
      validator: notesLengthValidator,
    },
    {
      title: 'Data Collected Date',
      dataIndex: 'date',
      inputType: 'date',
      editable: true,
      width: '200px',
      validator: futureDatesValidator,
    },
  ];

  return (
    <EditableTable
      loading={loading}
      columns={columns}
      data={data}
      onAdd={onCreate}
      onUpdate={onUpdate}
      onDelete={onDelete}
    />
  );
};

export default LivingInfoDataTable;
