import React, { useState } from 'react';

import { Input, Tag, Tooltip } from 'antd';
import styled from 'styled-components';

import { EMAIL_REGEX } from '../../constants';

const EmailInputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 4px;
  border: solid 1px var(--line-gray);
  background-color: var(--background);
  border-radius: 0px;
  &:hover {
    border-color: var(--input-border);
  }
  .ant-tag.ant-tag-has-color .anticon-close {
    color: var(--email-blue);
    &:hover {
      color: var(--email-blue);
    }
  }
`;
const StyledEmailInput = styled(Input)`
  border: none;
  background-color: var(--background);
  flex: 1;
  &:focus {
    box-shadow: none;
    background-color: var(--background);
  }
`;

const Error = styled.p`
  font-size: 10px;
  color: var(--error-red);
`;

const InputMultipleEmails = (props: any) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { emails, setEmails } = props;

  const handleCloseTag = (removedEmail: string) => {
    const newEmails = emails.filter((email: string) => email !== removedEmail);
    setEmails(newEmails);
  };

  const handleNewEmailsChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && validateEmail(inputValue)) {
      setEmails([...emails, inputValue]);
      setInputValue('');
      setError('');
    } else if (inputValue) {
      setError('Invalid email address');
    }
  };

  const validateEmail = (email: string) => {
    return EMAIL_REGEX.test(email);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === ',' || e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      handleInputConfirm();
    }
  };

  const getPlaceholderText = () => {
    if (emails.length > 0) {
      return '';
    }
    return 'Type in an email here, then press Tab or Return';
  };

  return (
    <>
      <EmailInputContainer>
        {emails.map((email: string) => {
          const isLongEmail = email.length > 20;
          const emailElem = (
            <Tag
              key={email}
              style={{ color: 'var(--email-blue)' }}
              closable
              onClose={() => handleCloseTag(email)}
              color={'var(--background-light-blue)'}
            >
              {isLongEmail ? `${email.slice(0, 20)}...` : email}
            </Tag>
          );
          return isLongEmail ? (
            <Tooltip title={email} key={email}>
              {emailElem}
            </Tooltip>
          ) : (
            emailElem
          );
        })}
        <StyledEmailInput
          placeholder={getPlaceholderText()}
          type="text"
          onChange={handleNewEmailsChange}
          value={inputValue}
          onPressEnter={handleInputConfirm}
          onBlur={handleInputConfirm}
          onKeyDown={handleKeyDown}
        />
      </EmailInputContainer>
      {error && <Error>{error}</Error>}
    </>
  );
};

export default InputMultipleEmails;
