import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { getCMASummary } from 'apis/CMASummaryAPI';
import FiltersControls from 'common/FiltersControls/FiltersControls';
import { CMA_SUMMARY_INITIAL_FILTERS } from 'components/CMASummary/constants';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';
import CMASummarySaveFilterModal from 'components/View/modals/CMASummarySaveFilterModal';
import { useAppSelector } from 'store/hook';

const CMASummaryFiltersControls = () => {
  const { setViewFilters } = useCommunityViewContext();
  const { selectedCompany } = useAppSelector((state) => state.global);
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);

  const { data: CMASummary } = useQuery({
    queryKey: ['CMASummary', selectedCompany],
    queryFn: () => {
      const requestCMASummaries = async () => {
        const response = await getCMASummary(selectedCompany!.id);
        if (response?.results?.length) {
          const cmaSummary = response.results[0];
          return cmaSummary;
        }
      };
      return requestCMASummaries();
    },
    enabled: Boolean(selectedCompany?.id),
    refetchOnWindowFocus: false,
  });

  return (
    <FiltersControls
      modal={
        <CMASummarySaveFilterModal
          CMASummary={CMASummary}
          open={openSaveModal}
          onCancel={() => setOpenSaveModal(false)}
        />
      }
      showSaveFilter
      disableSaveFilter={false}
      onReset={() => setViewFilters(CMA_SUMMARY_INITIAL_FILTERS)}
      onSaveFilter={() => setOpenSaveModal(true)}
    />
  );
};

export default CMASummaryFiltersControls;
