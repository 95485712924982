import React from 'react';
import { useParams } from 'react-router-dom';

import RecommendationDetails from './RecommendationDetails';
import { RecommendationDetailsProvider } from './RecommendationDetailsContext';

const RecommendationDetailsRoot = () => {
  const { recommendationId } = useParams();

  return (
    <RecommendationDetailsProvider recommendationId={Number(recommendationId)}>
      <RecommendationDetails />
    </RecommendationDetailsProvider>
  );
};

export default RecommendationDetailsRoot;
