import { useEffect } from 'react';

import { FormInstance } from 'antd';
import dayjs from 'dayjs';

import { INCENTIVE_TYPE, SIGNATURE_DOCUMENT_TYPES } from './constants';
import {
  INCENTIVES_FEES_TYPE,
  INCENTIVES_SAVINGS_CONTENT_TYPE,
  INCENTIVES_SAVINGS_DISCOUNT_TYPE,
} from './create/constants';
import { PROPOSAL_DURATION } from './proposal/constants';
import { IncentiveSavingType, IncentiveType, ProposalType, UnitType } from './types';

type Props = {
  form: FormInstance<any>;
  incentive: IncentiveType | undefined;
  selectedProposalUnit: UnitType | undefined;
  selectedProposal: ProposalType | undefined;
  readOnly?: boolean;
};

const useIncentiveInitialFormValues = ({
  form,
  incentive,
  selectedProposalUnit,
  selectedProposal,
  readOnly,
}: Props) => {
  useEffect(() => {
    const alwaysSelectedRates = [INCENTIVES_FEES_TYPE.MONTHLY_RENT];
    const selectedIncentiveProposalRates =
      incentive?.savings?.map((item: IncentiveSavingType) =>
        item.type === INCENTIVES_FEES_TYPE.CUSTOM ? item.name : item.type
      ) ?? [];
    const selectedProposalRates = [...alwaysSelectedRates, ...selectedIncentiveProposalRates];
    const hasAdditionalDiscount = selectedProposal?.incentive?.type === INCENTIVE_TYPE.ADDITIONAL_DISCOUNT;

    // Preselect highest possible proposal length up to seven days
    const hasEndDate = Boolean(selectedProposal?.expires_at);

    const proposalDuration = hasEndDate
      ? Math.round(dayjs(selectedProposal?.expires_at).diff(dayjs(selectedProposal?.created_at), 'day', true))
      : PROPOSAL_DURATION.SEVEN;

    let signatureDocumentsInitialFormValues = selectedProposalUnit?.community?.signature_documents
      ? selectedProposalUnit.community.signature_documents.map((document) => document.id)
      : undefined;

    // uncheck Addendum i if there is no incentive
    if (!incentive && !hasAdditionalDiscount && signatureDocumentsInitialFormValues) {
      const incentiveAddendumDocument = selectedProposalUnit!.community!.signature_documents!.find(
        (document) => document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_INCENTIVE
      );
      if (incentiveAddendumDocument) {
        signatureDocumentsInitialFormValues = signatureDocumentsInitialFormValues.filter(
          (documentId) => documentId !== incentiveAddendumDocument.id
        );
      }
    }
    // uncheck Addendum P if Rates Pet Fee is unchecked
    if (!form.getFieldValue('rates').includes(INCENTIVES_FEES_TYPE.PET_FEE) && signatureDocumentsInitialFormValues) {
      const petFeeAddendumDocument = selectedProposalUnit!.community!.signature_documents!.find(
        (document) => document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_P
      );
      if (petFeeAddendumDocument && signatureDocumentsInitialFormValues) {
        signatureDocumentsInitialFormValues = signatureDocumentsInitialFormValues.filter(
          (documentId) => documentId !== petFeeAddendumDocument.id
        );
      }
    }

    // uncheck Addendum E from intially checked fields
    const motorizedAddendumDocument = selectedProposalUnit?.community?.signature_documents?.find(
      (document) => document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_E
    );
    if (motorizedAddendumDocument && signatureDocumentsInitialFormValues) {
      signatureDocumentsInitialFormValues = signatureDocumentsInitialFormValues.filter(
        (documentId) => documentId !== motorizedAddendumDocument.id
      );
    }

    let additional_discount: any = { enabled: false };
    if (hasAdditionalDiscount) {
      const customAmountSavings =
        selectedProposal?.incentive?.savings?.filter(
          (savings) =>
            savings.type === INCENTIVES_SAVINGS_CONTENT_TYPE.CUSTOM &&
            savings.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT
        ) || [];
      // On the backend side we treat the additional saving as all the other savings.
      // We don't distinguish between them and the other savings of the same incentive.
      // BUT we know for sure that the additionDiscountSavings is appended AFTER other savings
      // so it has the most recent `created_at`, and it comes last in the array.
      const additionalAmountSavings = customAmountSavings.length
        ? customAmountSavings[customAmountSavings.length - 1]
        : null;

      if (additionalAmountSavings) {
        additional_discount = {
          enabled: true,
          amount: additionalAmountSavings.discount,
          description: additionalAmountSavings.name,
        };
      }
    }

    form.setFieldsValue({
      lead: readOnly && selectedProposal ? selectedProposal.lead : form.getFieldValue('lead'),
      resident:
        readOnly && selectedProposal?.resident
          ? { ...selectedProposal.resident, enabled: true }
          : form.getFieldValue('resident'),
      second_person:
        readOnly && selectedProposal?.second_person
          ? { ...selectedProposal.second_person, enabled: true }
          : form.getFieldValue('second_person'),
      rates: readOnly && selectedProposal ? selectedProposal.pricing.map((rate) => rate.type) : selectedProposalRates,
      time: {
        limit: Boolean(incentive),
        duration: proposalDuration,
      },
      signature_documents_ids:
        readOnly && selectedProposal?.signature_documents_ids
          ? selectedProposal?.signature_documents_ids
          : signatureDocumentsInitialFormValues,
      additional_discount,
    });
  }, [incentive, selectedProposal, selectedProposalUnit, readOnly, form]);
};

export default useIncentiveInitialFormValues;
