import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { WIDGET_FILTER_OPTIONS, WIDGET_FILTER_TYPES } from '../../../common/Widgets/constants';
import SelectFilter from '../../lib/SelectFilter';
import { useCommunityViewContext } from '../CommunityViewContext';
import { ViewFilters } from '../types';

const Label = styled.span`
  font-family: var(--font-bold);
`;

const HeaderViewFiltersTime = () => {
  const { viewFilters, setViewFilters } = useCommunityViewContext();
  const filters = viewFilters as ViewFilters;

  const handleChange = (value: number) => {
    setViewFilters((prevState: any) => ({ ...prevState, time: value }));
  };

  const options = WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.TIME].map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <SelectFilter
      className={classNames({ active: Boolean(filters?.time) })}
      variant="borderless"
      value={filters?.time}
      placeholder={'Time'}
      options={options}
      placement="bottomLeft"
      onChange={handleChange}
      labelRender={({ label }: any) => (
        <>
          <Label>{'Time: '}</Label>
          {label}
        </>
      )}
    />
  );
};

export default HeaderViewFiltersTime;
