import React from 'react';

import styled from 'styled-components';

type Props = {
  title: string;
  addon?: React.ReactNode;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  position: relative;

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-image: linear-gradient(to right, var(--line-gray) 0%, var(--background-gray-light));
    position: absolute;
    bottom: 0;
  }
`;

const Title = styled.p`
  color: var(--text-primary);
  font-size: 20px;
  font-family: var(--font-bold);
`;

const Addon = styled.div``;

const Header = ({ title, addon }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      {addon && <Addon>{addon}</Addon>}
    </Container>
  );
};

export default Header;
