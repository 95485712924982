import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { getOccupancies } from '../../../../apis/CommunityAPI';
import { useCareTypesContext } from '../CareTypesContext';
import { calculateOccupancyStats } from '../utils';

const Container = styled.div`
  font-size: 14px;
  color: var(--text-secondary);
  margin-right: 16px;
`;

const Value = styled.span`
  font-size: 20px;
  color: var(--text-primary);
`;

const Percentage = styled.span`
  color: var(--text-primary);
`;

const Text = styled.span`
  color: var(--text-secondary);
  padding-right: 10px;
`;

const AccomodationOccupancy = () => {
  const { careType } = useCareTypesContext();
  const { competitorId } = useParams();
  const { communityId } = useParams();
  const id = Number(competitorId || communityId);

  const { data, isLoading } = useQuery({
    queryKey: ['community', 'occupancy', id, careType],
    queryFn: () => getOccupancies(id, { active: 1, living_type: careType?.id }),
    enabled: Boolean(id),
  });

  const hasData = data?.length > 0;

  if (!hasData || isLoading) {
    return null;
  }

  const occupancyStats = calculateOccupancyStats(data);

  return (
    <Container>
      <Value>{occupancyStats.totalUnits - occupancyStats.freeUnits}</Value>/<Value>{occupancyStats.totalUnits}</Value>
      <Text> units occupied </Text>
      <Value>{occupancyStats.occupancyPercentage.toFixed(1)}</Value>
      <Percentage>%</Percentage>
      <Text> occupancy</Text>
    </Container>
  );
};

export default AccomodationOccupancy;
