import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { Outlet } from 'react-router-dom';

import { Typography } from 'antd';
import styled from 'styled-components';

import { getCMASummary } from 'apis/CMASummaryAPI';

import { PublicationContext } from './PublicationContext';
import PublicSidebar from './PublicSidebar';
import { getPublication, getViews } from '../../apis/ViewAPI';
import Loader from '../lib/Loader';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  overflow: auto;
`;

const EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  align-self: center;
`;

const Publication = () => {
  const navigate = useNavigate();
  const { hash } = useParams();

  const { data: publicationData, isLoading: publicationLoading } = useQuery({
    queryKey: ['publication', hash],
    queryFn: () => getPublication(hash),
    refetchOnWindowFocus: false,
    enabled: Boolean(hash),
  });

  const { data: viewsData, isLoading: viewsLoading } = useQuery({
    queryKey: ['views', hash, publicationData],
    queryFn: () => getViews(publicationData.community_id, { ids: publicationData.views }),
    enabled: Boolean(publicationData) && Boolean(publicationData.community_id) && Boolean(publicationData.views),
  });

  useEffect(() => {
    if (viewsData && viewsData.length > 0) {
      navigate(`/public/${hash}/view/${viewsData[0].id}`);
    }
  }, [viewsData]);


  const showLoading = publicationLoading || viewsLoading;

  return (
    <Container>
      <Loader active={showLoading} />
      {!showLoading && (
        <PublicationContext.Provider
          value={{
            publication: publicationData,
            views: viewsData,
          }}
        >
          <PublicSidebar />
          <ContentContainer>
            {viewsData?.length === 0 && (
              <EmptyMessage>
                <Typography.Title level={3}>Published views could not be found</Typography.Title>
                <Typography.Text type="secondary">
                  This could happen due to views being modified or removed.
                </Typography.Text>
              </EmptyMessage>
            )}
            {viewsData?.length > 0 && <Outlet />}
          </ContentContainer>
        </PublicationContext.Provider>
      )}
    </Container>
  );
};

export default Publication;
