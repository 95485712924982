import React from 'react';

import styled from 'styled-components';

import { useCommunityIncentivesContext } from './CommunityIncentivesContext';
import CommunityIncentivesStyledTable from './CommunityIncentivesStyledTable';
import CommunityIncentivesTableActions from './CommunityIncentivesTableActions';
import CommunityIncentivesTableAttachments from './CommunityIncentivesTableAttachments';
import CommunityIncentivesTableDate from './CommunityIncentivesTableDate';
import CommunityIncentivesTableIncentives from './CommunityIncentivesTableIncentives';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
`;

const CommunityIncentivesTable = () => {
  const { data, isLoading, isFetching } = useCommunityIncentivesContext();

  const columns = [
    {
      title: 'Incentive',
      width: '40%',
      key: 'name',
      render: (value: any, record: any) => <CommunityIncentivesTableIncentives item={record} />,
    },
    {
      title: 'Attachments',
      width: '30%',
      render: (value: any, record: any) => <CommunityIncentivesTableAttachments item={record} />,
    },
    {
      title: 'Added',
      sorter: true,
      key: 'created_at',
      render: (value: any, record: any) => <CommunityIncentivesTableDate item={record} />,
    },
    {
      title: '',
      width: 100,
      render: (value: any, record: any) => <CommunityIncentivesTableActions item={record} />,
    },
  ];

  return (
    <Container>
      <CommunityIncentivesStyledTable
        columns={columns}
        dataSource={data || []}
        loading={isLoading || isFetching}
        rowKey={(record: any) => record.id}
        showSorterTooltip={false}
        pagination={false}
      />
    </Container>
  );
};

export default CommunityIncentivesTable;
