import React from 'react';

import styled from 'styled-components';

import CMASummaryDownloadCSV from 'components/CMASummary/CMASummaryDownloadCSV';

import HeaderViewActionsControls from './HeaderViewActionsControls';
import HeaderViewActionsShare from './HeaderViewActionsShare';
import HeaderViewActionsSwot from './HeaderViewActionsSwot';
import { useCommunityViewContext } from '../CommunityViewContext';
import CommunityViewAddWidget from '../create/CommunityViewAddWidget';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const HeaderViewActions = () => {
  const { view, preview, isViewCMA } = useCommunityViewContext();

  return (
    <Container>
      {!isViewCMA && view && !view.default && !preview && <CommunityViewAddWidget />}
      <HeaderViewActionsShare />
      {isViewCMA && <CMASummaryDownloadCSV />}
      {!isViewCMA && !preview && <HeaderViewActionsSwot />}
      {!isViewCMA && !preview && <HeaderViewActionsControls />}
    </Container>
  );
};

export default HeaderViewActions;
