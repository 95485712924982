import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import { CommunityIncentivesType } from './types';
import LabelsList from '../../common/List/LabelsList';

const { Paragraph } = Typography;

const Container = styled.span`
  display: flex;
  margin: 2px 0px 10px 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.span`
  padding-top: 4px;
  padding-right: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  font-size: 12px;
`;

const Description = styled(Paragraph)`
  font-family: var(--font-regular);
  font-size: 12px;
  text-align: justify;
  line-height: normal;
  color: var(--text-secondary);
  white-space: break-spaces;
`;

const ElipsisSymbol = styled.span`
  color: var(--link-blue);
  cursor: pointer;
`;

type Props = {
  item: CommunityIncentivesType;
};

const CommunityIncentivesTableIncentives = ({ item }: Props) => {
  const livingTypes = useAppSelector((state) => state.global.livingtypes);

  const careTypes = livingTypes
    .filter((livingType) => item.living_types.includes(livingType.id))
    .map((livingType) => livingType.type);

  return (
    <Container>
      <Icon>
        <img
          src={'/icons/price-tag-green.svg'}
          alt="Incentive"
          style={{
            minWidth: 24,
            minHeight: 24,
          }}
        />
      </Icon>

      <Content>
        <Info>
          <LabelsList options={careTypes ?? []} labelPlural={'care types'} />
        </Info>
        <Description
          ellipsis={{
            rows: 2,
            expandable: 'collapsible',
            symbol: (expanded: boolean) => <ElipsisSymbol>{expanded ? 'Show Less' : 'Show More'}</ElipsisSymbol>,
          }}
        >
          {item?.description}
        </Description>
      </Content>
    </Container>
  );
};

export default CommunityIncentivesTableIncentives;
