import dayjs from 'dayjs';

import { getFormattedDate } from 'helpers/dates';

import { MYSTERY_SHOP_MODAL_REQUEST_FROM } from './constants';
import {
  MysteryShopRequestGroup,
  MysteryShopRequestInbound,
  MysteryShopRequestOutbound,
  MysteryShopRequestsBatch,
  ShopRequestCreationObject,
  ShopRequestsFiltersType,
} from './types';

export const createShopRequestOutbound = (
  requestGroupName: string,
  base_community_id: number | undefined,
  company_id: number | undefined,
  repeat: number,
  endRepeat: number,
  shopRequests: ShopRequestCreationObject[]
): MysteryShopRequestGroup => ({
  name: requestGroupName,
  base_community_id,
  company_id,
  repeat,
  end_repeat: endRepeat,
  shop_requests: shopRequests.map((shopRequest) => ({
    community_id: shopRequest.communityId,
    independent_living: shopRequest.independentLiving,
    assisted_living: shopRequest.assistedLiving,
    memory_care: shopRequest.memoryCare,
    admin_note: shopRequest.adminNote,
    due_date: shopRequest.dueDate,
    shopper_id: shopRequest.shopperId,
    shopper_note: shopRequest.shopperNote,
    status: shopRequest.status,
    website_review: false,
    fee_information: false,
    apartment_starting_rates: false,
    basic_floor_plan_rates: false,
    floor_plans_sq_footage: false,
    care_pricing_type: false,
    care_pricing: false,
    specials_incentives: false,
    typical_rate_increase_annaully: false,
    email_correspondence_application_attachments: false,
    respite_fee_by_care_type: false,
    rate_documentation: false,
    occupancy_data_per_care_level: false,
    sample_menu_activity_calendar: false,
    company_id,
  })),
});

export const transformShopRequestOutbound = (shopRequest: MysteryShopRequestInbound): MysteryShopRequestOutbound => ({
  community_id: shopRequest.community.id,
  independent_living: shopRequest.independentLiving,
  assisted_living: shopRequest.assistedLiving,
  memory_care: shopRequest.memoryCare,
  admin_note: shopRequest.adminNote,
  due_date: shopRequest.dueDate,
  repeat: shopRequest.repeat,
  end_repeat: shopRequest.endRepeat,
  shopper_id: shopRequest.shopper?.userId || null,
  shopper_note: shopRequest.shopperNote,
  status: shopRequest.status,
  website_review: shopRequest.website_review,
  fee_information: shopRequest.fee_information,
  apartment_starting_rates: shopRequest.apartment_starting_rates,
  basic_floor_plan_rates: shopRequest.basic_floor_plan_rates,
  floor_plans_sq_footage: shopRequest.floor_plans_sq_footage,
  care_pricing_type: shopRequest.care_pricing_type,
  care_pricing: shopRequest.care_pricing,
  specials_incentives: shopRequest.specials_incentives,
  typical_rate_increase_annaully: shopRequest.typical_rate_increase_annaully,
  email_correspondence_application_attachments: shopRequest.email_correspondence_application_attachments,
  respite_fee_by_care_type: shopRequest.respite_fee_by_care_type,
  rate_documentation: shopRequest.rate_documentation,
  occupancy_data_per_care_level: shopRequest.occupancy_data_per_care_level,
  sample_menu_activity_calendar: shopRequest.sample_menu_activity_calendar,
});

export const getShopRequestName = (name: string | null, requestFrom: number, selectedBaseCommunity: any): string => {
  if (name && name?.length) {
    return name;
  } else if (requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY && selectedBaseCommunity?.comunity_name) {
    return `${selectedBaseCommunity.comunity_name}'s Competitors`;
  }
  return `Request from ${getFormattedDate(new Date(), 'MMM D, YYYY')}`;
};

export const getInitials = (name: string): string => {
  const matches = name.match(/(\b\S)/g);
  if (!matches) {
    return '';
  }
  return matches.join('').toUpperCase();
};

export const getShopRequestGroupsWithKeys = (groups: MysteryShopRequestGroup[] | null) => {
  if (!groups) {
    return [];
  }

  return groups.map((group) => ({
    ...group,
    key: getShopRequestGroupKey(group),
    shopRequests: group.shop_requests!.map((request) => ({
      ...request,
      key: getShopRequestKey(request),
    })),
  }));
};

export const getShopRequestKey = (shopRequest: MysteryShopRequestOutbound) => `shopRequest-${shopRequest.id}`;
export const getShopRequestGroupKey = (group: MysteryShopRequestGroup) => `shopRequestGroup-${group.id}`;

export const getShopRequestListingPayload = (
  filters: Partial<ShopRequestsFiltersType>,
  ordering: string | undefined
) => {
  const payload: any = {
    search: filters.search,
    page: filters.page,
    page_size: filters.pageSize,
    status: filters.status,
    shoppers: filters.shoppers,
    due_date_start: filters.dueDateStart ? dayjs(filters.dueDateStart).format('YYYY-MM-DD') : undefined,
    due_date_end: filters.dueDateEnd ? dayjs(filters.dueDateEnd).format('YYYY-MM-DD') : undefined,
    due_date_range: filters.dueDateRange,
    request_from: filters.requestFrom,
    group_by: filters.groupBy,
    order_by: ordering,
  };

  if (filters.requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY) {
    payload.company_ids = filters.companyIds;
  }

  return payload;
};

export const transformBatchShopRequestOutbound = (
  batchShopRequest: Partial<MysteryShopRequestsBatch>
): Partial<MysteryShopRequestsBatch> => {
  const { shopper_id, ...rest } = batchShopRequest;

  return {
    ...rest,
    ...(shopper_id === -1 ? { shopper_id: null } : shopper_id !== undefined ? { shopper_id } : {}),
  };
};
