import React from 'react';

import classNames from 'classnames';

import { useAppSelector } from '../../../store/hook';
import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useCommunityIncentivesContext } from '../CommunityIncentivesContext';

const CommunityIncentivesFiltersCareType = () => {
  const livingTypes = useAppSelector((state) => state.global.livingtypes);
  const { filters, setFilters } = useCommunityIncentivesContext();

  const handleSelect = (selection: any[]) => {
    const values = selection.map((item: any) => item.value);
    setFilters({ ...filters, careTypes: values });
  };

  const options = livingTypes?.map((item: any) => ({
    label: item.type,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: Boolean(filters.careTypes?.length) })}
      placeholder={'Care Type'}
      options={options}
      defaultValue={filters?.careTypes ?? []}
      onSelect={handleSelect}
    />
  );
};

export default CommunityIncentivesFiltersCareType;
