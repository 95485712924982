import React from 'react';

import classNames from 'classnames';

import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';

type Props = {
  competitors: number[];
  options: { label: string; value: number; color: string }[];
};

const CompetitorsFilter = ({ competitors, options }: Props) => {
  const { setViewFilters } = useCommunityViewContext();

  const handleSelect = (values: number[]) => {
    setViewFilters((prevState: any) => ({ ...prevState, competitors: values.map((item: any) => item.value) }));
  };

  return (
    <SelectAdvancedFilter
      className={classNames({ active: competitors?.length > 0 })}
      placeholder={'Competitors'}
      options={options}
      defaultValue={competitors}
      onSelect={handleSelect}
    />
  );
};

export default CompetitorsFilter;
