import React from 'react';

import styled from 'styled-components';

import ShopRequestsCompanyFilter from './ShopRequestsCompanyFilter';
import ShopRequestsDueDateEndFilter from './ShopRequestsDueDateEndFilter';
import ShopRequestsDueDateStartFilter from './ShopRequestsDueDateStartFilter';
import ShopRequestGroupByFilter from './ShopRequestsGroupByFilter';
import ShopRequestsRequestsFromFilter from './ShopRequestsRequestFromFilter';
import ShopRequestsShopperFilter from './ShopRequestsShopperFilter';
import ShopRequestsStatusFilter from './ShopRequestsStatusFilter';
import {
  MYSTERY_SHOP_MODAL_REQUEST_FROM,
  SHOP_REQUEST_COLUMNS,
  SHOP_REQUEST_DUE_DATE_RANGES,
  SHOP_REQUESTS_PAGE_SIZE,
} from '../constants';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';

const Container = styled.div`
  margin: 20px 0 20px 30px;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const Link = styled.span`
  cursor: pointer;
  font-size: 10px;
  color: var(--text-secondary);
  &.active {
    color: var(--active-blue);
  }
  &.disabled {
    pointer-events: none;
  }
`;

const ResetContainer = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid var(--line-gray);
  margin-left: 10px;
  padding-left: 10px;
  height: 12px;
`;

const ShopRequestFilters = () => {
  const { filters, ordering, setFilters, setOrdering } = useShopRequestsDataContext();

  const resetFilters = () => {
    setFilters({
      page: 1,
      pageSize: SHOP_REQUESTS_PAGE_SIZE,
      search: '',
      status: [],
      requestFrom: MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY,
      shoppers: [],
      companyIds: [],
      dueDateStart: undefined,
      dueDateEnd: undefined,
      dueDateRange: SHOP_REQUEST_DUE_DATE_RANGES.DUE_THIS_WEEK,
      groupBy: undefined,
    });
    if (ordering !== SHOP_REQUEST_COLUMNS.dueDate.id) {
      setOrdering(SHOP_REQUEST_COLUMNS.dueDate.id);
    }
  };

  return (
    <Container>
      <ShopRequestGroupByFilter />
      <ShopRequestsStatusFilter />
      <ShopRequestsShopperFilter />
      <ShopRequestsDueDateStartFilter />
      <ShopRequestsDueDateEndFilter />
      <ShopRequestsRequestsFromFilter />
      {filters.requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY && <ShopRequestsCompanyFilter />}
      <ResetContainer>
        <Link onClick={resetFilters}>Reset</Link>
      </ResetContainer>
    </Container>
  );
};

export default ShopRequestFilters;
