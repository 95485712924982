import React from 'react';

import { Collapse } from 'antd';
import styled from 'styled-components';

const AccomodationGroupStyledCollapse = styled(Collapse)`
  &.ant-collapse {
    background-color: var(--gray-medium);
    border-radius: 0px;
    border: 1px solid var(--line-gray);
  }
  &.ant-collapse-large > .ant-collapse-item > .ant-collapse-header {
    padding-right: 0px;
    align-items: center;
    height: 60px;
  }
  &.ant-collapse-large > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export default AccomodationGroupStyledCollapse;
