import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { BrochureAndDocsContext } from './BrochureAndDocsContext';
import BrochureAndDocsThumbnails from './BrochureAndDocsThumbnails';
import { getCommunityBrochureAndDocs } from '../../../../apis/CommunityAPI';
import { FILE_SOURCE } from '../../../../constants';
import Header from '../../common/Header';

const Container = styled.div`
  position: relative;
`;

const Content = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const FileWrapper = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
`;

const FileName = styled.span`
  box-sizing: border-box;
  width: 134px;
  padding: 4px;
  font-size: 12px;
  color: var(--text-primary);
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
`;

const BrochureAndDocs = () => {
  const [brochureAndDocs, setBrochureAndDocs] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  const { competitorId } = useParams();
  const { communityId } = useParams();
  const id = Number(competitorId || communityId);

  const { data } = useQuery({
    queryKey: ['community', 'brochures', 'documents', id, selectedCategory],
    queryFn: () =>
      getCommunityBrochureAndDocs(id, { file_category: selectedCategory || null, source: [FILE_SOURCE.MEDIA] }),
    onSuccess: (data) => {
      setBrochureAndDocs(data);
    },
    enabled: Boolean(id),
  });

  const hasData = data?.length > 0;

  if (!hasData) {
    return null;
  }

  return (
    <BrochureAndDocsContext.Provider
      value={{
        brochureAndDocs,
        selectedCategory,
        setSelectedCategory,
        setBrochureAndDocs,
      }}
    >
      <Container>
        <Header title="Brochures and Documents" />
        <Content>
          {data.map((file: any, index: number) => (
            <FileWrapper key={file.id} href={file.file_url} target="_blank" rel="noopener noreferrer">
              <BrochureAndDocsThumbnails file={file} />
              <FileName title={file.file_name}>{file.file_name}</FileName>
            </FileWrapper>
          ))}
        </Content>
      </Container>
    </BrochureAndDocsContext.Provider>
  );
};

export default BrochureAndDocs;
