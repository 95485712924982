import React from 'react';

import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import CMASummaryTableDataValuesDifference from './CMASummaryTableDataValuesDifference';
import { CMASummaryFilters } from './types';
import { parseCMASummaryFeeData } from './utils';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

type Props = {
  careFeeLevel: number;
};

const CMASummaryTableDataCareFees = ({ careFeeLevel }: Props) => {
  const { viewFilters } = useCommunityViewContext();
  const filters = viewFilters as CMASummaryFilters;
  const [careFeeLevelValue, feeLevelDifference] = parseCMASummaryFeeData(
    careFeeLevel,
    filters?.viewOptions?.valueDisplay
  );

  return (
    <div>
      {typeof careFeeLevelValue == 'number' && (
        <Title>
          ${careFeeLevelValue.toLocaleString()} <CMASummaryTableDataValuesDifference difference={feeLevelDifference} />
        </Title>
      )}
      {typeof careFeeLevelValue != 'number' && '-'}
    </div>
  );
};

export default CMASummaryTableDataCareFees;
