import React from 'react';

import styled from 'styled-components';

import { useCareTypesContext } from './CareTypesContext';
import FeesItems from './common/FeesItems';
import { FEES_CONFIGURATIONS, FREQUENCY_OPTIONS } from './constants';
import Header from '../common/Header';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommunityFees = () => {
  const { careType } = useCareTypesContext();
  const communityFee = careType?.communityFee;

  const communityFeeEquivalent = FREQUENCY_OPTIONS.find((item) => item.value == communityFee?.frequency)?.label;
  const depositEquivalent = FREQUENCY_OPTIONS.find(
    (item) => item.value == communityFee?.deposit_equivalent_factor
  )?.label;

  const showCommunityFee = communityFee?.community_fee || communityFeeEquivalent;
  const showDeposit = communityFee?.deposit || depositEquivalent;
  const deposit = !depositEquivalent ? communityFee?.deposit : undefined;

  if (!communityFee) {
    return null;
  }

  return (
    <Container>
      <Header title="Community Fees" />
      <Content>
        {showCommunityFee && (
          <FeesItems title="Community Fee" amount={communityFee?.community_fee} subtitle={communityFeeEquivalent} />
        )}
        {FEES_CONFIGURATIONS.map(
          ({ title, key, subtitle }, index) =>
            communityFee[key] && (
              <FeesItems key={index} title={title} amount={communityFee[key] as number} subtitle={subtitle || ''} />
            )
        )}
        {showDeposit && <FeesItems title="Deposit Fee" amount={deposit} subtitle={depositEquivalent} />}
      </Content>
    </Container>
  );
};

export default CommunityFees;
