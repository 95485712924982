import React from 'react';

import { Breadcrumb } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from '../../../store/hook';

const StyledBreadcrumb = styled(Breadcrumb)`
  span {
    color: var(--text-primary);

    &.competitor {
      color: var(--competitor-purple);
      text-transform: capitalize;
    }

    &.community {
      color: var(--positive-green);
      text-transform: capitalize;
    }
  }
`;

const HeaderBreadcrumbs = () => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  return (
    <StyledBreadcrumb
      items={[
        {
          title: selectedCommunity?.name,
        },
        {
          className: selectedCommunity?.type,
          title: selectedCommunity?.type,
        },
      ]}
      separator="·"
    />
  );
};

export default HeaderBreadcrumbs;
