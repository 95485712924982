import React, { useState } from 'react';

import classNames from 'classnames';

import AggregationTypePicker from 'components/lib/AggregationTypePicker';

import { WIDGET_FILTER_OPTIONS, WIDGET_FILTER_TYPES } from '../../../common/Widgets/constants';
import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useCommunityViewContext } from '../CommunityViewContext';
import { ViewFilters } from '../types';

const HeaderViewFiltersCareLevel = () => {
  const { viewFilters, setViewFilters } = useCommunityViewContext();
  const filters = viewFilters as ViewFilters;
  const [otherOptions, setOtherOptions] = useState<number>(filters?.careLevelsStatsType);

  const handleSelect = (values: number[]) => {
    setViewFilters((prevState: any) => ({
      ...prevState,
      careLevels: values.map((item: any) => item.value),
      careLevelsStatsType: otherOptions,
    }));
  };

  const options = WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.CARE_LEVEL].map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: filters?.careLevels?.length > 0 })}
      placeholder={'Care Level'}
      options={options}
      otherOptions={<AggregationTypePicker aggregationType={otherOptions} onSelect={setOtherOptions} />}
      defaultValue={filters?.careLevels ?? []}
      onSelect={handleSelect}
    />
  );
};

export default HeaderViewFiltersCareLevel;
