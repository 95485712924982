import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { useLatestShopNotesContext } from './LatestShopNotesContext';

const { Paragraph } = Typography;

const Text = styled(Paragraph)`
  font-size: 14px;
  color: var(--text-secondary);
  white-space: break-spaces;
`;

const ElipsisSymbol = styled.span`
  color: var(--link-blue);
  cursor: pointer;
`;

const LatestShopNotesSingleParagraphText = () => {
  const { latestShopNotes } = useLatestShopNotesContext();
  const text = latestShopNotes?.request_notes;

  return (
    <Text
      ellipsis={{
        rows: 4,
        expandable: 'collapsible',
        symbol: (expanded: boolean) => <ElipsisSymbol>{expanded ? 'Show Less' : 'Show More'}</ElipsisSymbol>,
      }}
    >
      {text}
    </Text>
  );
};

export default LatestShopNotesSingleParagraphText;
