import React from 'react';

import styled from 'styled-components';

import { LivingInfoDataType } from '../types';

type Props = {
  item: LivingInfoDataType;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px 0 15px;
  border-bottom: solid 1px var(--line-gray);
  box-sizing: border-box;
`;

const Column = styled.div`
  margin-left: 40px;
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary);
`;

const Area = styled.div`
  font-family: var(--font-regular);
  font-size: 12px;
  color: var(--text-secondary);
`;

const Price = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--positive-green);
`;

const AccomodationGroupCollapseItem = ({ item }: Props) => {
  return (
    <Container>
      <Column>
        <Title>{item.apartment_type}</Title>
        {item.sq_footage && <Area>{item.sq_footage} sq ft</Area>}
      </Column>
      {item.base_rent && <Price>${item.base_rent}</Price>}
    </Container>
  );
};

export default AccomodationGroupCollapseItem;
