import React from 'react';

import styled from 'styled-components';

import RecommendationMarkdown from './RecommendationMarkdown';

const Container = styled.div`
  display: flex;
  gap: 15px;
  font-family: var(--font-regular);
`;

interface MarkerProps {
  variant: 'problem' | 'recommendation';
}

const Marker = styled.div<MarkerProps>`
  width: 5px;
  height: inherit;
  border-radius: 4px;
  background-image: ${({ variant }) =>
    variant === 'problem'
      ? 'linear-gradient(to bottom, #9898ff, var(--competitor-purple))'
      : 'linear-gradient(to bottom, #17dcae, var(--positive-green))'};
`;

interface RecommendationReasoningProps {
  variant: 'problem' | 'recommendation';
  description: string;
}

const RecommendationReasoning = ({ variant, description }: RecommendationReasoningProps) => {
  return (
    <Container>
      <Marker variant={variant} />
      <RecommendationMarkdown>{description}</RecommendationMarkdown>
    </Container>
  );
};

export default RecommendationReasoning;
