import React, { createContext, useContext } from 'react';

import { CommunityIncentivesFiltersType, CommunityIncentivesType } from './types';

type CommunityIncentivesContent = {
  data: CommunityIncentivesType[];
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
  filters: Partial<CommunityIncentivesFiltersType>;
  setFilters: (filters: any) => void;
};

export const CommunityIncentivesContext = createContext<CommunityIncentivesContent>({
  data: [],
  isLoading: false,
  isFetching: false,
  filters: {
    careTypes: [],
  },
  refetch: () => {
    // Do nothing
  },
  setFilters: () => {
    // Do nothing
  },
});

export const useCommunityIncentivesContext = () => useContext(CommunityIncentivesContext);
