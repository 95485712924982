import React, { createContext, useContext } from 'react';

import { LivingInfoGroupedDataType } from '../types';

type AccomodationContentType = {
  livingInfoData: LivingInfoGroupedDataType[] | [];
};

export const AccomodationContext = createContext<AccomodationContentType>({
  livingInfoData: [],
});

export const useAccomodationContext = () => useContext(AccomodationContext);
