import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import OverviewHeaderBreadcrumbs from './OverviewHeaderBreadcrumbs';
import Header from '../../common/Header';
import { useAppSelector } from '../../store/hook';
import Button from '../lib/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 0px;
`;

const OverviewHeader = () => {
  const { selectedManagementCompany, selectedCommunity } = useAppSelector((state) => state.global);
  const { currentUser } = useAppSelector((state) => state.auth);
  const showAddButtons = currentUser?.isStaff || currentUser?.isSuperuser || currentUser?.manager;
  return (
    <Container>
      <Header
        title={selectedCommunity?.name || selectedManagementCompany?.management_company || ''}
        breadcrumbs={<OverviewHeaderBreadcrumbs />}
      >
        {showAddButtons && (
          <Buttons>
            <Link to={'add-community'}>
              <Button type="primary" style={{ width: 162, height: 40 }}>
                Add My Community
              </Button>
            </Link>
            <Link to={'add-competitor'}>
              <Button type="primary" style={{ width: 162, height: 40 }}>
                Add Competitor
              </Button>
            </Link>
          </Buttons>
        )}
      </Header>
    </Container>
  );
};

export default OverviewHeader;
