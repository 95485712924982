import React from 'react';

import styled from 'styled-components';

import CMASummaryFiltersMenu from 'components/CMASummary/CMASummaryFiltersMenu';
import CMASummaryFiltersControls from 'components/CMASummary/filters/CMASummaryFiltersControls';

import HeaderViewFiltersControls from './HeaderViewFiltersControls';
import HeaderViewFiltersMenu from './HeaderViewFiltersMenu';
import { useCommunityViewContext } from '../CommunityViewContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  align-items: center;
`;

const HeaderViewFilters = () => {
  const { view, preview, isViewCMA } = useCommunityViewContext();
  return (
    <Container>
      {!isViewCMA && <HeaderViewFiltersMenu />}
      {isViewCMA && <CMASummaryFiltersMenu />}
      {view?.default && !preview && <HeaderViewFiltersControls />}
      {isViewCMA && !preview && <CMASummaryFiltersControls />}
    </Container>
  );
};

export default HeaderViewFilters;
