import React from 'react';

import styled from 'styled-components';

import { useIncentivesContext } from 'components/Incentives/IncentivesContext';
import { ProposalType } from 'components/Incentives/types';

interface Props {
  record: ProposalType;
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const LeadName = styled.div`
  font-family: var(--font-bold);
  color: var(--dark-blue);
  cursor: pointer;
`;

const LeadInfo = styled.div`
  color: var(--text-secondary);
`;

const IncentivesTableProposalsRecipient = ({ record }: Props) => {
  const { setSelectedProposalUnit, setSelectedProposal } = useIncentivesContext();

  const openProposalModal = () => {
    setSelectedProposal(record);
    setSelectedProposalUnit({ ...record.unit, incentive_id: record.incentive.id });
  };

  return (
    <>
      {record.lead && (
        <Container>
          <LeadName onClick={openProposalModal}>
            {record.lead.first_name} {record.lead.last_name}
          </LeadName>
          <LeadInfo>{record.lead.email}</LeadInfo>
          <LeadInfo>{record.lead.phone}</LeadInfo>
        </Container>
      )}
    </>
  );
};

export default IncentivesTableProposalsRecipient;
