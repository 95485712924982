import { uuid } from 'short-uuid';

import { WIDGET_FILTER_OPTIONS, WIDGET_FILTER_TYPES, WIDGET_TYPES } from 'common/Widgets/constants';
import { DataItemType } from 'common/Widgets/Graph/types';
import { WidgetType } from 'common/Widgets/types';
import { AGGREGATION_TYPE } from 'components/View/constants';
import { LivingType } from 'pageComponents/AddCommunityCompetitor/accommodation/constants';
import { ApartmentTypeGroups } from 'store/slices/globalSlice';

import {
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW,
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON,
  CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW,
  CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON,
  CMA_SUMMARY_CARE_FEE_DATA_ROW,
  CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW,
  CMA_SUMMARY_DATA_FEE_INCENTIVE_ROW_TYPE,
  CMA_SUMMARY_DATA_ROW_TYPE,
  CMA_SUMMARY_LIVING_TYPE_DATA_ROW,
  CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW,
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS,
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS,
  CMA_SUMMARY_ROOM_DATA_FILTER_TYPES,
  CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE,
  INDEPENDENT_LIVING_TYPE,
} from './constants';
import { CMASummaryFilterField, CMASummaryFilters, CMASummaryIncentive } from './types';
import { CARE_FEES_TYPES, LEVELS } from '../../pages/price_healing/Fees/constants';
import { CommunityBaseIncentiveType } from '../CommunityInfo/types';

export const isRowExpandable = (cmaSummaryRowType: string) => {
  return [CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW, CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW].includes(cmaSummaryRowType);
};

const addCMALivingTypeRows = ({
  cmaSummaryData,
  livingTypes,
  occupancies,
  livingInfo,
  feesIncentives,
  careFees,
  apartmentTypeGroups,
  livingInfoGroups,
  filters,
  communitiesIds,
}: {
  cmaSummaryData: any[];
  livingTypes: LivingType[];
  occupancies?: any[][];
  livingInfo?: any[][];
  careFees?: any[][];
  feesIncentives?: CMASummaryIncentive[][];
  apartmentTypeGroups?: ApartmentTypeGroups[];
  livingInfoGroups?: any[][];
  filters: any;
  communitiesIds: number[];
}) => {
  let filteredLivingTypes = [...livingTypes];
  if (filters?.careTypes?.length) {
    filteredLivingTypes = filteredLivingTypes.filter((livingType) => filters?.careTypes.includes(livingType.id));
  }

  filteredLivingTypes.forEach((livingType) => {
    let hasApartmentTypeGroupRows = false;
    let hasCareFeesRows = false;

    // Generating Community Accommodation Group rows
    const temporaryApartmentGroupRows: any[] = [];
    const temporaryCareFeesRows: any[] = [];

    apartmentTypeGroups?.forEach((apartmentTypeGroup) => {
      let hasCurrentLivingInfoGroup = false;

      const apartmentTypeGroupRowKey = getRowKey({
        type: CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW,
        livingType: livingType.type,
        apartmentGroupType: apartmentTypeGroup.name,
      });
      const apartmentTypeGroupRow: any = [
        {
          key: apartmentTypeGroupRowKey,
          title: apartmentTypeGroup.name,
          subtitle: livingType.type,
          type: CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW,
          level: 1,
        },
      ];

      livingInfoGroups?.forEach((group) => {
        const currentLivingInfoGroup = group.find(
          (item) => item.apartmentTypeGroupId === apartmentTypeGroup.id && item.livingType === livingType.id
        );
        if (currentLivingInfoGroup) {
          hasCurrentLivingInfoGroup = true;
          apartmentTypeGroupRow.push([currentLivingInfoGroup.baseRent, currentLivingInfoGroup.squareFootage]);
        } else {
          apartmentTypeGroupRow.push([]);
        }
      });

      // Push APARTMENT TYPE GROUP row only if there is at least one currentLivingInfoGroup
      if (hasCurrentLivingInfoGroup) {
        hasApartmentTypeGroupRows = true;
        temporaryApartmentGroupRows.push(apartmentTypeGroupRow);

        const roomComparisonRowsData: any[] = [];
        apartmentTypeGroup.apartmentTypes.forEach((apartmentType) => {
          let rowContainsData = false;
          let roomComparisonRows: any[] = [];
          const livingDataRow: any = [
            {
              key: uuid(),
              parent: apartmentTypeGroupRowKey,
              title: apartmentType.type,
              subtitle: livingType.type,
              type: CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW,
              level: 2,
            },
          ];

          livingInfo?.forEach((communityLivingInfo) => {
            const selectedCommunityLivingInfo = communityLivingInfo.find(
              (item) => item.livingType === livingType.type && item.apartmentType === apartmentType.type
            );
            if (selectedCommunityLivingInfo) {
              rowContainsData = true;
              livingDataRow.push([selectedCommunityLivingInfo.baseRent, selectedCommunityLivingInfo.squareFootage]);
              roomComparisonRows = getRoomComparisonApartmentTypeRows({
                filters,
                communitiesIds,
                selectedCommunityLivingInfo,
                careFees,
                livingInfo,
                livingType,
                apartmentTypeGroupRowKey,
              });

              if (
                !roomComparisonRowsData.find(
                  (item: any) =>
                    item.livingType === livingType.type &&
                    item.apartmentType === selectedCommunityLivingInfo.apartmentType
                )
              ) {
                roomComparisonRowsData.push({
                  livingType: livingType.type,
                  apartmentType: selectedCommunityLivingInfo.apartmentType,
                  apartmentTypeId: selectedCommunityLivingInfo.apartmentTypeId,
                  data: roomComparisonRows,
                });
              }
            } else {
              livingDataRow.push([]);
            }
          });

          if (rowContainsData) {
            temporaryApartmentGroupRows.push(livingDataRow);
            if (roomComparisonRows.length > 0) {
              temporaryApartmentGroupRows.push(...roomComparisonRows);
            }
          }
        });

        // insert Comparison Rows for apartmentGroup
        if (
          filters?.roomComparisonType === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON &&
          livingType.type !== INDEPENDENT_LIVING_TYPE
        ) {
          filters?.roomComparisonRows?.forEach(
            (roomComparisonRow: CMASummaryFilterField[], roomComparisonRowIndex: number) => {
              const title = roomComparisonRow.map((item) => item.name);
              const subtitle = roomComparisonRow.map(
                (item) => Object.values(CMA_SUMMARY_ROOM_DATA_FILTER_TYPES).find((e) => e.id === item.fieldType)?.label
              );
              const rowKey = getRowKey({
                type: CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON,
                livingType: livingType.type,
                apartmentGroupType: apartmentTypeGroup.name,
                index: roomComparisonRowIndex,
              });

              const comparisonRow: any[] = [
                {
                  key: rowKey,
                  title,
                  subtitle,
                  livingType: livingType.type,
                  apartmentGroupType: apartmentTypeGroup.name,
                  type: CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON,
                  level: 1,
                },
              ];

              communitiesIds.forEach((communityId: number, communityIndex: number) => {
                let fieldTotal = 0;
                roomComparisonRow.forEach((filterField: CMASummaryFilterField) => {
                  if (!livingInfo) {
                    return;
                  }
                  const communityLivingInfoRates = livingInfo[communityIndex]
                    .filter(
                      (item: any) =>
                        item.livingType === livingType.type &&
                        apartmentTypeGroup.apartmentTypes.find((i: any) => i.type === item.apartmentType)
                    )
                    .map((item: any) => item.baseRent)
                    .filter((item: any) => Boolean(item) && item > 0);
                  const sumOfAllApartmentypeGroupRates = communityLivingInfoRates.reduce(
                    (acc, curr) => acc + (curr || 0),
                    0
                  );
                  if (!communityLivingInfoRates?.length || sumOfAllApartmentypeGroupRates === 0) {
                    // If there is no rent for at least one item in the group, we will ignore this roomComparisonRow
                    return;
                  }

                  if (filterField.name === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.RATE) {
                    if (filterField.fieldType === CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.AVG.id) {
                      fieldTotal +=
                        communityLivingInfoRates.reduce((acc, curr) => acc + curr, 0) / communityLivingInfoRates.length;
                    } else if (filterField.fieldType === CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MIN.id) {
                      fieldTotal += Math.min(...communityLivingInfoRates);
                    } else if (filterField.fieldType === CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MAX.id) {
                      fieldTotal += Math.max(...communityLivingInfoRates);
                    }
                  } else if (filterField.name === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.CARE) {
                    const communityCareFees = careFees
                      ? careFees[communityIndex].filter((item: any) => item.livingType === livingType.type)
                      : [];
                    if (communityCareFees.length) {
                      const communityCareFee = communityCareFees[0];

                      if (communityCareFee.feesType === 'Levels') {
                        const levelsList = [
                          communityCareFee.level1,
                          communityCareFee.level2,
                          communityCareFee.level3,
                          communityCareFee.level4,
                          communityCareFee.level5,
                          communityCareFee.level6,
                          communityCareFee.level7,
                          communityCareFee.level8,
                        ];
                        if (filterField.fieldType === CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.AVG.id) {
                          if (communityCareFee.averageCareCost) {
                            fieldTotal += communityCareFee.averageCareCost;
                          } else {
                            const presentLevels = levelsList.filter((item: number) => Boolean(item) && item > 0);
                            const levelsCount = presentLevels.length;
                            const sum = levelsList.reduce((accumulator, current) => accumulator + current, 0);
                            fieldTotal += sum / levelsCount;
                          }
                        } else if (filterField.fieldType === CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MAX.id) {
                          fieldTotal += maxIgnoreNullUndefined(levelsList);
                        } else if (filterField.fieldType === CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MIN.id) {
                          fieldTotal += minIgnoreNullUndefined(levelsList);
                        }
                      } else {
                        fieldTotal += communityCareFee.averageCareCost || 0;
                      }
                    }
                  } else if (filterField.name === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.MEDICATION) {
                    const communityCareFees = careFees
                      ? careFees[communityIndex].filter((item: any) => item.livingType === livingType.type)
                      : [];
                    if (communityCareFees.length) {
                      fieldTotal += communityCareFees[0].medicationManagementFee || 0;
                    }
                  }
                });

                comparisonRow.push([Math.round(fieldTotal)]);
              });

              const apartmentGroupRowIndex = temporaryApartmentGroupRows.findIndex(
                (row: any) => row[0].subtitle === livingType.type && row[0].title === apartmentTypeGroup.name
              );
              const indexToAdd = apartmentGroupRowIndex + roomComparisonRowIndex + 1;

              temporaryApartmentGroupRows.splice(indexToAdd, 0, comparisonRow);
            }
          );
        }
      }
    });

    // Generating Community Fees rows
    const feesRows: any[] = [];
    if (feesIncentives?.length) {
      const livingTypeFeesIncentives = feesIncentives.map((communityFeeIncentives: CMASummaryIncentive[]) =>
        communityFeeIncentives.filter((item: CMASummaryIncentive) => item?.livingTypeId == livingType.id)
      );

      Object.values(CMA_SUMMARY_DATA_FEE_INCENTIVE_ROW_TYPE).forEach(
        (communityFeeType: { id: string; label: string; field: string }) => {
          if (!filters?.viewOptions || !filters?.viewOptions[communityFeeType.field]) {
            return;
          }
          const communitiesFeeValues = livingTypeFeesIncentives.map((item: CMASummaryIncentive[]) => {
            if (item[0]) {
              const entry = item[0];
              const fieldName: keyof CMASummaryIncentive = communityFeeType.field;
              const isCommunityFee = communityFeeType.id === CMA_SUMMARY_DATA_FEE_INCENTIVE_ROW_TYPE.COMMUNITY_FEE.id;
              const communityFeeEquivalentFactor = isCommunityFee && entry.frequency;

              const value =
                communityFeeEquivalentFactor && entry.communityFeeEquivalent
                  ? entry.communityFeeEquivalent
                  : item[0][fieldName];
              return [value, communityFeeEquivalentFactor];
            } else {
              return [];
            }
          });

          if (communitiesFeeValues.some((entry: any) => entry && typeof entry[0] === 'number')) {
            hasApartmentTypeGroupRows = true;
            const individualFeeRow = {
              key: uuid(),
              title: communityFeeType.label,
              subtitle: livingType.type,
              type: CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW,
            };

            feesRows.push([individualFeeRow, ...communitiesFeeValues]);
          }
        }
      );
    }

    if (careFees?.length) {
      const livingTypeCareFees = careFees
        ?.map((communityCareFees: any[]) =>
          communityCareFees.filter((item: any) => item?.livingTypeId == livingType.id)
        )
        .map((communityCareFees: any[]) => (communityCareFees?.length ? communityCareFees[0] : []));

      const communityCareFees = livingTypeCareFees.map((communityCareFee: any) => {
        if (!communityCareFee) {
          return [];
        }
        let value = undefined;
        const type = communityCareFee?.feesType;
        let subtitle = '';

        if (communityCareFee.feesTypeId === CARE_FEES_TYPES.LEVELS) {
          const levelsList = [
            communityCareFee?.level1,
            communityCareFee?.level2,
            communityCareFee?.level3,
            communityCareFee?.level4,
            communityCareFee?.level5,
            communityCareFee?.level6,
            communityCareFee?.level7,
            communityCareFee?.level8,
          ];

          const presentLevels = levelsList.filter((item: number) => Boolean(item));
          const levelsCount = presentLevels.length;

          switch (filters?.aggregationType) {
            case AGGREGATION_TYPE.MAX:
              value = Math.max(...levelsList.filter((item) => item && item > 0));
              break;
            case AGGREGATION_TYPE.MIN:
              value = Math.min(...levelsList.filter((item) => item && item > 0));
              break;
            case AGGREGATION_TYPE.AVG:
            default: {
              const sum = levelsList.reduce((accumulator, current) => accumulator + current, 0);
              const averageLevelsValue = sum / levelsCount;
              value = averageLevelsValue;
            }
          }
          subtitle = `${levelsCount} ${levelsCount === 1 ? 'Level' : 'Levels'}`;
        }

        return [value, type, subtitle];
      });

      const hasCareFeesRow = communityCareFees.some(
        (communityCareFee: any) => communityCareFee?.length && Boolean(communityCareFee[0])
      );
      const hasCareFeesGroup = livingTypeCareFees.some(
        (communityCareFee: any) => communityCareFee?.feesTypeId === CARE_FEES_TYPES.LEVELS
      );

      const groupUUID = getRowKey({ type: CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW, livingType: livingType.type });
      const careFeesGroupRow: any = [
        {
          key: groupUUID,
          title: 'Care Fees',
          subtitle: livingType.type,
          living_type: livingType.type,
          type: CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW,
          level: hasCareFeesGroup ? 1 : undefined,
        },
        ...communityCareFees,
      ];

      if (hasCareFeesRow) {
        temporaryCareFeesRows.push(careFeesGroupRow);
        hasCareFeesRows = true;
      }

      if (hasCareFeesGroup) {
        const careFeeLevelsRows = LEVELS.map((field: string, index: number) => {
          const level = index + 1;
          const rowKey = getRowKey({ type: CMA_SUMMARY_CARE_FEE_DATA_ROW, livingType: livingType.type, level });
          const levelsTitleCell = {
            key: rowKey,
            parent: groupUUID,
            title: `Level ${index + 1}`,
            living_type: livingType.type,
            type: CMA_SUMMARY_CARE_FEE_DATA_ROW,
            level: 2,
          };

          const levelsValuesCells = livingTypeCareFees.map((communityCareFee: any) => communityCareFee[field]);
          const hasAnyLevelValues = levelsValuesCells.some((item: any) => Boolean(item));

          // If there is no values for particular level dicard that row from the data
          return hasAnyLevelValues ? [levelsTitleCell, ...levelsValuesCells] : [];
        });

        // Only leave Level row where at least one Community has value.
        const filteredCareFeeLevelsRows = careFeeLevelsRows.filter((item: any[]) => item.length);
        temporaryCareFeesRows.push(...filteredCareFeeLevelsRows);
      }
    }

    // Generating Living Type header row containing occupancies data
    const livingTypeRows = [];
    if (occupancies?.length) {
      const communityOccupancies = occupancies.map((item) => {
        const occupancy = item?.find((occupancy: any) => occupancy.livingType === livingType.type);
        if (occupancy) {
          const { occupancyPercentage, totalApartmentsUnits, freeApartmentsUnits } = occupancy;
          return [occupancyPercentage, totalApartmentsUnits, freeApartmentsUnits];
        }

        return [];
      });

      // Display Occupancy Row
      const hasOccupanciesData = communityOccupancies.some((item: any[]) => item.length); // Generating Community Occupancies rows
      const occupancyLivingTypeRow = {
        key: uuid(),
        title: livingType.type,
        hasOccupancies: hasOccupanciesData,
        subtitle: hasOccupanciesData ? 'Occupancy' : undefined,
        type: CMA_SUMMARY_LIVING_TYPE_DATA_ROW,
      };

      livingTypeRows.push([occupancyLivingTypeRow, ...communityOccupancies]);

      if (hasOccupanciesData) {
        hasApartmentTypeGroupRows = true;
      }
    }

    // Push the LIVING TYPE OCCUPANCY row if it has apartment group rows, and push its children after
    if (hasApartmentTypeGroupRows || hasCareFeesRows) {
      cmaSummaryData.push(...livingTypeRows);
      if (filters?.viewOptions?.roomType && hasApartmentTypeGroupRows) {
        cmaSummaryData.push(...temporaryApartmentGroupRows);
      }
      if (filters?.viewOptions?.careFee && hasCareFeesRows) {
        cmaSummaryData.push(...temporaryCareFeesRows);
      }
      cmaSummaryData.push(...feesRows);
    }
  });
};

const maxIgnoreNullUndefined = (args: any[] | null) => {
  if (!args) {
    return 0;
  }
  const filteredArgs = args.filter((v) => v != null);
  return filteredArgs.length > 0 ? Math.max(...filteredArgs) : 0;
};

const minIgnoreNullUndefined = (args: any[] | null) => {
  if (!args) {
    return 0;
  }
  const filteredArgs = args.filter((v) => v != null);
  return filteredArgs.length > 0 ? Math.min(...filteredArgs) : 0;
};

const getRoomComparisonApartmentTypeRows = ({
  filters,
  communitiesIds,
  selectedCommunityLivingInfo,
  careFees,
  livingInfo,
  livingType,
  apartmentTypeGroupRowKey,
}: {
  filters: any;
  communitiesIds: number[];
  selectedCommunityLivingInfo: any;
  careFees?: any[][];
  livingInfo: any[][];
  livingType: any;
  apartmentTypeGroupRowKey?: string;
}) => {
  const rowsToAdd: any[] = [];
  if (
    filters?.roomComparisonType === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON &&
    livingType.type !== INDEPENDENT_LIVING_TYPE
  ) {
    filters?.roomComparisonRows?.forEach((roomComparisonRow: CMASummaryFilterField[]) => {
      const roomComparisonRateField = roomComparisonRow.find(
        (field: CMASummaryFilterField) => field.name === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.RATE
      );
      const roomComparisonCareField = roomComparisonRow.find(
        (field: CMASummaryFilterField) => field.name === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.CARE
      );
      const roomComparisonMedicationField = roomComparisonRow.find(
        (field: CMASummaryFilterField) => field.name === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.MEDICATION
      );

      const title = roomComparisonRow.map((item) => item.name);
      const subtitle = roomComparisonRow.map(
        (item) => Object.values(CMA_SUMMARY_ROOM_DATA_FILTER_TYPES).find((e) => e.id === item.fieldType)?.label
      );
      const comparisonRowKey = getRowKey({
        type: CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON,
        livingType: livingType.type,
        apartmentType: selectedCommunityLivingInfo.apartmentType,
        roomComparisonFilters: roomComparisonRow,
      });
      const comparisonRow: any[] = [
        {
          key: comparisonRowKey,
          parent: apartmentTypeGroupRowKey,
          livingType: livingType.type,
          apartmentType: selectedCommunityLivingInfo.apartmentType,
          title,
          subtitle,
          type: CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON,
          level: 2,
        },
      ];

      communitiesIds.forEach((communityId, communityIndex) => {
        let totalSum = 0;

        const currentLivingInfo =
          livingInfo &&
          livingInfo[communityIndex].find(
            (item: any) =>
              item.livingType === livingType.type && item.apartmentType === selectedCommunityLivingInfo.apartmentType
          );
        if (currentLivingInfo) {
          if (!currentLivingInfo.baseRent) {
            // If there is no rent, we will not show the rate neither the medication
            return;
          }
          if (roomComparisonRateField) {
            totalSum += currentLivingInfo.baseRent || 0;
          }
          if (roomComparisonCareField) {
            const careFee =
              careFees && careFees[communityIndex]
                ? careFees[communityIndex].find((item: any) => item.livingType === livingType.type)
                : null;
            if (careFee) {
              const levelsList = [
                careFee?.level1,
                careFee?.level2,
                careFee?.level3,
                careFee?.level4,
                careFee?.level5,
                careFee?.level6,
                careFee?.level7,
                careFee?.level8,
              ];
              const presentLevels = levelsList.filter((item: number) => Boolean(item) && item > 0);

              if (roomComparisonCareField.fieldType === CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.AVG.id) {
                if (careFee.averageCareCost > 0) {
                  totalSum += careFee.averageCareCost || 0;
                } else if (careFee.feesType === 'Levels') {
                  const levelsCount = presentLevels.length;
                  const sum = levelsList.reduce((accumulator, current) => accumulator + current, 0);
                  const averageLevelsValue = sum / levelsCount;
                  totalSum += averageLevelsValue || 0;
                }
              } else if (roomComparisonCareField.fieldType === CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MAX.id) {
                const levelCareFees = [
                  careFee.level1,
                  careFee.level2,
                  careFee.level3,
                  careFee.level4,
                  careFee.level5,
                  careFee.level6,
                  careFee.level7,
                  careFee.level8,
                ];

                totalSum += maxIgnoreNullUndefined(levelCareFees);
              } else if (roomComparisonCareField.fieldType === CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MIN.id) {
                const levelCareFees = [
                  careFee.level1,
                  careFee.level2,
                  careFee.level3,
                  careFee.level4,
                  careFee.level5,
                  careFee.level6,
                  careFee.level7,
                  careFee.level8,
                ];

                totalSum += minIgnoreNullUndefined(levelCareFees);
              }
            }
          }
          if (roomComparisonMedicationField) {
            const careFee = careFees
              ? careFees[communityIndex].find((item: any) => item.livingType === livingType.type)
              : null;
            if (careFee) {
              totalSum += careFee.medicationManagementFee || 0;
            }
          }
        }
        comparisonRow.push([Math.round(totalSum)]);
      });

      if (!isSimilarComparisonRowPresent(rowsToAdd, comparisonRow)) {
        rowsToAdd.push(comparisonRow);
      }
    });
  }

  return rowsToAdd;
};

const isSimilarComparisonRowPresent = (existingRows: any[][], rowToAdd: any[]) => {
  const rowToAddMetadata = rowToAdd[0];
  if (!rowToAddMetadata) {
    return false;
  }

  const rowToAddFilters: CMASummaryFilterField[] = rowToAddMetadata.title.map((name: string, index: number) => ({
    name,
    fieldType: rowToAddMetadata.subtitle[index],
  }));

  return existingRows.some((row: any[]) => {
    const currentRowMetadata = row[0];
    if (!currentRowMetadata) {
      return false;
    }
    const currentRowFilters: CMASummaryFilterField[] = currentRowMetadata.title.map((name: string, index: number) => ({
      name,
      fieldType: currentRowMetadata.subtitle[index],
    }));

    if (currentRowFilters.length !== rowToAddFilters.length) {
      return false;
    }

    return rowToAddFilters.every((rowToAddFilter: CMASummaryFilterField) => {
      const matchingFilter = currentRowFilters.find((currentRowFilter: CMASummaryFilterField) => {
        if (currentRowFilter.name !== rowToAddFilter.name) {
          return false;
        }
        if (
          currentRowFilter.name === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.CARE &&
          currentRowFilter.fieldType !== rowToAddFilter.fieldType
        ) {
          return false;
        }

        return true;
      });
      return Boolean(matchingFilter);
    });
  });
};

const getFilteredApartmentTypeGroups = ({
  apartmentTypeGroups,
  filters,
}: {
  apartmentTypeGroups: ApartmentTypeGroups[];
  filters: any;
}) => {
  return apartmentTypeGroups.filter((apartmentGroup) =>
    apartmentGroup.apartmentTypes.find((apartmentType) => filters?.roomTypes.includes(apartmentType.id))
  );
};

const getLivingInfoGroups = ({ livingInfo, filters }: { livingInfo?: any[][]; filters: any }) => {
  if (!livingInfo) {
    return [];
  }
  if (!filters?.viewOptions?.roomType) {
    return livingInfo;
  }

  const aggregateValues = (values: number[], aggregationType: number) => {
    const filteredValues = values.filter((value) => value !== null && value !== 0);

    if (filteredValues.length === 0) {
      return 0;
    }

    switch (aggregationType) {
      case AGGREGATION_TYPE.MIN:
        return Math.min(...filteredValues);
      case AGGREGATION_TYPE.MAX:
        return Math.max(...filteredValues);
      case AGGREGATION_TYPE.AVG:
      default: {
        const sum = filteredValues.reduce((acc, val) => acc + val, 0);
        return Math.round(sum / filteredValues.length);
      }
    }
  };

  const calculateAggregates = (communityLivingInfo: any[]) => {
    const groupMap = new Map();

    for (const communityLivingInfoItem of communityLivingInfo) {
      const key = `${communityLivingInfoItem.apartmentTypeGroupId}-${communityLivingInfoItem.livingTypeId}`;
      if (!groupMap.has(key)) {
        groupMap.set(key, {
          apartmentTypeGroupId: communityLivingInfoItem.apartmentTypeGroupId,
          livingType: communityLivingInfoItem.livingTypeId,
          baseRentValues: [],
          squareFootageValues: [],
        });
      }

      const group = groupMap.get(key);
      group.baseRentValues.push(communityLivingInfoItem.baseRent);
      group.squareFootageValues.push(communityLivingInfoItem.squareFootage);
    }

    return Array.from(groupMap.values()).map((group) => ({
      apartmentTypeGroupId: group.apartmentTypeGroupId,
      baseRent: aggregateValues(group.baseRentValues, filters?.aggregationType),
      squareFootage: aggregateValues(group.squareFootageValues, filters?.aggregationType),
      livingType: group.livingType,
    }));
  };

  return livingInfo.map((communityLivingInfo) => calculateAggregates(communityLivingInfo));
};

const getFilteredLivingInfo = ({ livingInfo, filters }: { livingInfo?: any[][]; filters: any }) => {
  if (!livingInfo) {
    return [];
  }
  if (!filters || !filters?.roomTypes || filters?.roomTypes.length === 0) {
    return livingInfo;
  }

  return livingInfo.map((row) =>
    row.filter((livingInfoItem) => filters?.roomTypes.includes(livingInfoItem.apartmentTypeId))
  );
};

export const getCMASummaryReviewSources = (filters: CMASummaryFilters) => {
  const availableReviewSources = WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.REVIEW_SOURCE];
  return filters?.reviewSources?.length &&
    filters?.reviewSources.length > 0 &&
    filters?.reviewSources.length < availableReviewSources.length
    ? filters?.reviewSources
        .map((value: number) => {
          const source = availableReviewSources.find((reviewSource) => reviewSource.id === value);
          return source?.name;
        })
        .join(', ')
    : 'All Sources';
};

type CMASummaryDataProps = {
  community: any;
  competitors: any[];
  ratings?: DataItemType[];
  livingTypes: LivingType[];
  feesIncentives?: CMASummaryIncentive[][];
  careFees?: any;
  occupancies?: any[][];
  livingInfo?: any[][];
  incentives?: CommunityBaseIncentiveType[][];
  apartmentTypeGroups: ApartmentTypeGroups[];
  livingInfoGroups?: any[][];
  filters: CMASummaryFilters;
};

export const getCMASummaryData = ({
  community,
  competitors,
  ratings,
  livingTypes,
  feesIncentives,
  incentives,
  careFees,
  occupancies,
  livingInfo,
  apartmentTypeGroups,
  filters,
}: CMASummaryDataProps) => {
  if (!community || !competitors) {
    return undefined;
  }

  let cmaSummaryData: any[] = [[{ key: uuid(), title: '', type: CMA_SUMMARY_DATA_ROW_TYPE.COMMUNITY_NAME }]];

  if (filters?.viewOptions?.contactInformation) {
    cmaSummaryData.push(
      [{ key: uuid(), title: 'Address', type: CMA_SUMMARY_DATA_ROW_TYPE.ADDRESS }],
      [{ key: uuid(), title: 'Website', type: CMA_SUMMARY_DATA_ROW_TYPE.WEBSITE }],
      [{ key: uuid(), title: 'Phone', type: CMA_SUMMARY_DATA_ROW_TYPE.PHONE }]
    );
  }
  if (filters?.viewOptions?.reviews) {
    cmaSummaryData.push([
      {
        key: uuid(),
        title: 'Reviews',
        subtitle: getCMASummaryReviewSources(filters),
        type: CMA_SUMMARY_DATA_ROW_TYPE.REVIEWS,
      },
    ]);
  }

  if (filters?.viewOptions?.careTypes) {
    const filteredApartmentTypeGroups = getFilteredApartmentTypeGroups({ apartmentTypeGroups, filters });
    const filteredLivingInfo = getFilteredLivingInfo({ livingInfo, filters });
    addCMALivingTypeRows({
      cmaSummaryData,
      livingTypes,
      occupancies,
      livingInfo: filters?.roomTypes?.length ? filteredLivingInfo : livingInfo,
      careFees,
      feesIncentives,
      apartmentTypeGroups: filters?.roomTypes?.length ? filteredApartmentTypeGroups : apartmentTypeGroups,
      livingInfoGroups: getLivingInfoGroups({ livingInfo, filters }),
      filters,
      communitiesIds: [community.id, ...competitors?.flatMap((c) => c.id)],
    });
  }

  if (
    incentives?.some((item: CommunityBaseIncentiveType[]) => Boolean(item?.length)) &&
    filters?.viewOptions?.incentives
  ) {
    cmaSummaryData.push([
      {
        key: uuid(),
        title: 'Incentives',
        subtitle: 'View community page for more',
        type: CMA_SUMMARY_DATA_ROW_TYPE.INCENTIVES,
      },
    ]);
  }

  cmaSummaryData.forEach((dataRow) => {
    const rowMetadata = dataRow[0];

    switch (rowMetadata.type) {
      case CMA_SUMMARY_DATA_ROW_TYPE.COMMUNITY_NAME: {
        dataRow.push(community.comunity_name);
        competitors.forEach((competitor: any) => {
          dataRow.push(competitor.comunity_name);
        });
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.ADDRESS: {
        dataRow.push(community.address);
        competitors.forEach((competitor: any) => {
          dataRow.push(competitor.address);
        });
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.WEBSITE: {
        dataRow.push(community.website);
        competitors.forEach((competitor: any) => {
          dataRow.push(competitor.website);
        });
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.PHONE: {
        dataRow.push(community.phone_number);
        competitors.forEach((competitor: any) => {
          dataRow.push(competitor.phone_number);
        });
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.REVIEWS: {
        ratings?.forEach((rating) => {
          dataRow.push([rating?.value?.toFixed(1), rating?.total]);
        });
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.INCENTIVES: {
        if (incentives) {
          dataRow.push(...incentives);
        }
        break;
      }
    }
  });

  cmaSummaryData = addDataDifferenceValues(cmaSummaryData, filters);

  return cmaSummaryData;
};

export const prepareCMASummaryDataRowsForAntdTable = (expandedRowKeys: string[], cmaSummaryData?: any[]) => {
  const prepareCMASummaryDataRowForAntdTable = (cmaSummaryDataRow: any) => {
    const rowMetadata = cmaSummaryDataRow[0];
    return {
      key: rowMetadata.key,
      rowContent: cmaSummaryDataRow,
    };
  };

  return cmaSummaryData
    ?.map((row) => prepareCMASummaryDataRowForAntdTable(row))
    .filter((row) => {
      const { rowContent } = row;

      const rowMetadata = rowContent.length ? rowContent[0] : undefined;
      const parentUUID = rowMetadata?.parent;

      return !parentUUID ? true : parentUUID && expandedRowKeys.includes(parentUUID);
    });
};

const addDataDifferenceValues = (rows: any[], filters: Partial<CMASummaryFilters>) => {
  if (filters?.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.DONT_DISPLAY) {
    return rows;
  }

  const calculatePercentageDifference = (a: number, b: number) => {
    if (a === 0) {
      return null;
    }

    const percentage = ((b - a) / a) * 100;
    return Math.round(percentage);
  };

  return rows.map((row: any[]) => {
    const rowMetadata = row[0];
    const communityValues = row[1];
    if (!rowMetadata || !communityValues) {
      return row;
    }

    if (
      rowMetadata.type === CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW ||
      rowMetadata.type === CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW ||
      rowMetadata.type === CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW ||
      rowMetadata.type === CMA_SUMMARY_CARE_FEE_DATA_ROW ||
      rowMetadata.type === CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW ||
      rowMetadata.type === CMA_SUMMARY_DATA_ROW_TYPE.REVIEWS ||
      rowMetadata.type === CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON ||
      rowMetadata.type === CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON
    ) {
      return row.map((rowValues: any, index: number) => {
        if (index < 2) {
          return rowValues; // skip metadata and community data, and proceed to competitors data
        }

        if (typeof rowValues === 'number') {
          if (filters?.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.AMOUNTS) {
            return [rowValues, rowValues - communityValues];
          } else if (filters?.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.PERCENTAGES) {
            return [rowValues, calculatePercentageDifference(communityValues, rowValues)];
          }
        }

        if (!Array.isArray(rowValues)) {
          return rowValues;
        }

        return rowValues.map((value, valueIndex) => {
          if (
            !communityValues[valueIndex] ||
            isNaN(communityValues[valueIndex]) ||
            value === communityValues[valueIndex]
          ) {
            return value;
          }

          let differenceValue;
          if (filters?.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.AMOUNTS) {
            differenceValue = value - communityValues[valueIndex];
          } else if (filters?.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.PERCENTAGES) {
            differenceValue = calculatePercentageDifference(communityValues[valueIndex], value);
          }
          return [value, differenceValue];
        });
      });
    }

    return row;
  });
};

export const formatAmount = (prefix: string, value?: number | null) => {
  if (typeof value !== 'number' || isNaN(value)) {
    return null;
  }
  return value < 0 ? `-${prefix}${Math.abs(value).toLocaleString()}` : `+${prefix}${value.toLocaleString()}`;
};

export const formatPercentage = (value?: number | null) => {
  if (typeof value !== 'number' || isNaN(value)) {
    return null;
  }
  return value < 0 ? `-${Math.abs(value).toLocaleString()}%` : `+${value.toLocaleString()}%`;
};

export const parseCMASummaryCommunityData = (
  data: number | number[] | number[][] | undefined,
  valueDisplay: number | undefined
) => {
  if (!data) {
    return [null, null];
  }
  if (!Array.isArray(data)) {
    return [data];
  }

  let parsedDataItemOne = data[0];
  let parsedDataItemTwo = data[1];
  let parsedDataItemOneDifference, parsedDataItemTwoDifference;

  if (Array.isArray(parsedDataItemOne)) {
    parsedDataItemOneDifference =
      valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.DONT_DISPLAY ? undefined : parsedDataItemOne[1];
    parsedDataItemOne = parsedDataItemOne[0];
  }
  if (Array.isArray(parsedDataItemTwo)) {
    parsedDataItemTwoDifference =
      valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.DONT_DISPLAY ? undefined : parsedDataItemTwo[1];
    parsedDataItemTwo = parsedDataItemTwo[0];
  }

  return [parsedDataItemOne, parsedDataItemTwo, parsedDataItemOneDifference, parsedDataItemTwoDifference];
};

export const getRowKey = ({
  type,
  livingType,
  apartmentType,
  apartmentGroupType,
  roomComparisonFilters,
  level,
  index,
}: {
  type: string;
  livingType?: string;
  apartmentGroupType?: string;
  apartmentType?: string;
  roomComparisonFilters?: CMASummaryFilterField[];
  level?: number;
  index?: number;
}) => {
  if (type === CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON) {
    return `${CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON}-${livingType}-${apartmentGroupType}-${index}`;
  } else if (type === CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW) {
    return `${CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW}-${livingType}-${apartmentGroupType}`;
  } else if (type === CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON) {
    return `${CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON}-${livingType}-${apartmentType}-roomComparison-${roomComparisonFilters
      ?.filter((item) => Boolean(item))
      .map((item) => `${item?.name}-${item?.fieldType}`)
      .join('-')}`;
  } else if (type === CMA_SUMMARY_CARE_FEE_DATA_ROW) {
    return `${CMA_SUMMARY_CARE_FEE_DATA_ROW}-${livingType}-${level}`;
  } else if (type === CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW) {
    return `${CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW}-${livingType}`;
  }
};

export const parseCMASummaryFeeData = (data?: number | number[], valueDisplay?: number): (number | null)[] => {
  if (
    !data ||
    !Array.isArray(data) ||
    typeof data === 'number' ||
    valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.DONT_DISPLAY
  ) {
    return [data as number, null];
  }

  if (Array.isArray(data[0])) {
    return [data[0][0], Math.round(data[0][1])];
  }
  return [data[0], Math.round(data[1])];
};

export const isViewCMA = (view?: { widgets?: WidgetType[] }) => {
  return Boolean(
    view?.widgets?.length && view.widgets?.length === 1 && view.widgets[0].type === WIDGET_TYPES.CMA_SUMMARY
  );
};
