import React from 'react';

import styled from 'styled-components';

import { CommunityIncentivesType } from './types';

type Props = {
  item: CommunityIncentivesType;
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Attachment = styled.a`
  color: var(--link-blue);
  margin-left: 5px;
  height: 16px;
  font-size: 12px;
  cursor: pointer;
`;

const CommunityIncentivesTableIncentivesAttachments = ({ item }: Props) => {
  const attachments = [...(item.images || []), ...(item.files || [])];
  return (
    <div>
      {attachments.map((attachment) => (
        <Container key={attachment.id}>
          <img alt="attachment" src="/icons/attachment.svg" />
          <Attachment href={attachment.path} target="_blank" rel="noopener noreferrer">
            {attachment.file_name}.{attachment.file_extension}
          </Attachment>
        </Container>
      ))}
    </div>
  );
};

export default CommunityIncentivesTableIncentivesAttachments;
