import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-top: 150px;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

const Box = styled.div`
  text-align: center;
`;

const Description = styled.div`
  max-width: 488px;
  color: #89919d;
  text-align: center;
  margin-top: 5px;
`;

const RecommendationsNotFound = () => {
  return (
    <Container>
      <img src="/icons/ai-blob.svg" style={{ width: '29px', height: '29px' }} />
      <Box>
        <h2>There are no AI recommendations. Stay tuned.</h2>
        <Description>
          AI recommendations are generated automatically. Check back here once in a while to see what you can improve.
        </Description>
      </Box>
    </Container>
  );
};

export default RecommendationsNotFound;
