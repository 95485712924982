import React from 'react';

import styled from 'styled-components';

import { LivingInfoDataType } from '../types';
import { calculateLivingInfoStats, formatNumberWithCommas } from '../utils';

type Props = {
  data: LivingInfoDataType[];
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  padding-right: 15px;
  padding-top: 8px;
  position: relative;
  &::after {
    content: '';
    display: absolute;
    height: 2px;
    width: calc(100% - 15px);
    border-radius: 1px;
    background-image: linear-gradient(90deg, #0ab776, #fddf05, #ff6400);
    position: absolute;
    top: 0px;
  }
`;

const Column = styled.div`
  font-family: var(--font-regular);
  font-size: 10px;
  color: var(--text-secondary);
  width: 60px;
  &:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const Price = styled.div`
  font-family: var(--font-regular);
  font-size: 10px;
  color: var(--text-primary);
`;

const TextGray = styled.span`
  font-family: var(--font-regular);
  font-size: 10px;
  color: var(--text-secondary);
`;

const AccomodationGroupCollapseHeaderStats = ({ data }: Props) => {
  const { minBaseRentItem, avgBaseRent, maxBaseRentItem, avgSqFootage } = calculateLivingInfoStats(data);
  return (
    <Container>
      {[minBaseRentItem, { base_rent: avgBaseRent, sq_footage: avgSqFootage }, maxBaseRentItem].map((item, idx) => (
        <Column key={idx}>
          {item?.base_rent && (
            <Price>
              ${formatNumberWithCommas(item?.base_rent)} <TextGray>{['min', 'avg', 'max'][idx]}</TextGray>
            </Price>
          )}
          {item?.sq_footage && <div>{formatNumberWithCommas(item.sq_footage)} sq ft</div>}
        </Column>
      ))}
    </Container>
  );
};

export default AccomodationGroupCollapseHeaderStats;
