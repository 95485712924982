import {
  CMA_SUMMARY_INITIAL_FILTERS,
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS,
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS,
} from './constants';
import { CMASummaryFilterField, CMASummaryFilters, CMASummaryFiltersOutbound } from './types';

export const transformCMASummaryFiltersOutbound = (filtersInboud: CMASummaryFilters) => ({
  room_comparison_type: filtersInboud.roomComparisonType,
  room_comparison_rows: filtersInboud.roomComparisonRows?.map((row) => ({
    fields: row.map((field) => ({ name: field.name, type: field.fieldType })),
  })),
  view_options: {
    contact_information: filtersInboud?.viewOptions?.contactInformation,
    reviews: filtersInboud?.viewOptions?.reviews,
    care_types: filtersInboud?.viewOptions?.careTypes,
    room_type: filtersInboud?.viewOptions?.roomType,
    community_fee: filtersInboud?.viewOptions?.communityFee,
    pet_fee: filtersInboud?.viewOptions?.petFee,
    second_person_fee: filtersInboud?.viewOptions?.secondPersonFee,
    care_fee: filtersInboud?.viewOptions?.careFee,
    incentives: filtersInboud?.viewOptions?.incentives,
    value_display: filtersInboud?.viewOptions?.valueDisplay,
  },
  // Don't save competitors until we fix the CommonFilters handling of competitors for different communities
  competitors: [],
  review_sources: filtersInboud.reviewSources,
  care_types: filtersInboud.careTypes,
  room_types: filtersInboud.roomTypes,
  aggregation_type: filtersInboud?.aggregationType,
});

const transformCMAFilterViewOptionsInbound = (viewOptions: any) =>
  viewOptions
    ? {
        contactInformation: viewOptions.contact_information,
        reviews: viewOptions.reviews,
        careTypes: viewOptions.care_types,
        roomType: viewOptions.room_type,
        communityFee: viewOptions.community_fee,
        petFee: viewOptions.pet_fee,
        secondPersonFee: viewOptions.second_person_fee,
        careFee: viewOptions.care_fee,
        incentives: viewOptions.incentives,
        valueDisplay: viewOptions.value_display,
      }
    : CMA_SUMMARY_INITIAL_FILTERS.viewOptions;

export const transformCMASummaryFiltersInbound = (filtersOutbound: CMASummaryFiltersOutbound) => ({
  roomComparisonType: filtersOutbound.room_comparison_type
    ? filtersOutbound.room_comparison_type
    : CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.SIMPLE_COMPARISON,
  roomComparisonRows: filtersOutbound.room_comparison_rows?.map((row) =>
    row.fields.map((field) => ({
      name: field.name,
      fieldType: field.type,
    }))
  ),
  competitors: [],
  viewOptions: transformCMAFilterViewOptionsInbound(filtersOutbound.view_options),
  reviewSources: filtersOutbound.review_sources,
  careTypes: filtersOutbound.care_types,
  roomTypes: filtersOutbound.room_types,
  aggregationType: filtersOutbound.aggregation_type,
});

export const filterRemainingCMASummaryRoomComparisonFields = (row: CMASummaryFilterField[]) =>
  Object.values(CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS).filter((fieldName) => {
    if (!row) {
      return true;
    }
    return !row?.find((field: CMASummaryFilterField) => field.name === fieldName);
  });
