import React from 'react';

import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import CMASummaryBorder from './CMASummaryBorder';
import CMASummaryTableDataValuesDifference from './CMASummaryTableDataValuesDifference';
import { CMASummaryFilters } from './types';
import { parseCMASummaryCommunityData } from './utils';

type Props = {
  apartmentTypeGroupData: number | number[];
};

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const CMASummaryTableDataGroupRoomComparison = ({ apartmentTypeGroupData }: Props) => {
  const { viewFilters } = useCommunityViewContext();
  const filters = viewFilters as CMASummaryFilters;

  const [totalSum, secondItem, totalSumDifference] = parseCMASummaryCommunityData(
    apartmentTypeGroupData,
    filters?.viewOptions?.valueDisplay
  );

  return (
    <>
      <CMASummaryBorder backgroundColor="white" />
      {totalSum ? (
        <Title>
          ${totalSum.toLocaleString()} <CMASummaryTableDataValuesDifference difference={totalSumDifference} />
        </Title>
      ) : (
        <div>-</div>
      )}
    </>
  );
};

export default CMASummaryTableDataGroupRoomComparison;
