import React from 'react';

import styled from 'styled-components';

import { getCategoryOrDefaultScheme } from './utils';

interface ContainerProps {
  color: string;
  backgroundColor: string;
}

const Container = styled.div<ContainerProps>`
  padding: 6px 10px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-transform: capitalize;
  text-overflow: ellipsis;
`;

interface RecommendationCategoryTagProps {
  category: string;
}

const RecommendationCategoryTag = ({ category }: RecommendationCategoryTagProps) => {
  const colorScheme = getCategoryOrDefaultScheme(category);
  return (
    <Container color={colorScheme.color} backgroundColor={colorScheme.background}>
      {category}
    </Container>
  );
};

export default RecommendationCategoryTag;
