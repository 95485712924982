export const WIDGET_TYPES = {
  MARKET_RATE: 10,
  MARKET_RATE_SQUARE_FOOTAGE: 11,
  MARKET_RATE_AVERAGE_CARE_COST: 12,
  COMMUNITY_FEES: 13,
  SECOND_PERSON_FEE: 14,
  PET_FEE: 15,
  CARE_FEES: 16,
  OCCUPANCY: 17,
  RATING: 18,
  ENTRANCE_FEE: 19,
  SQUARE_FOOTAGE: 20,

  MARKET_RATE_GRAPH: 100,
  MARKET_RATE_SQUARE_FOOTAGE_GRAPH: 101,
  MARKET_RATE_AVERAGE_CARE_COST_GRAPH: 102,
  COMMUNITY_FEES_GRAPH: 103,
  SECOND_PERSON_FEE_GRAPH: 104,
  PET_FEE_GRAPH: 105,
  CARE_FEES_GRAPH: 106,
  OCCUPANCY_GRAPH: 107,
  RATING_GRAPH: 108,
  SWOT_SCORE: 109,
  SWOT_PERFORMANCE: 110,
  ENTRANCE_FEE_GRAPH: 111,
  SQUARE_FOOTAGE_GRAPH: 112,

  REVIEWS: 200,
  PHOTOS: 201,
  INCENTIVES: 202,
  MAP: 203,
  CMA_SUMMARY: 204,
};

export const SINGLE_METRIC_WIDGET_TYPES = [
  WIDGET_TYPES.MARKET_RATE,
  WIDGET_TYPES.MARKET_RATE_SQUARE_FOOTAGE,
  WIDGET_TYPES.MARKET_RATE_AVERAGE_CARE_COST,
  WIDGET_TYPES.COMMUNITY_FEES,
  WIDGET_TYPES.SECOND_PERSON_FEE,
  WIDGET_TYPES.PET_FEE,
  WIDGET_TYPES.CARE_FEES,
  WIDGET_TYPES.ENTRANCE_FEE,
  WIDGET_TYPES.SQUARE_FOOTAGE,
  WIDGET_TYPES.OCCUPANCY,
  WIDGET_TYPES.RATING,
];

export const GRAPH_WIDGET_TYPES = [
  WIDGET_TYPES.MARKET_RATE_GRAPH,
  WIDGET_TYPES.MARKET_RATE_SQUARE_FOOTAGE_GRAPH,
  WIDGET_TYPES.MARKET_RATE_AVERAGE_CARE_COST_GRAPH,
  WIDGET_TYPES.COMMUNITY_FEES_GRAPH,
  WIDGET_TYPES.SECOND_PERSON_FEE_GRAPH,
  WIDGET_TYPES.PET_FEE_GRAPH,
  WIDGET_TYPES.CARE_FEES_GRAPH,
  WIDGET_TYPES.ENTRANCE_FEE_GRAPH,
  WIDGET_TYPES.OCCUPANCY_GRAPH,
  WIDGET_TYPES.SQUARE_FOOTAGE_GRAPH,
  WIDGET_TYPES.RATING_GRAPH,
  WIDGET_TYPES.SWOT_SCORE,
];

export const CUSTOM_WIDGET_TYPES = [
  WIDGET_TYPES.REVIEWS,
  WIDGET_TYPES.PHOTOS,
  WIDGET_TYPES.INCENTIVES,
  WIDGET_TYPES.MAP,
];

export const WIDGET_TYPES_LABELS = {
  [WIDGET_TYPES.MARKET_RATE]: 'Market Rate',
  [WIDGET_TYPES.MARKET_RATE_SQUARE_FOOTAGE]: 'Market Rate/sq ft',
  [WIDGET_TYPES.MARKET_RATE_AVERAGE_CARE_COST]: 'Market Rate + Care Cost',
  [WIDGET_TYPES.COMMUNITY_FEES]: 'Community Fees',
  [WIDGET_TYPES.SECOND_PERSON_FEE]: 'Second Person Fee',
  [WIDGET_TYPES.PET_FEE]: 'Pet Fee',
  [WIDGET_TYPES.CARE_FEES]: 'Care Fees',
  [WIDGET_TYPES.ENTRANCE_FEE]: 'Entrance Fees',
  [WIDGET_TYPES.SQUARE_FOOTAGE]: 'Square Footage',
  [WIDGET_TYPES.OCCUPANCY]: 'Occupancy',
  [WIDGET_TYPES.RATING]: 'Rating',

  [WIDGET_TYPES.MARKET_RATE_GRAPH]: 'Market Rate',
  [WIDGET_TYPES.MARKET_RATE_SQUARE_FOOTAGE_GRAPH]: 'Market Rate/sq ft',
  [WIDGET_TYPES.MARKET_RATE_AVERAGE_CARE_COST_GRAPH]: 'Market Rate + Care Cost',
  [WIDGET_TYPES.COMMUNITY_FEES_GRAPH]: 'Community Fees',
  [WIDGET_TYPES.SECOND_PERSON_FEE_GRAPH]: 'Second Person Fee',
  [WIDGET_TYPES.PET_FEE_GRAPH]: 'Pet Fee',
  [WIDGET_TYPES.CARE_FEES_GRAPH]: 'Care Fees',
  [WIDGET_TYPES.ENTRANCE_FEE_GRAPH]: 'Entrance Fee',
  [WIDGET_TYPES.SQUARE_FOOTAGE_GRAPH]: 'Square Footage',
  [WIDGET_TYPES.OCCUPANCY_GRAPH]: 'Occupancy',
  [WIDGET_TYPES.RATING_GRAPH]: 'Rating',
  [WIDGET_TYPES.SWOT_SCORE]: 'SWOT Score',
  [WIDGET_TYPES.SWOT_PERFORMANCE]: 'SWOT Performance',

  [WIDGET_TYPES.REVIEWS]: 'Reviews',
  [WIDGET_TYPES.PHOTOS]: 'Photos',
  [WIDGET_TYPES.INCENTIVES]: 'Incentives',
  [WIDGET_TYPES.MAP]: 'Map',
};

export const WIDGET_FILTER_TYPES = {
  TIME: 10,
  COMPETITOR: 20,
  CARE_TYPE: 30,
  ROOM_TYPE: 40,
  CARE_LEVEL: 50,
  REVIEW_SOURCE: 60,
  REVIEW_CATEGORY: 70,
  SWOT_CATEGORY: 80,
};

export const WIDGET_FILTER_TYPE_LABEL = {
  [WIDGET_FILTER_TYPES.TIME]: ['day', 'days'],
  [WIDGET_FILTER_TYPES.COMPETITOR]: ['competitor', 'competitors'],
  [WIDGET_FILTER_TYPES.CARE_TYPE]: ['care type', 'care types'],
  [WIDGET_FILTER_TYPES.ROOM_TYPE]: ['room type', 'room types'],
  [WIDGET_FILTER_TYPES.CARE_LEVEL]: ['care level', 'care levels'],
  [WIDGET_FILTER_TYPES.REVIEW_SOURCE]: ['review source', 'review sources'],
  [WIDGET_FILTER_TYPES.REVIEW_CATEGORY]: ['review category', 'review categories'],
  [WIDGET_FILTER_TYPES.SWOT_CATEGORY]: ['category', 'categories'],
};

export const COMMON_FILTERS = [WIDGET_FILTER_TYPES.TIME, WIDGET_FILTER_TYPES.COMPETITOR, WIDGET_FILTER_TYPES.CARE_TYPE];
export const EXTENDED_COMMON_FILTERS = [...COMMON_FILTERS, WIDGET_FILTER_TYPES.ROOM_TYPE];
export const REVIEW_FILTERS = [WIDGET_FILTER_TYPES.REVIEW_SOURCE, WIDGET_FILTER_TYPES.REVIEW_CATEGORY];

export const WIDGET_FILTERS = {
  [WIDGET_TYPES.MARKET_RATE]: EXTENDED_COMMON_FILTERS,
  [WIDGET_TYPES.MARKET_RATE_SQUARE_FOOTAGE]: EXTENDED_COMMON_FILTERS,
  [WIDGET_TYPES.MARKET_RATE_AVERAGE_CARE_COST]: EXTENDED_COMMON_FILTERS,
  [WIDGET_TYPES.COMMUNITY_FEES]: COMMON_FILTERS,
  [WIDGET_TYPES.SECOND_PERSON_FEE]: COMMON_FILTERS,
  [WIDGET_TYPES.PET_FEE]: COMMON_FILTERS,
  [WIDGET_TYPES.ENTRANCE_FEE]: COMMON_FILTERS,
  [WIDGET_TYPES.CARE_FEES]: [...COMMON_FILTERS, WIDGET_FILTER_TYPES.CARE_LEVEL],
  [WIDGET_TYPES.SQUARE_FOOTAGE]: EXTENDED_COMMON_FILTERS,
  [WIDGET_TYPES.OCCUPANCY]: COMMON_FILTERS,
  [WIDGET_TYPES.RATING]: [WIDGET_FILTER_TYPES.TIME, WIDGET_FILTER_TYPES.COMPETITOR, ...REVIEW_FILTERS],

  [WIDGET_TYPES.MARKET_RATE_GRAPH]: EXTENDED_COMMON_FILTERS,
  [WIDGET_TYPES.MARKET_RATE_SQUARE_FOOTAGE_GRAPH]: EXTENDED_COMMON_FILTERS,
  [WIDGET_TYPES.MARKET_RATE_AVERAGE_CARE_COST_GRAPH]: [...EXTENDED_COMMON_FILTERS, WIDGET_FILTER_TYPES.CARE_LEVEL],
  [WIDGET_TYPES.COMMUNITY_FEES_GRAPH]: COMMON_FILTERS,
  [WIDGET_TYPES.SECOND_PERSON_FEE_GRAPH]: COMMON_FILTERS,
  [WIDGET_TYPES.PET_FEE_GRAPH]: COMMON_FILTERS,
  [WIDGET_TYPES.ENTRANCE_FEE_GRAPH]: COMMON_FILTERS,
  [WIDGET_TYPES.CARE_FEES_GRAPH]: [...COMMON_FILTERS, WIDGET_FILTER_TYPES.CARE_LEVEL],
  [WIDGET_TYPES.SQUARE_FOOTAGE_GRAPH]: EXTENDED_COMMON_FILTERS,
  [WIDGET_TYPES.OCCUPANCY_GRAPH]: COMMON_FILTERS,
  [WIDGET_TYPES.RATING_GRAPH]: [WIDGET_FILTER_TYPES.TIME, WIDGET_FILTER_TYPES.COMPETITOR, ...REVIEW_FILTERS],

  [WIDGET_TYPES.REVIEWS]: [...REVIEW_FILTERS],
  [WIDGET_TYPES.PHOTOS]: [WIDGET_FILTER_TYPES.COMPETITOR],
  [WIDGET_TYPES.INCENTIVES]: COMMON_FILTERS,

  [WIDGET_TYPES.SWOT_PERFORMANCE]: [WIDGET_FILTER_TYPES.SWOT_CATEGORY],
  [WIDGET_TYPES.SWOT_SCORE]: [WIDGET_FILTER_TYPES.SWOT_CATEGORY],
};

export const WIDGET_WITHOUT_PREFIX = [
  WIDGET_TYPES.RATING_GRAPH,
  WIDGET_TYPES.RATING,
  WIDGET_TYPES.OCCUPANCY,
  WIDGET_TYPES.OCCUPANCY_GRAPH,
  WIDGET_TYPES.SWOT_SCORE,
  WIDGET_TYPES.SQUARE_FOOTAGE,
  WIDGET_TYPES.SQUARE_FOOTAGE_GRAPH,
];

export const WIDGET_FILTER_OPTIONS = {
  [WIDGET_FILTER_TYPES.TIME]: [
    { id: null, name: 'All Time' },
    { id: 12, name: 'Last 12 Months' },
    { id: 24, name: 'Last 24 Months' },
  ],
  [WIDGET_FILTER_TYPES.CARE_TYPE]: [
    { id: 1, name: 'Independent Living' },
    { id: 2, name: 'Assisted Living' },
    { id: 3, name: 'Memory Care' },
    { id: 4, name: 'Enhanced Assisted Living' },
    { id: 5, name: 'Skilled Nursing' },
  ],
  [WIDGET_FILTER_TYPES.CARE_LEVEL]: [
    { id: 1, name: 'Level 1' },
    { id: 2, name: 'Level 2' },
    { id: 3, name: 'Level 3' },
    { id: 4, name: 'Level 4' },
    { id: 5, name: 'Level 5' },
    { id: 6, name: 'Level 6' },
    { id: 7, name: 'Level 7' },
    { id: 8, name: 'Level 8' },
  ],
  [WIDGET_FILTER_TYPES.REVIEW_SOURCE]: [
    { id: 1, name: 'Google' },
    { id: 2, name: 'APFM' },
    { id: 3, name: 'Caring' },
  ],
  [WIDGET_FILTER_TYPES.REVIEW_CATEGORY]: [
    { id: 1, name: 'Staff' },
    { id: 2, name: 'Room' },
    { id: 3, name: 'Care' },
    { id: 4, name: 'Cleanliness' },
    { id: 5, name: 'Service' },
  ],
  [WIDGET_FILTER_TYPES.SWOT_CATEGORY]: [
    { id: 1, name: 'Apartment Amenities' },
    { id: 2, name: 'Community Amenities' },
    { id: 3, name: 'Dining Experience' },
    { id: 4, name: 'Care Services' },
    { id: 5, name: 'Activity Program' },
    { id: 6, name: 'Reputation' },
    { id: 7, name: "Community 'Feel'" },
    { id: 8, name: 'Location' },
    { id: 9, name: 'Pricing Options' },
    { id: 10, name: 'Internet/Social Media Presence' },
  ],
};

export const WIDGET_POSTFIX = {
  [WIDGET_TYPES.OCCUPANCY]: '%',
  [WIDGET_TYPES.OCCUPANCY_GRAPH]: '%',
  [WIDGET_TYPES.SQUARE_FOOTAGE]: ' sq ft',
  [WIDGET_TYPES.SQUARE_FOOTAGE_GRAPH]: ' sq ft',
};

export const WIDGETS_WITH_NO_CONTROLS = [WIDGET_TYPES.SWOT_SCORE, WIDGET_TYPES.MAP];

export const WIDGETS_WITH_ALLOWED_DATA_INSERTION = [WIDGET_TYPES.SWOT_SCORE];

export const SWOT_CATEGORIES = [
  {
    value: 1,
    id: 'apartment_amenities_score',
    noteField: 'apartment_amenities_note',
    label: 'Apartment Amenities',
    fields: [
      {
        name: 'Apartment Size Options',
        key: 'aa_apartment_size',
      },
      {
        name: 'Apartment Flooring',
        key: 'aa_apartment_flooring',
      },
      {
        name: 'Kitchen Cabinets/Countertops',
        key: 'aa_kitchen_cabinets_countertops',
      },
      {
        name: 'Apartment Views',
        key: 'aa_apartment_views',
      },
      {
        name: 'Apartment Lighting',
        key: 'aa_apartment_lighting',
      },
      {
        name: 'Washer/Dryer/Laundry Options',
        key: 'aa_washer_dryer_laundry',
      },
      {
        name: 'Cable/Internet Access',
        key: 'aa_cable_internet_access',
      },
    ],
  },
  {
    value: 2,
    id: 'community_amenities_score',
    noteField: 'community_amenities_note',
    label: 'Community Amenities',
    fields: [
      {
        name: 'Lobby/Hallway Aesthetics',
        key: 'ca_lobby_hallway_aesthetics',
      },
      {
        name: 'Fitness/Exercise Space',
        key: 'ca_fitness_exercise_space',
      },
      {
        name: 'On-Site Physical Therapy',
        key: 'ca_onsite_physical_therapy',
      },
      {
        name: 'Outdoor Amenities (Pool, Sitting Areas, Shuffleboard, etc)',
        key: 'ca_outdoor_amenities',
      },
      {
        name: 'Serene Outdoor Space/Walking Paths',
        key: 'ca_serene_outdoor_space',
      },
      {
        name: 'Indoor Amenities (Theatre, Bar, Bistro, etc)',
        key: 'ca_indoor_amenities',
      },
      {
        name: 'Laundry Room',
        key: 'ca_laundry_room',
      },
      {
        name: 'Pet Friendliness',
        key: 'ca_pet_friendliness',
      },
    ],
  },
  {
    value: 3,
    id: 'dining_experience_score',
    noteField: 'dining_experience_note',
    label: 'Dining Experience',
    fields: [
      {
        name: 'Service Experience',
        key: 'de_service_experience',
      },
      {
        name: 'Multiple Dining Venues',
        key: 'de_multiple_dining_venues',
      },
      {
        name: 'Menu Options',
        key: 'de_menu_options',
      },
      { name: 'Food Quality', key: 'de_food_quality' },
      { name: 'Dining Hours', key: 'de_dining_hours' },
    ],
  },
  {
    value: 4,
    id: 'care_services_score',
    noteField: 'care_services_note',
    label: 'Care Services',
    fields: [
      {
        name: 'Breadth of Clinical Services (Continuum of Care, Specialized Care)',
        key: 'cs_breadth_of_clinical_services',
      },
      {
        name: 'Nursing Coverage (RN, LVN, LPN, etc)',
        key: 'cs_nursing_coverage',
      },
      {
        name: 'State Survey Results',
        key: 'cs_state_survey_results',
      },
      {
        name: 'Levels of Care/All Inclusive',
        key: 'cs_levels_of_care',
      },
      { name: 'Care Staff Ratio', key: 'cs_care_staff_ratio' },
    ],
  },
  {
    value: 5,
    id: 'life_enrichment_score',
    noteField: 'life_enrichment_note',
    label: 'Activity Program',
    fields: [
      {
        name: 'Diversity of Programming',
        key: 'ap_diversity_of_programming',
      },
      { name: 'Outings (Quality/Quantity)', key: 'ap_outings' },
      {
        name: 'Unique Programming',
        key: 'ap_unique_programming',
      },
      {
        name: 'Physical/Exercise Programming',
        key: 'ap_physical_exercise_programming',
      },
      {
        name: 'Transportation Services',
        key: 'ap_transportation_services',
      },
      {
        name: 'Spiritual/Religious Programming',
        key: 'ap_spiritual_religious_programming',
      },
    ],
  },
  {
    value: 6,
    id: 'reputation_score',
    noteField: 'reputation_note',
    label: 'Reputation',
    fields: [
      {
        name: 'General Reputation',
        key: 'reputation_general',
      },
      {
        name: 'Google Rating',
        key: 'reputation_google_rating',
      },
      {
        name: 'A Place for Mom Rating',
        key: 'reputation_place_for_mom_rating',
      },
      { name: 'Caring.com', key: 'reputation_caring_com' },
    ],
  },
  {
    value: 7,
    id: 'community_feel_score',
    noteField: 'community_feel_note',
    label: "Community 'Feel'",
    fields: [
      {
        name: 'First Impressions',
        key: 'community_feel_first_impressions',
      },
      {
        name: 'Front Desk Experience',
        key: 'community_feel_front_desk_experience',
      },
      {
        name: 'Associate Engagement (Friendliness)',
        key: 'community_feel_associate_engagement',
      },
      {
        name: 'Associate Knowledge',
        key: 'community_feel_associate_knowledge',
      },
      {
        name: 'Associate Tenure',
        key: 'community_feel_associate_tenure',
      },
    ],
  },
  {
    value: 8,
    id: 'location_score',
    noteField: 'location_note',
    label: 'Location',
    fields: [
      {
        name: 'Proximity to Healthcare Services',
        key: 'location_healthcare_services',
      },
      {
        name: 'Proximity to Shopping Options',
        key: 'location_shopping_options',
      },
    ],
  },
  {
    value: 9,
    id: 'pricing_score',
    noteField: 'pricing_note',
    label: 'Pricing Options',
    fields: [
      {
        name: 'Competitive Pricing',
        key: 'po_competitive_pricing',
      },
      {
        name: 'Access to Government Funding (Medicaid, State Aid,etc)',
        key: 'po_access_to_government_funding',
      },
      {
        name: 'Care Pricing',
        key: 'po_care_pricing',
      },
      {
        name: 'Reaccuring Fees',
        key: 'po_reaccuring_fees',
      },
    ],
  },
  {
    value: 10,
    id: 'internet_social_media_score',
    noteField: 'internet_social_media_note',
    label: 'Internet/Social Media Presence',
    fields: [
      {
        name: 'SEO Ranking on Key Search Terms (Senior Housing, AL, MC,etc)',
        key: 'internet_seo_ranking',
      },
      {
        name: 'Website Design',
        key: 'internet_website_design',
      },
      {
        name: 'Social Media Presence',
        key: 'internet_social_media',
      },
      {
        name: 'Email Marketing Campaigns',
        key: 'internet_email_marketing_campaigns',
      },
      {
        name: 'Community Pictures/Videos',
        key: 'internet_community_pictures_videos',
      },
      {
        name: 'Access to Virtual Tour',
        key: 'internet_access_to_virtual_tour',
      },
      {
        name: 'Website Navigation',
        key: 'internet_website_navigation',
      },
      {
        name: 'Traditional Marketing',
        key: 'internet_traditional_marketing',
      },
      {
        name: 'Website Content',
        key: 'internet_website_content',
      },
    ],
  },
];
