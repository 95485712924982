import React, { useState } from 'react';

import { Image } from 'antd';
import styled from 'styled-components';

import { STATUS } from '../../../../constants';
import PreviewUnavailable from '../../common/PreviewUnavailable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  width: 134px;
  height: 134px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const ImageCategory = styled.span`
  font-size: 12px;
  color: var(--text-primary);
  margin-top: 10px;
`;

interface Props {
  item: any;
  onClick: any;
}

const ImageWrapper = ({ item, onClick }: Props) => {
  const [status, setStatus] = useState(STATUS.IDLE);

  const showImage = [STATUS.IDLE, STATUS.LOADED].includes(status);
  const showError = STATUS.FAILURE === status;

  return (
    <Container>
      <Content key={item.id} onClick={onClick}>
        {showImage && (
          <Image
            src={item.image_url}
            alt={'image'}
            style={{ objectFit: 'cover' }}
            height={'134px'}
            width={'134px'}
            preview={false}
            onLoad={() => setStatus(STATUS.LOADED)}
            onError={() => setStatus(STATUS.FAILURE)}
          />
        )}
        {showError && <PreviewUnavailable />}
      </Content>
      <ImageCategory>{item.image_category}</ImageCategory>
    </Container>
  );
};

export default ImageWrapper;
