import React, { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { CareDataType } from './CareTypes/types';
import { CommunityDataType } from './types';

type CommunityInfoContentType = {
  communityInfo: CommunityDataType | undefined;
  mappedCareData: CareDataType[];
  setMappedCareData: Dispatch<SetStateAction<CareDataType[]>>;
  setCommunityInfo: (info: CommunityDataType) => void;
};

export const CommunityInfoContext = createContext<CommunityInfoContentType>({
  communityInfo: undefined,
  mappedCareData: [],
  setMappedCareData: () => {
    // Does Nothing
  },
  setCommunityInfo: () => {
    // Does Nothing
  },
});

export const useCommunityInfoContext = () => useContext(CommunityInfoContext);
