import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { formatUrl } from './utils';
import { getAdditionalLinks } from '../../../apis/CommunityAPI';
import Header from '../common/Header';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const Label = styled.p`
  color: var(--text-primary);
  font-family: var(--font-bold);
  width: 50%;
`;

const Link = styled.a`
  color: var(--link-blue);
  font-size: 14px;
  font-family: var(--font-regular);
  width: 50%;
  text-align: right;
`;

const AdditionalLinks = () => {
  const { competitorId } = useParams();
  const { communityId } = useParams();
  const id = Number(competitorId || communityId);

  const { data } = useQuery({
    queryKey: ['community', 'additional', 'links', id],
    queryFn: () => getAdditionalLinks(id),
    enabled: Boolean(id),
  });

  const hasData = data?.length > 0;

  if (!hasData) {
    return null;
  }

  return (
    <Container>
      <Header title="Additional Links" />
      <Content>
        {data.map((item: any) => (
          <Row key={item.id}>
            <Label>{item.description}</Label>
            <Link href={formatUrl(item?.page_url)} target="_blank" rel="noopener noreferrer">
              {item?.page_url}
            </Link>
          </Row>
        ))}
      </Content>
    </Container>
  );
};

export default AdditionalLinks;
