import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sortViews } from 'components/View/utils';
import { DEFAULT_SWOT_SCORES } from 'utils/data/communities';

import {
  ClientDataType,
  CommunityDataType,
  CompanyDataType,
  CompetitorSwotScoresType,
  ManagementCompanyDataType,
} from '../../types/actors';
import type { RootState } from '../index';

export type DataType = {
  id: number;
  type: string;
  abv?: string;
  created_at: string;
  updated_at: string;
};

type ApartmentDataType = {
  id: number;
  rating_name: string;
  order_no: number;
  rate_pct: number;
  updated_by: string;
  created_by: string;
  updated_at: string;
  created_at: string;
  status: number;
  company_id: number;
};

type ReviewCategoryDataType = {
  id: number;
  label: string;
};

export type ApartmentTypeGroups = {
  id: number;
  name: string;
  apartmentTypes: DataType[];
};

interface UniqueListType {
  loading: boolean;
  FeeTypes: DataType[];
  LevelTypes: DataType[];
  OccupancyTypes: DataType[];
  PaymentTypes: DataType[];
  apartmenttypes: DataType[];
  apartmentTypeGroups: ApartmentTypeGroups[];
  livingtypes: DataType[];
  qsunittypes?: DataType[];
  business_models: DataType[];
  apartment_ratings: ApartmentDataType[];

  selectedComunityCompanyName: string;
}

interface GlobalType extends UniqueListType {
  reviewCategories: ReviewCategoryDataType[];
  communityList: any[];
  communityCompetitorsList: any[];
  companyList: any[];
  managementCompanyList: any[];
  careList: any[];
  views: any[];
  selectedClient: ClientDataType | undefined;
  selectedCompany: CompanyDataType | undefined;
  selectedManagementCompany: ManagementCompanyDataType | undefined;
  selectedCommunity: CommunityDataType | undefined;
}

// Define the initial state using that type
const initialState: GlobalType = {
  loading: false,
  FeeTypes: [],
  LevelTypes: [],
  OccupancyTypes: [],
  PaymentTypes: [],
  apartmenttypes: [],
  apartmentTypeGroups: [],
  livingtypes: [],
  qsunittypes: [],
  business_models: [],
  reviewCategories: [],
  communityList: [],
  communityCompetitorsList: [],
  companyList: [],
  managementCompanyList: [],
  careList: [],
  apartment_ratings: [],
  views: [],
  selectedComunityCompanyName: '',
  selectedClient: undefined,
  selectedCompany: undefined,
  selectedManagementCompany: undefined,
  selectedCommunity: undefined,
};

export const globalSlice = createSlice({
  name: 'global',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleSetOptionTypes: (state, action: PayloadAction<UniqueListType>) => {
      state.FeeTypes = action.payload.FeeTypes;
      state.LevelTypes = action.payload.LevelTypes;
      state.OccupancyTypes = action.payload.OccupancyTypes;
      state.PaymentTypes = action.payload.PaymentTypes;
      state.apartmenttypes = action.payload.apartmenttypes;
      state.apartmentTypeGroups = action.payload.apartmentTypeGroups;
      state.livingtypes = action.payload.livingtypes;
      state.business_models = action.payload.business_models;
      state.apartment_ratings = action.payload.apartment_ratings;
      state.selectedComunityCompanyName = action.payload.selectedComunityCompanyName;
    },
    handleSetReviewCategories: (state, action: PayloadAction<ReviewCategoryDataType[]>) => {
      state.reviewCategories = action.payload;
    },
    handleSetLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    handleStoreUserCommunities: (state, action: PayloadAction<any[]>) => {
      state.communityList = action.payload;
    },
    handleStoreUserCommunitiesCompetitors: (state, action: PayloadAction<any[]>) => {
      state.communityCompetitorsList = action.payload;
    },
    handleStoreUserCompanies: (state, action: PayloadAction<any[]>) => {
      state.companyList = action.payload;
    },
    handleStoreUserManagementCompanies: (state, action: PayloadAction<any[]>) => {
      state.managementCompanyList = action.payload;
    },
    handleStoreUserCareList: (state, action: PayloadAction<any[]>) => {
      state.careList = action.payload;
    },
    handleStoreUserViews: (state, action: PayloadAction<any[]>) => {
      state.views = action.payload?.sort(sortViews);
    },
    handleSetSelectedClient: (state, action: PayloadAction<ClientDataType | undefined>) => {
      state.selectedClient = action.payload;
    },
    handleSetSelectedCompany: (state, action: PayloadAction<CompanyDataType | undefined>) => {
      state.selectedCompany = action.payload;
    },
    handleSetSelectedManagementCompany: (state, action: PayloadAction<ManagementCompanyDataType | undefined>) => {
      state.selectedManagementCompany = action.payload;
    },
    handleSetSelectedCommunity: (state, action: PayloadAction<CommunityDataType | undefined>) => {
      state.selectedCommunity = action.payload;
    },
    handleReset: (state) => {
      state.companyList = [];
      state.communityList = [];
      state.communityCompetitorsList = [];
      state.managementCompanyList = [];
      state.views = [];
      state.selectedClient = undefined;
      state.selectedCompany = undefined;
      state.selectedManagementCompany = undefined;
      state.selectedCommunity = undefined;
    },
    handleSetCompetitorSwotScores: (state, action: PayloadAction<CompetitorSwotScoresType>) => {
      const competitor = state.communityCompetitorsList.find((el) => el.id === action.payload.competitorId);
      const scores: { [key: string]: string | number } = action.payload.scores;

      if (competitor && scores) {
        Object.keys(scores)
          // filter only the swot score properties, not the rest of the Strengthfinder model
          // properties (community_name, community_id etc...)
          .filter((propertyName) => Object.keys(DEFAULT_SWOT_SCORES).indexOf(propertyName) !== -1)
          .forEach((propertyName: string) => {
            competitor[propertyName] = scores[propertyName];
          });
      }
    },
  },
});

export const {
  handleSetOptionTypes,
  handleSetReviewCategories,
  handleStoreUserCompanies,
  handleStoreUserManagementCompanies,
  handleStoreUserCommunities,
  handleStoreUserCommunitiesCompetitors,
  handleStoreUserCareList,
  handleStoreUserViews,
  handleSetLoader,
  handleSetSelectedClient,
  handleSetSelectedCompany,
  handleSetSelectedManagementCompany,
  handleSetSelectedCommunity,
  handleReset,
  handleSetCompetitorSwotScores,
} = globalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default globalSlice.reducer;
