import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb as AntBreadcrumb } from 'antd';
import styled from 'styled-components';

const Breadcrumb = styled(AntBreadcrumb)`
  span {
    color: var(--text-primary);

    &.ai {
      color: var(--link-blue);
      cursor: pointer;
    }
  }
`;

interface RecommendationBreadcrumbProps {
  company: string;
}

const RecommendationBreadcrumb = ({ company }: RecommendationBreadcrumbProps) => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Breadcrumb
      items={[
        { title: company },
        {
          className: 'ai',
          title: 'AI Recommendations',
          onClick: goBack,
        },
      ]}
      separator="·"
    />
  );
};

export default RecommendationBreadcrumb;
