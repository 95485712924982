import React from 'react';

import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import CMASummaryTableDataValuesDifference from './CMASummaryTableDataValuesDifference';
import { CMASummaryFilters } from './types';
import { parseCMASummaryFeeData } from './utils';
import { FREQUENCY_VALUES } from '../../pages/price_healing/Fees/constants';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
`;

type Props = { fee: [number | number[], number] };

const COMMUNITY_FEE_EQUIVALENT_LABEL = {
  [FREQUENCY_VALUES.HALF_MONTH]: 'half',
  [FREQUENCY_VALUES.ONE_MONTH]: 'one',
  [FREQUENCY_VALUES.TWO_MONTHS]: 'two',
  [FREQUENCY_VALUES.THREE_MONTHS]: 'three',
};

const CMASummaryTableDataLivingTypeFee = ({ fee }: Props) => {
  const { viewFilters } = useCommunityViewContext();
  if (!fee?.length) {
    return null;
  }
  const filters = viewFilters as CMASummaryFilters;
  const [value, communityFeeEquivalentFactor] = fee;
  const [feeValue, feeDifference] = parseCMASummaryFeeData(value, filters?.viewOptions?.valueDisplay);

  return (
    <div>
      {typeof feeValue === 'number' && (
        <Title>
          ${feeValue.toLocaleString(undefined, { maximumFractionDigits: 2 })}{' '}
          <CMASummaryTableDataValuesDifference difference={feeDifference} />
        </Title>
      )}
      {typeof feeValue !== 'number' && '-'}
      {communityFeeEquivalentFactor && (
        <Subtitle>Equal to {COMMUNITY_FEE_EQUIVALENT_LABEL[communityFeeEquivalentFactor]} month's rent</Subtitle>
      )}
    </div>
  );
};

export default CMASummaryTableDataLivingTypeFee;
