import React, { useState } from 'react';

import styled from 'styled-components';

import { useLatestShopNotesContext } from './LatestShopNotesContext';

const TextContainer = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: var(--text-secondary);
  margin-bottom: 10px;
`;

const TruncatedText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  max-height: calc(14px * 1.5 * 4);
  white-space: normal;
`;

const ExpandedText = styled.div`
  white-space: break-spaces;
`;

const ElipsisSymbol = styled.span`
  color: var(--link-blue);
  cursor: pointer;
`;

const LatestShopNotesMultiParagraphText = () => {
  const { latestShopNotes } = useLatestShopNotesContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const text = latestShopNotes?.request_notes;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <TextContainer>
      {isExpanded && (
        <ExpandedText>
          {text?.split('\n').map((paragraph, index) => (
            <span key={index}>
              {paragraph}
              {index < text.split('\n').length - 1 && <br />}
            </span>
          ))}
          <ElipsisSymbol onClick={handleToggle}> Show Less</ElipsisSymbol>
        </ExpandedText>
      )}

      {!isExpanded && (
        <>
          <TruncatedText>
            {text?.split('\n').map((paragraph, index) => (
              <span key={index}>
                {paragraph}
                {index < text.split('\n').length - 1 && <br />}
              </span>
            ))}
          </TruncatedText>
          <ElipsisSymbol onClick={handleToggle}> Show More</ElipsisSymbol>
        </>
      )}
    </TextContainer>
  );
};

export default LatestShopNotesMultiParagraphText;
