import React, { createContext, useContext } from 'react';

import { CareFeeDateType } from 'pages/price_healing/Fees/constants';

import { CareDataType } from './types';

type CareTypesContentType = {
  careType: CareDataType | undefined;
  careFees: CareFeeDateType | undefined;
  setCareFees: (data: CareFeeDateType) => void;
};

export const CareTypesContext = createContext<CareTypesContentType>({
  careType: undefined,
  careFees: undefined,
  setCareFees: () => {
    // Do nothing
  },
});

export const useCareTypesContext = () => useContext(CareTypesContext);
