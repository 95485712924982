import React, { useState } from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';
import { Divider } from 'components/lib/Divider';

import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';
import Input from '../../lib/Input';
import { INCENTIVES_FEES_TYPE, INCENTIVES_SAVINGS_DISCOUNT_TYPE } from '../create/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  margin-top: 20px;
`;

const Label = styled.span`
  display: flex;
  font-family: var(--font-bold);
  font-size: 14px;
  justify-content: end;
  width: 75px;
  color: var(--text-primary);
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  margin-left: 10px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span {
    display: flex;
  }
`;

const IncentivesProposalModalProposalAdditionalDiscount = () => {
  const [disabled, setDisabled] = useState(true);

  const { rates } = useIncentivesProposalModalContext();
  const monthlyRent = rates.find((item) => item.type === INCENTIVES_FEES_TYPE.MONTHLY_RENT);

  return (
    <Container>
      <LabelContainer>
        <Form.Item noStyle name={['additional_discount', 'enabled']} valuePropName="checked">
          <Checkbox className={'green'} onChange={(event: any) => setDisabled(!event.target.checked)} />
        </Form.Item>
        <Title>Add Additional Discount</Title>
      </LabelContainer>
      <Divider />
      <FormContainer>
        <Form.Item
          name={['additional_discount', 'amount']}
          label={<Label>Amount</Label>}
          rules={[
            { required: !disabled, message: 'This field is required!' },
            {
              validator(_, value) {
                if (!value || !monthlyRent?.amount || Number(value) <= monthlyRent.amount) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Can not be larger than a monthly rent!'));
              },
            },
          ]}
          style={{ height: 36 }}
        >
          <Input
            placeholder={'Enter additional discount amount'}
            prefix={<span style={{ fontSize: '14px' }}>$</span>}
            max={3315}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          name={['additional_discount', 'description']}
          label={<Label>Description</Label>}
          rules={[{ required: !disabled, message: 'This field is required!' }]}
          style={{ height: 36 }}
        >
          <Input placeholder={'Enter additional discount description'} disabled={disabled} />
        </Form.Item>
      </FormContainer>
    </Container>
  );
};

export default IncentivesProposalModalProposalAdditionalDiscount;
