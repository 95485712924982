import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import { SELECTOR_ITEM_TYPES } from './constants';
import SidebarMenuItem from './SidebarMenuItem';

const Container = styled.div``;

const SidebarMenuQuickCompetitorsList = () => {
  const { selectedCommunity, communityCompetitorsList } = useAppSelector((state) => state.global);

  const competitors = communityCompetitorsList
    .filter((competitor) => selectedCommunity?.id == competitor.parent_id)
    .map((item) => ({
      id: item.id,
      type: SELECTOR_ITEM_TYPES.COMPETITOR,
      name: item.apartmentname,
      address: item.apartmentaddress,
      description: item.apartmentaddress,
    }));

  return (
    <Container>
      {competitors.map((item) => (
        <SidebarMenuItem
          key={item.id}
          to={`/community/${selectedCommunity?.id}/competitor/${item.id}/info`}
          label={item.name}
        />
      ))}
    </Container>
  );
};

export default SidebarMenuQuickCompetitorsList;
