import React from 'react';

import styled from 'styled-components';

import { AccomodationContext } from './AccomodationContext';
import AccomodationGroupCollapse from './AccomodationGroupCollapse';
import AccomodationOccupancy from './AccomodationOccupancy';
import Header from '../../common/Header';
import { useCareTypesContext } from '../CareTypesContext';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Accomodation = () => {
  const { careType } = useCareTypesContext();
  const livingInfoData = careType?.livingInfo;

  if (!livingInfoData?.length) {
    return null;
  }

  return (
    <AccomodationContext.Provider
      value={{
        livingInfoData,
      }}
    >
      <Container>
        <Header title="Accomodation" addon={<AccomodationOccupancy />} />
        <Content>
          <AccomodationGroupCollapse />
        </Content>
      </Container>
    </AccomodationContext.Provider>
  );
};

export default Accomodation;
