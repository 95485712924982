import { FormInstance } from 'antd';

import { IncentiveProposalPricingType } from './types';
import { INCENTIVE_TYPE, SIGNATURE_DOCUMENT_TYPES } from '../constants';
import {
  INCENTIVES_FEES_TYPE,
  INCENTIVES_FEES_TYPE_LABELS,
  INCENTIVES_SAVINGS_DISCOUNT_DURATION_LABELS,
  INCENTIVES_SAVINGS_DISCOUNT_TYPE,
  INCENTIVES_SAVINGS_DURATION_TYPE_FACTOR,
  INCENTIVES_SAVINGS_FEE_TYPE_LABELS,
} from '../create/constants';
import { getUnitFeePrice } from '../helpers';
import { IncentiveSavingType, SignatureDocument, UnitType } from '../types';

export const getProposalPricingSavings = (rates: IncentiveProposalPricingType[]) => {
  const commonSavings = rates
    .filter((item: IncentiveProposalPricingType) => Boolean(item.discount) && Boolean(item.discount_type))
    .reduce((sum, current) => {
      const discountDurationFactor = INCENTIVES_SAVINGS_DURATION_TYPE_FACTOR[current.discount_duration_type!] ?? 1;
      const discountDuration = (current?.discount_duration ?? 1) * discountDurationFactor;

      if (current.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT && Boolean(current?.discount)) {
        return sum + (current?.discount ?? 0) * discountDuration;
      } else if (current.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.PERCENTAGE && Boolean(current?.discount)) {
        return sum + (current?.amount ?? 0) * ((current?.discount ?? 0) / 100) * discountDuration;
      }

      return sum;
    }, 0);

  const customSavings = rates
    .filter((item: IncentiveProposalPricingType) => item.type === INCENTIVES_FEES_TYPE.CUSTOM && Boolean(item.amount))
    .reduce((sum, current) => sum + (current.amount ?? 0), 0);
  return commonSavings + customSavings;
};

export const getProposalIncentiveSavingsValues = (incentiveSaving: IncentiveSavingType) => {
  const label =
    incentiveSaving?.type !== INCENTIVES_FEES_TYPE.CUSTOM
      ? INCENTIVES_SAVINGS_FEE_TYPE_LABELS[incentiveSaving.type ?? '']
      : incentiveSaving.name;

  const value =
    incentiveSaving.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT
      ? (incentiveSaving?.discount ?? 0) *
      (INCENTIVES_SAVINGS_DURATION_TYPE_FACTOR[incentiveSaving?.discount_duration_type ?? ''] ?? 1)
      : incentiveSaving?.discount;

  const durationLabel =
    INCENTIVES_SAVINGS_DISCOUNT_DURATION_LABELS[incentiveSaving.discount_duration_type ?? ''] &&
    INCENTIVES_SAVINGS_DISCOUNT_DURATION_LABELS[incentiveSaving.discount_duration_type ?? ''][
    incentiveSaving.discount_duration!
    ];

  const showCommonSavings = incentiveSaving.type !== INCENTIVES_FEES_TYPE.CUSTOM;
  const showSavingsAmount = incentiveSaving.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT;

  return { label, value, durationLabel, showCommonSavings, showSavingsAmount };
};

export const getProposalIncentiveAmountDescription = (incentiveSavings: IncentiveSavingType[], unit: UnitType) => {
  const savingsList = incentiveSavings.map((saving) => {
    const {
      label,
      value,
      durationLabel = '',
      showCommonSavings,
      showSavingsAmount,
    } = getProposalIncentiveSavingsValues(saving);

    let results = '';
    if (showCommonSavings && !showSavingsAmount) {
      results += `${value}% off ${label} ${durationLabel}\n`;
    }
    if (showCommonSavings && showSavingsAmount) {
      results += `$${value} off ${label} ${durationLabel}\n`;
    }
    if (!showCommonSavings && showSavingsAmount) {
      results += `$${value} ${label}\n`;
    }
    if (!showCommonSavings && !showSavingsAmount) {
      results += `${label}\n`;
    }
    return results;
  });

  const percentageSavings = incentiveSavings.filter(
    (item: IncentiveSavingType) => item.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.PERCENTAGE
  );
  const totalSavingsArray = (percentageSavings ?? []).map((item: IncentiveSavingType) => {
    const initialAmount = getUnitFeePrice(unit, item?.type) as number;
    return `$${initialAmount - (initialAmount * (item?.discount ?? 100)) / 100} ${item?.type && INCENTIVES_FEES_TYPE_LABELS[item.type]}`;
  });
  const totalSavings = totalSavingsArray.join('\n');

  return `${savingsList}\n${totalSavings}`;
};

export const getFirstAndLastName = (name: string) => {
  // Remove quotes and trim extra spaces
  name = name.replace(/^["']+|["']+$/g, '').trim();

  let firstName = '';
  let lastName = '';

  // Regular expression to match title (Mr, Mrs), and names
  const namePattern =
    /^(Mr\.|Mrs\.|Ms\.|Miss|Dr\.)?\s*([A-Za-z]+(?:[-'][A-Za-z]+)?)\s*([A-Za-z]+(?:[-'][A-Za-z]+)?)?\s*([A-Za-z]+(?:[-'][A-Za-z]+)?)?$/i;
  const match = name.match(namePattern);

  if (match) {
    // Match groups:
    // match[1]: Title (optional)
    // match[2]: First name
    // match[3]: Middle name (optional)
    // match[4]: Last name (optional)

    firstName = match[2] || '';
    lastName = match[4] || match[3] || ''; // If no explicit last name, assume middle is last
  }
  return {
    firstName: firstName || name,
    lastName: lastName || name,
  };
};

export const updateAdditionalDiscountRatesAndForm = (
  rates: IncentiveProposalPricingType[],
  form: FormInstance<any>,
  additional_discount: any,
  setRates: (value: IncentiveProposalPricingType[]) => void,
  signatureDocuments?: SignatureDocument[],
) => {
  const additionalDiscountRate = rates.find((rate) => rate.incentive_type === INCENTIVE_TYPE.ADDITIONAL_DISCOUNT);
  const ratesWithoutAdditionalDiscount = rates.filter(
    (rate) => rate.incentive_type !== INCENTIVE_TYPE.ADDITIONAL_DISCOUNT
  );
  const existingFormRatesWithoutAdditionDiscount = form
    .getFieldValue('rates')
    .filter((rateName: string) => rateName !== additionalDiscountRate?.name);

  const incentiveAddendumDocument = signatureDocuments?.find(
    (document) => document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_INCENTIVE
  );
  let signature_documents = form.getFieldValue('signature_documents_ids');

  if (additional_discount.enabled) {
    const name = additional_discount.description;
    const discount = parseFloat(additional_discount.amount);

    if (name && discount) {
      setRates([
        ...ratesWithoutAdditionalDiscount,
        {
          type: INCENTIVES_FEES_TYPE.CUSTOM,
          name,
          amount: discount,
          incentive_type: INCENTIVE_TYPE.ADDITIONAL_DISCOUNT,
        },
      ]);
      if (!signature_documents.includes(incentiveAddendumDocument?.id)) {
        signature_documents.push(incentiveAddendumDocument?.id);
      }
      form.setFieldsValue({ rates: [...existingFormRatesWithoutAdditionDiscount, name], signature_documents });
    }
  } else {
    setRates(ratesWithoutAdditionalDiscount);
    if (signature_documents.includes(incentiveAddendumDocument?.id)) {
      signature_documents = signature_documents.filter((documentId: number) => documentId !== incentiveAddendumDocument?.id);
    }
    form.setFieldsValue({ rates: existingFormRatesWithoutAdditionDiscount, signature_documents });
  }
};
