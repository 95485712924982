import React, { ReactNode } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { Avatar } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

import { COMMUNITIES_AVATAR_SIZE } from 'components/Overview/constants';
import { isLocationMatchingMenuItem } from 'utils/helpers/locationMatch';

const Label = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  font-family: var(--font-regular);
  font-size: 12px;
  color: var(--gray-dark);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAvatar = styled(Avatar)`
  color: var(--neutral-purple);
  background-color: var(--neutral-purple-light);
  border: 1px solid var(--neutral-purple);

  span {
    font-size: 10px;
  }
`;
const MenuItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 12px;
  padding: 24px 26px 24px 20px;
  border-bottom: 1px solid var(--line-gray);

  background-color: #fff;

  &:hover {
    background-color: var(--active-blue);
    ${Label} {
      color: var(--white);
    }
    ${Description} {
      color: var(--gray-dark);
    }
  }

  &.selected {
    background-color: var(--active-blue);
    ${Label} {
      color: var(--white);
    }
    ${Description} {
      color: var(--gray-dark);
    }
  }
`;

type Props = {
  to: string;
  pattern?: string;
  label: string;
  description: string;
};

const CompetitorInfoCompetitorMenuItem = ({ to, pattern, label, description }: Props) => {
  const location = useLocation();

  const isPathMatch = matchPath(pattern || to, location.pathname);
  const menuItemClassnames = classNames({
    selected: isPathMatch || isLocationMatchingMenuItem(pattern || to, location.pathname),
  });
  return (
    <StyledLink to={to} className={menuItemClassnames}>
      <MenuItem>
        <div>
          <StyledAvatar size={COMMUNITIES_AVATAR_SIZE}>{label ? label[0] : 'n/a'}</StyledAvatar>
        </div>
        <div title={`${label} | ${description}`}>
          <Label>{label}</Label>
          <Description>{description}</Description>
        </div>
      </MenuItem>
    </StyledLink>
  );
};

export default CompetitorInfoCompetitorMenuItem;
