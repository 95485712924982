import React from 'react';
import { useParams } from 'react-router';

import styled from 'styled-components';

import Button from 'components/lib/Button';

import CommunityInfoHeaderBreadcrumbs from './CommunityInfoHeaderBreadcrumbs';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 30px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const TitleIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--positive-green);
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 var(--box-shadow-pink);
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 40px;
  font-weight: 900;
`;

const Link = styled.a`
  text-decoration: none;
  margin-bottom: 10px;
`;

const Image = styled.span`
  width: 22px;
  height: 18.5px;
  background-image: url('/icons/auto-graph.svg');
  margin-right: 4.5px;
`;

const CommunityInfoHeader = () => {
  const { competitorId } = useParams();
  const isCompetitor = !!competitorId;
  return (
    <Container>
      <HeaderContent>
        <CommunityInfoHeaderBreadcrumbs />
        <TitleContainer>
          <TitleIcon>
            <img
              src={'/icons/checklist-white.svg'}
              alt="CommunityInfo"
              style={{
                width: 24,
                height: 24,
              }}
            />
          </TitleIcon>
          <Title>{isCompetitor ? 'Competitor Data' : 'Community Data'}</Title>
        </TitleContainer>
      </HeaderContent>
      {!!competitorId && (
        <Link href={`/competitor/${competitorId}`} target="_blank">
          <Button type="primary">
            <Image />
            Open Overview
          </Button>
        </Link>
      )}
    </Container>
  );
};

export default CommunityInfoHeader;
