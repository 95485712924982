import React from 'react';

import styled from 'styled-components';

import CommunityInfoCompetitorContent from './CommunityInfoCompetitorContent';
import CommunityInfoCompetitorMenu from './CommunityInfoCompetitorMenu';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--line-gray);
  margin-top: -50px;
  padding-top: 50px;
  max-width: 250px;
`;

const Header = styled.div`
  padding-bottom: 10px;
  font-size: 14px;
  color: var(--text-primary);
  padding-left: 20px;
`;

const CommunityInfoCompetitorOverview = () => {
  return (
    <Container>
      <Sidebar>
        <Header>Competitors</Header>
        <CommunityInfoCompetitorMenu />
      </Sidebar>
      <CommunityInfoCompetitorContent />
    </Container>
  );
};

export default CommunityInfoCompetitorOverview;
