import React, { useEffect, useRef, useState } from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import { CMASummaryFilterContext } from './CMASummaryFilterContext';
import CMASummaryFilterDropdownHeader from './CMASummaryFilterDropdownHeader';
import CMASummaryRoomComparisonTypeFilterFormList from './CMASummaryRoomComparisonTypeFilterFormList';
import CMASummaryRoomComparisonTypeSelector from './CMASummaryRoomComparisonTypeSelector';
import {
  CMA_SUMMARY_INITIAL_FILTERS,
  CMA_SUMMARY_ROOM_COMPARISON_FILTER_BASE_ROW,
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS,
  ROOM_COMPARISON_ROWS,
  ROOM_COMPARISON_TYPE,
} from '../constants';
import { useFocusNewestRow } from '../customHooks';
import { CMASummaryFilterField, CMASummaryFilters } from '../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .ant-form-item .ant-form-item-control-input {
    min-height: 0;
  }
`;

type Props = {
  onClose: () => void;
};

const CMASummaryRoomComparisonTypeFilterDropdown = ({ onClose }: Props) => {
  const [form] = Form.useForm();
  const { viewFilters, setViewFilters } = useCommunityViewContext();
  const filters = viewFilters as CMASummaryFilters;
  const roomComparisonType = Form.useWatch(ROOM_COMPARISON_TYPE, form);
  const roomComparisonRows = Form.useWatch(ROOM_COMPARISON_ROWS, form);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useFocusNewestRow(roomComparisonType, roomComparisonRows, containerRef, numberOfRows, setNumberOfRows);
  useEffect(() => {
    form.setFieldsValue({
      roomComparisonType: filters?.roomComparisonType,
      roomComparisonRows: filters?.roomComparisonRows,
    });
  }, [filters]);
  useEffect(() => {
    const isAdvancedComparison =
      roomComparisonType === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON;
    const onlyBaseRowPresent =
      JSON.stringify(roomComparisonRows) === JSON.stringify(CMA_SUMMARY_ROOM_COMPARISON_FILTER_BASE_ROW);

    if (isAdvancedComparison && !roomComparisonRows?.length) {
      form.setFieldsValue({
        roomComparisonRows: CMA_SUMMARY_ROOM_COMPARISON_FILTER_BASE_ROW,
      });
    } else if (isAdvancedComparison && roomComparisonRows?.length && !onlyBaseRowPresent) {
      form.setFieldsValue({ roomComparisonRows });
    }
  }, [roomComparisonRows, roomComparisonType]);

  const handleSubmit = (values: { roomComparisonType: number; roomComparisonRows: CMASummaryFilterField[][] }) => {
    setViewFilters((prevState: any) => ({
      ...prevState,
      roomComparisonType: values.roomComparisonType,
      roomComparisonRows: values.roomComparisonRows
        ? values.roomComparisonRows.filter((row) => Boolean(row.length))
        : [],
    }));
    onClose();
  };

  return (
    <Form form={form} initialValues={CMA_SUMMARY_INITIAL_FILTERS} onFinish={handleSubmit}>
      <CMASummaryFilterContext.Provider
        value={{
          form,
        }}
      >
        <Container ref={containerRef}>
          <CMASummaryFilterDropdownHeader onReset={() => form.setFieldsValue(CMA_SUMMARY_INITIAL_FILTERS)} />
          <CMASummaryRoomComparisonTypeSelector />
          {roomComparisonType === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON && (
            <CMASummaryRoomComparisonTypeFilterFormList />
          )}
        </Container>
      </CMASummaryFilterContext.Provider>
    </Form>
  );
};

export default CMASummaryRoomComparisonTypeFilterDropdown;
