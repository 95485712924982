import React from 'react';

import Button from 'components/lib/Button';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import { transformCSV } from './csv';
import { CMASummaryFilters } from './types';

const CMASummaryDownloadCSV = () => {
  const { community, viewFilters, CMASummaryData } = useCommunityViewContext();

  return (
    <Button onClick={() => transformCSV(community, CMASummaryData, viewFilters as CMASummaryFilters)}>
      Download as CSV
    </Button>
  );
};

export default CMASummaryDownloadCSV;
