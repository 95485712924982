import React, { useState } from 'react';

import classNames from 'classnames';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import CMASummaryRoomComparisonTypeFilterDropdown from './CMASummaryRoomComparisonTypeFilterDropdown';
import CMASummaryRoomComparisonTypeFilterSelectLabel from './CMASummaryRoomComparisonTypeFilterSelectLabel';
import CMASummarySelect from './CMASummarySelect';
import { CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS } from '../constants';
import { CMASummaryFilters } from '../types';

const CMASummaryRoomComparisonTypeFilter = () => {
  const [open, setOpen] = useState(false);
  const { viewFilters } = useCommunityViewContext();
  const filters = viewFilters as CMASummaryFilters;

  return (
    <CMASummarySelect
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      value={filters?.roomComparisonType}
      className={classNames({
        active: filters?.roomComparisonType === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON,
      })}
      dropdownRender={() => <CMASummaryRoomComparisonTypeFilterDropdown onClose={() => setOpen(false)} />}
      labelRender={() => <CMASummaryRoomComparisonTypeFilterSelectLabel />}
    />
  );
};

export default CMASummaryRoomComparisonTypeFilter;
