import React from 'react';

import styled from 'styled-components';

import { CommunityBaseIncentiveType } from '../CommunityInfo/types';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 10px;
`;

const Title = styled.div`
  font-size: 12px;
`;

type Props = {
  incentives: CommunityBaseIncentiveType[];
};

const CMASummaryTableDataIncentives = ({ incentives }: Props) => (
  <Container>{incentives?.map((incentive) => <Title key={incentive.id}>{incentive.description}</Title>)}</Container>
);

export default CMASummaryTableDataIncentives;
