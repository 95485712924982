import React, { useState } from 'react';

import styled from 'styled-components';

import ConfirmModal from 'common/Modal/ConfirmModal';
import { useAppSelector } from 'store/hook';

import { useCommunityIncentivesContext } from './CommunityIncentivesContext';
import { CommunityIncentivesType } from './types';
import { deleteIncentive } from '../../apis/CommunityAPI';
import DownCaretIcon from '../../common/Icons/DownCaretIcon';
import { useRootContext } from '../layout/RootContext';
import Button from '../lib/Button';
import Dropdown from '../lib/Dropdown';

type Props = {
  item: CommunityIncentivesType;
};

const Wrapper = styled.div`
  visibility: hidden;
`;

const CommunityIncentivesTableActions = ({ item }: Props) => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const { refetch } = useCommunityIncentivesContext();
  const { showMessage } = useRootContext();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const handleDeleteIncentive = async () => {
    await deleteIncentive(selectedCommunity?.id as number, item.id as number)
      .then((response: any) => {
        refetch();
        showMessage('success', 'Community Incentive deleted successfully');
      })
      .catch((reason: any) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Community Incentive');
      });
  };

  return (
    <Wrapper className={'hover-visible'}>
      <ConfirmModal
        open={openDeleteModal}
        title={'Delete Incentives'}
        description={`This will delete the ${selectedCommunity?.type} incentive.\nAre you sure you want to do this?`}
        confirmLabel="Delete"
        onConfirm={handleDeleteIncentive}
        onCancel={() => setOpenDeleteModal(false)}
      />
      <Dropdown
        menu={{
          items: [
            {
              label: 'Delete Incentive',
              key: 'deleteIncentive',
              onClick: () => setOpenDeleteModal(true),
            },
          ],
        }}
      >
        <Button style={{ width: 100 }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <span>Actions</span>
            <DownCaretIcon />
          </div>
        </Button>
      </Dropdown>
    </Wrapper>
  );
};

export default CommunityIncentivesTableActions;
