import React from 'react';

import SavingsList from '../../common/SavingsList';
import TotalSavings from '../../common/TotalSavings';
import { useIncentivesDataContext } from '../../IncentivesDataContext';
import { IncentiveType, UnitType } from '../../types';

interface Props {
  incentive: IncentiveType;
  unit: UnitType;
}

const IncentivesTableUnitsIncentive = ({ incentive, unit }: Props) => (
  <>
    {incentive ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span
            style={{
              fontFamily: 'var(--font-bold)',
              fontSize: 14,
              color: 'var(--text-primary)',
            }}
          >
            {incentive.name}
          </span>
          <div style={{ color: 'var(--text-secondary)' }}>
            <SavingsList savings={incentive.savings ?? []} />
          </div>
          <TotalSavings incentive={incentive} unit={unit} />
        </span>
      </div>
    ) : (
      <span>No Incentive</span>
    )}
  </>
);

export default IncentivesTableUnitsIncentive;
