import React from 'react';

import styled from 'styled-components';

import { useCareTypesContext } from './CareTypesContext';
import FeesItems from './common/FeesItems';
import Header from '../common/Header';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const MedicationFees = () => {
  const { careType } = useCareTypesContext();
  const careFees = careType?.careFee;

  const medicationFee = careFees?.medicationManagementFee;

  if (!medicationFee) {
    return null;
  }

  return (
    <Container>
      <Header title="Medication Fees" />
      <Content>
        <FeesItems title="Medication Fee" amount={medicationFee} subtitle="Average" />
      </Content>
    </Container>
  );
};

export default MedicationFees;
