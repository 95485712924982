import React, { useState } from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import { useRootContext } from 'components/layout/RootContext';
import Button from 'components/lib/Button';
import Checkbox from 'components/lib/Checkbox';
import Modal from 'components/lib/Modal';
import TextArea from 'components/lib/TextArea';
import Upload from 'components/lib/Upload';
import { useAppSelector } from 'store/hook';

import { useCommunityIncentivesContext } from './CommunityIncentivesContext';
import { CommunityIncentivesType } from './types';
import { createIncentive, uploadIncentiveFiles } from '../../apis/CommunityAPI';

const StyledModal = styled(Modal)`
  &&& .ant-modal {
    max-width: 1040px;
  }

  .ant-modal-close {
    top: 22px;
  }

  &&& .ant-modal-body {
    padding: 25px 0;
  }

  &&& .ant-modal-footer {
    margin-top: 0;
    button {
      font-size: 14px;
    }
  }

  .ant-modal-content {
    max-width: 1040px;

    .ant-modal-header {
      margin-bottom: 0;
    }
    .ant-modal-footer {
      display: flex;
      flex-flow: row-reverse;

      button {
        margin-right: 10px;
        border-radius: 4px;
        width: 155px;
      }
    }
  }
`;

const StyledTitle = styled.div`
  font-family: var(--font-bold);
  margin-bottom: 10px;
  padding: 0 30px;
`;

const StyledInput = styled(TextArea)`
  width: 502px;
`;

const Row = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 30px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Footer = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--line-gray);
`;

const CommunityIncentivesCreateModal = () => {
  const { showMessage } = useRootContext();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const livingTypes = useAppSelector((state) => state.global.livingtypes);
  const { refetch } = useCommunityIncentivesContext();
  const [form] = Form.useForm();

  const handleSubmit = async (values: CommunityIncentivesType) => {
    await createIncentive(selectedCommunity?.id as number, { ...values, community: selectedCommunity?.id })
      .then(async (response: any) => {
        await uploadFiles(response.id, values);
        return response;
      })
      .then((response) => {
        refetch();
        showMessage('success', 'Community Incentive added successfully');
        setOpenCreateModal(false);
        form.resetFields();
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to add Community Incentive');
      });
  };

  const handleCancel = () => {
    setOpenCreateModal(false);
    form.resetFields();
  };

  const uploadFiles = async (incentiveId: number, values: CommunityIncentivesType) => {
    const formData = new FormData();
    (values.files_upload ?? []).forEach((file: any) => {
      formData.append('files', file.originFileObj);
    });

    await uploadIncentiveFiles(selectedCommunity?.id as number, incentiveId, formData).catch((reason) => {
      console.error(reason);
      showMessage('error', 'Failed to upload Community Incentive files!');
    });
  };

  return (
    <div>
      <StyledModal
        open={openCreateModal}
        onCancel={handleCancel}
        footer={null}
        title="Create Incentive"
        width="fit-content"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            description: '',
            files_upload: null,
            livingTypes: [],
          }}
        >
          <StyledTitle>Incentive Description</StyledTitle>
          <Row>
            <Column>
              <Form.Item name="description" rules={[{ required: true, message: 'Please enter a description' }]}>
                <StyledInput placeholder="Enter a description" />
              </Form.Item>
              <Form.Item
                name="files_upload"
                valuePropName="files"
                getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
              >
                <Upload />
              </Form.Item>
            </Column>
            <Column>
              <Form.Item
                name="living_types"
                rules={[{ required: true, message: 'Please select at least one living type' }]}
              >
                <CheckboxGroup>
                  {livingTypes.map((livingType) => (
                    <Checkbox key={livingType.id} value={livingType.id} className={'green'}>
                      {livingType.type}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
              </Form.Item>
            </Column>
          </Row>
          <Footer>
            <Form.Item>
              <Button type={'primary'} htmlType="submit" style={{ marginRight: '10px' }}>
                Create
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Form.Item>
          </Footer>
        </Form>
      </StyledModal>
      <Button type={'primary'} onClick={() => setOpenCreateModal(true)}>
        Create Incentive
      </Button>
    </div>
  );
};

export default CommunityIncentivesCreateModal;
