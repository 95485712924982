import React from 'react';

import classNames from 'classnames';

import { WIDGET_FILTER_OPTIONS, WIDGET_FILTER_TYPES } from 'common/Widgets/constants';
import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';

type Props = {
  reviewSources: any;
};

const ReviewSourceFilter = ({ reviewSources }: Props) => {
  const { setViewFilters } = useCommunityViewContext();

  const handleSelect = (values: number[]) => {
    setViewFilters((prevState: any) => ({ ...prevState, reviewSources: values.map((item: any) => item.value) }));
  };

  const options = WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.REVIEW_SOURCE].map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: reviewSources?.length > 0 })}
      placeholder={'Review Source'}
      options={options}
      defaultValue={reviewSources ?? []}
      onSelect={handleSelect}
    />
  );
};

export default ReviewSourceFilter;
