import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { Spin } from 'antd';
import styled from 'styled-components';

import { formatUrl } from './utils';
import { getCommunity } from '../../../apis/CommunityAPI';
import Header from '../common/Header';
import { CommunityInfoContext } from '../CommunityInfoContext';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const SpinWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const Label = styled.p`
  color: var(--text-primary);
  font-family: var(--font-bold);
  width: 50%;
`;

const Text = styled.p`
  color: var(--text-primary);
  font-size: 14px;
  font-family: var(--font-regular);
  width: 50%;
  text-align: right;
`;

const Link = styled.a`
  color: var(--link-blue);
  font-size: 14px;
  font-family: var(--font-regular);
  width: 50%;
  text-align: right;
`;

const BasicInfo = () => {
  const { competitorId } = useParams();
  const { communityId } = useParams();
  const id = Number(competitorId || communityId);

  const { setCommunityInfo } = useContext(CommunityInfoContext);

  const { data, isLoading } = useQuery({
    queryKey: ['community', 'basic', 'info', id],
    queryFn: () => getCommunity(id),
    onSuccess: (data) => {
      setCommunityInfo(data);
    },
    enabled: Boolean(id),
  });

  return (
    <Container>
      <Header title="Basic Info" />
      {isLoading && (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      )}
      {data && (
        <Content>
          <Row>
            <Label>Community Name</Label>
            <Label style={{ textAlign: 'right' }}>{data?.comunity_name}</Label>
          </Row>
          <Row>
            <Label>Address</Label>
            <Text>{data?.address}</Text>
          </Row>
          <Row>
            <Label>Phone Number</Label>
            <Link>{data?.phone_number}</Link>
          </Row>
          <Row>
            <Label>Website</Label>
            <Link href={formatUrl(data?.website)} target="_blank" rel="noopener noreferrer">
              {data?.website}
            </Link>
          </Row>
          <Row>
            <Label>Year Opened</Label>
            <Text>{data?.year_opened}</Text>
          </Row>
          <Row>
            <Label>Google Place ID</Label>
            <Link>{data?.google_map_location}</Link>
          </Row>
          <Row>
            <Label>A Place For Mom ID</Label>
            <Link
              href={` https://www.aplaceformom.com/community/${data?.a_place_for_mom_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.a_place_for_mom_id}
            </Link>
          </Row>
          <Row>
            <Label>Caring Url</Label>
            <Link href={formatUrl(data?.caring_url)} target="_blank" rel="noopener noreferrer">
              {data?.caring_url}
            </Link>
          </Row>
        </Content>
      )}
    </Container>
  );
};

export default BasicInfo;
