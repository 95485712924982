import { Dayjs } from 'dayjs';

export const TABS = {
  EDIT: 'edit',
  HISTORY: 'history',
};

export type LivingInfoDataType = {
  id?: number;
  key: string;
  apartmentTypeId: number;
  livingTypeId: number;
  baseRent: number;
  squareFootage: number;
  pricePerSquare: number;
  notes: string;
  createdBy: string;
  createdAt: Date | Dayjs;
  updatedBy: string;
  updatedAt: Date | Dayjs;
  date: Date | Dayjs;
};

export const NOTE_MAXIMUM_LENGTH = 500;
