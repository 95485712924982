import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { RateTrendsInboundType } from './types';
import { transformRateTrendsInbound } from './utils';
import { getCommunityLevelFees } from '../../../apis/CommunityAPI';
import Header from '../common/Header';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
`;

const Text = styled.span`
  font-size: 14px;
  color: var(--text-secondary);
  margin-left: 10px;
  padding-top: 3px;
  padding-bottom: 2px;
  height: 19px;
`;

const BoldText = styled.span`
  color: var(--text-primary);
  font-family: var(--font-bold);
`;

const RateTrends = () => {
  const [rateTrends, setRateTrends] = useState<RateTrendsInboundType>();
  const { competitorId } = useParams();
  const { communityId } = useParams();
  const id = Number(competitorId || communityId);

  const { data } = useQuery({
    queryKey: ['community', 'rate', 'trends', id],
    queryFn: () => getCommunityLevelFees(id, { active: 1 }),
    onSuccess: (data) => {
      if (data.length > 0) {
        setRateTrends(transformRateTrendsInbound(data[0]));
      }
    },
    enabled: Boolean(id),
  });

  const hasData = data?.length > 0;

  if (!hasData) {
    return null;
  }

  return (
    <Container>
      <Header title="Rate Trends" />
      {rateTrends && (
        <Content>
          <img src="/icons/rate-trends.svg" alt="rate trends" height="24px" width="24px" />
          <Text>
            The rates tend to
            <BoldText>{` increase between ${rateTrends.annualRateRncreaseMin}% and ${rateTrends.annualRateRncreaseMax}% `}</BoldText>
            each year.
          </Text>
        </Content>
      )}
    </Container>
  );
};

export default RateTrends;
