import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import { useLatestShopNotesContext } from './LatestShopNotesContext';
import { GLOBAL_DATE_FORMAT } from '../../../../constants';

const Container = styled.div`
  font-size: 14px;
  color: var(--text-secondary);
`;

const AddedBy = styled.span`
  font-family: var(--font-bold);
  color: var(--text-primary);
`;

const LatestShopNotesDetails = () => {
  const { latestShopNotes } = useLatestShopNotesContext();
  const addedBy = latestShopNotes?.created_by;
  const addedOn = latestShopNotes?.created_at;

  const showDetails = addedBy || addedOn;
  if (!showDetails) return null;

  return (
    <Container>
      {`Added ${addedOn && dayjs(addedOn).format(GLOBAL_DATE_FORMAT)} by `}
      <AddedBy>{addedBy}</AddedBy>
    </Container>
  );
};

export default LatestShopNotesDetails;
