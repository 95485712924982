import React from 'react';

import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import CMASummaryTableDataValuesDifference from './CMASummaryTableDataValuesDifference';
import { CMASummaryFilters } from './types';
import { parseCMASummaryCommunityData } from './utils';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const Subtitle = styled.span`
  color: var(--text-secondary);
  font-size: 12px;
`;

type Props = {
  livingInfo?: number[] | number[][];
};

const CMASummaryTableDataLivingInfo = ({ livingInfo }: Props) => {
  const { viewFilters } = useCommunityViewContext();
  const filters = viewFilters as CMASummaryFilters;

  const [rent, squareFootage, rentDifference, squareFootageDifference] = parseCMASummaryCommunityData(
    livingInfo,
    filters?.viewOptions?.valueDisplay
  );

  return (
    <div>
      {rent ? (
        <>
          <Title>
            ${rent.toLocaleString()}
            <CMASummaryTableDataValuesDifference difference={rentDifference} />
          </Title>
          {squareFootage && (
            <Subtitle>
              {squareFootage.toLocaleString()} sq ft
              <CMASummaryTableDataValuesDifference difference={squareFootageDifference} />
            </Subtitle>
          )}
        </>
      ) : (
        <div>-</div>
      )}
    </div>
  );
};

export default CMASummaryTableDataLivingInfo;
