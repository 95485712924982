import React, { createContext, useContext } from 'react';

import { CMASummaryFilters } from 'components/CMASummary/types';

import { ViewFilters as ViewFiltersType } from './types';

type CommunityViewContent = {
  contentRef: any;
  viewId: number;
  view: any;
  viewFilters?: ViewFiltersType | CMASummaryFilters;
  filtersModified: boolean;
  communityId: number;
  community: any;
  competitorId: number | undefined;
  competitors: any[];
  apartmentTypes: any[];
  preview: boolean;
  setView: (object: any) => void;
  showViewModal: string;
  isViewCMA: boolean;
  CMASummaryData?: any[];
  setShowViewModal: (modal: string) => void;
  viewRefetch: () => void;
  setViewFilters: (value: any) => void;
};

export const CommunityViewContext = createContext<CommunityViewContent>({
  contentRef: undefined,
  viewId: 0,
  view: undefined,
  viewFilters: undefined,
  filtersModified: false,
  communityId: 0,
  community: undefined,
  competitorId: undefined,
  competitors: [],
  apartmentTypes: [],
  preview: false,
  showViewModal: '',
  isViewCMA: false,
  setView: (object: any) => {
    // Do Nothing
  },
  setShowViewModal: () => {
    // Do Nothing
  },
  viewRefetch: () => {
    // Do Nothing
  },
  setViewFilters: (value: any) => {
    // Do nothing
  },
});

export const useCommunityViewContext = () => useContext(CommunityViewContext);
