import React from 'react';

import classNames from 'classnames';

import CMASummaryTableColumnHeader from './CMASummaryTableColumnHeader';
import CMASummaryTableDataColumn from './CMASummaryTableDataColumn';
import CMASummaryTableTitleColumn from './CMASummaryTableTitleColumn';
import {
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW,
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON,
  CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON,
  CMA_SUMMARY_CARE_FEE_DATA_ROW,
  CMA_SUMMARY_TABLE_COLUMNS_OFFSET,
} from './constants';

export const generateCMASummaryTableColumns = ({
  community,
  competitors,
  filteredCompetitors,
  scrollCompetitorsLeft,
  scrollCompetitorsRight,
  expandedRowKeys,
  setExpandedRowKeys,
  competitorsMinIndex,
  competitorsMaxIndex,
  displayedNumberOfCommunities,
}: {
  community: any;
  competitors: any[];
  filteredCompetitors: any[];
  scrollCompetitorsLeft: () => void;
  scrollCompetitorsRight: () => void;
  expandedRowKeys: string[];
  setExpandedRowKeys: React.Dispatch<React.SetStateAction<string[]>>;
  competitorsMinIndex: number;
  competitorsMaxIndex: number;
  displayedNumberOfCommunities: number;
}) => {
  const disableLeftScroll = competitorsMinIndex === 0;
  const disableRightScroll =
    competitorsMaxIndex === (competitors?.length || 0) - 1 || competitors.length + 1 <= competitorsMaxIndex;

  const columns: any[] = [
    {
      title: '',
      render: (value: any, cmaSummaryRow: any) => (
        <CMASummaryTableTitleColumn
          cmaSummaryRow={cmaSummaryRow.rowContent}
          expandedRowKeys={expandedRowKeys}
          setExpandedRowKeys={setExpandedRowKeys}
        />
      ),
    },
    {
      title: community?.comunity_name,
      render: (value: any, cmaSummaryRow: any) => (
        <CMASummaryTableDataColumn cmaSummaryRow={cmaSummaryRow.rowContent} index={1} />
      ),
    },
  ];

  filteredCompetitors?.forEach((competitor: any, index: number) => {
    const indexWithOffset = CMA_SUMMARY_TABLE_COLUMNS_OFFSET + competitorsMinIndex + index;
    columns.push({
      title: () => (
        <CMASummaryTableColumnHeader
          index={index}
          community={competitor}
          filteredCompetitorsCount={filteredCompetitors.length}
          totalCompetitorsCount={competitors?.length || 0}
          scrollLeft={scrollCompetitorsLeft}
          scrollRight={scrollCompetitorsRight}
          disableLeft={disableLeftScroll}
          disableRight={disableRightScroll}
          displayedNumberOfCommunities={displayedNumberOfCommunities}
        />
      ),
      render: (value: any, cmaSummaryRow: any) => (
        <CMASummaryTableDataColumn cmaSummaryRow={cmaSummaryRow.rowContent} index={indexWithOffset} />
      ),
    });
  });

  return columns;
};

export const getAntdRowClassName = (record: any, index: number) => {
  if (record?.rowContent?.length) {
    const rowMetadata = record?.rowContent[0];
    return classNames({
      'cma-summary-apartment-type-data-row': [
        CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW,
        CMA_SUMMARY_CARE_FEE_DATA_ROW,
        CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON,
      ].includes(rowMetadata.type),
      'room-comparison-row': [
        CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON,
        CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON,
      ].includes(rowMetadata.type),
    });
  }
  return '';
};
