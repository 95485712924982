import React, { createContext, useState } from 'react';
import { useQuery } from 'react-query';

import { getRecommendationDetails } from 'apis/RecommendationsAPI';
import ErrorMessage from 'components/lib/ErrorMessage';
import Loader from 'components/lib/Loader';
import { SelectAdvancedFilterOptionType } from 'types/common';
import { RecommendationDetailsFilterType, RecommendationDetailsType } from 'types/recommendations';

import useRecommendationsContext from './RecommendationsContext';
import { getAvailableWidgets } from './utils';

type RecommendationDetailsContextType = {
  recommendation: RecommendationDetailsType;
  filters: RecommendationDetailsFilterType;
  widgets: number[];
  communityOptions: SelectAdvancedFilterOptionType[];
  updateFilters: (filters: RecommendationDetailsFilterType) => void;
};

const RecommendationDetailsContext = createContext<RecommendationDetailsContextType | null>(null);

interface RecommendationDetailsProviderProps {
  recommendationId: number;
  children: React.ReactNode;
}

export const RecommendationDetailsProvider: React.FC<RecommendationDetailsProviderProps> = ({
  recommendationId,
  children,
}) => {
  const { company, filters: globalFilters } = useRecommendationsContext();
  const [filters, setFilters] = useState<RecommendationDetailsFilterType>({
    communities: globalFilters.communities || [],
    careTypes: globalFilters.careTypes || [],
  });
  const { isLoading, data, isError } = useQuery({
    queryKey: ['community', recommendationId],
    queryFn: () => getRecommendationDetails(recommendationId),
    refetchOnWindowFocus: false,
  });

  const updateFilters = (newFilters: RecommendationDetailsFilterType) => {
    setFilters((draft) => ({ ...draft, ...newFilters }));
  };

  if (!company) {
    return null;
  }

  if (isLoading) {
    return <Loader active={isLoading} />;
  }

  if (isError) {
    return <ErrorMessage text="Failed to load AI recommendation. Please try again." active={isError} />;
  }

  if (!data) {
    return null;
  }

  const widgets = getAvailableWidgets(data);
  const communityOptions: SelectAdvancedFilterOptionType[] = data.community_overviews.map((co) => ({
    value: co.community_id,
    label: co.community_name,
  }));

  const value = {
    recommendation: data,
    filters,
    widgets,
    communityOptions,
    updateFilters,
  };
  return <RecommendationDetailsContext.Provider value={value}>{children}</RecommendationDetailsContext.Provider>;
};

const useRecommendationDetailsContext = () => {
  const context = React.useContext(RecommendationDetailsContext);
  if (!context) {
    throw new Error('useRecommendationDetailsContext must be used within a RecommendationDetailsProvider');
  }
  return context;
};

export default useRecommendationDetailsContext;
