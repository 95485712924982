import React, { createContext, useState } from 'react';

import { WIDGET_FILTER_OPTIONS, WIDGET_FILTER_TYPES } from 'common/Widgets/constants';
import { useAppSelector } from 'store/hook';
import { CompanyDataType } from 'types/actors';
import { SelectAdvancedFilterOptionType } from 'types/common';
import { RecommendationFilterType } from 'types/recommendations';

import { RECOMMENDATION_CATEGORIES } from './constants';

type RecommendationsContextType = {
  company: CompanyDataType;
  categoryOptions: SelectAdvancedFilterOptionType[];
  communityOptions: SelectAdvancedFilterOptionType[];
  careTypeOptions: SelectAdvancedFilterOptionType[];
  unitTypeOptions: SelectAdvancedFilterOptionType[];
  filters: RecommendationFilterType;
  updateFilters: (filters: RecommendationFilterType) => void;
  resetFilters: () => void;
};

const RecommendationsContext = createContext<RecommendationsContextType | null>(null);

export const RecommendationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { selectedCompany, selectedCommunity, communityList, apartmenttypes } = useAppSelector((state) => state.global);
  const [filters, setFilters] = useState<RecommendationFilterType>({
    communities: [selectedCommunity?.id].filter(Boolean) as number[],
    careTypes: [],
    categories: [],
  });

  const categoryOptions: SelectAdvancedFilterOptionType[] = Object.values(RECOMMENDATION_CATEGORIES).map(
    (category) => ({
      value: category.value,
      label: category.label,
    })
  );
  const communityOptions: SelectAdvancedFilterOptionType[] = communityList.map((community) => ({
    value: community.id,
    label: community.community.apartmentname,
  }));
  const careTypeOptions: SelectAdvancedFilterOptionType[] = WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.CARE_TYPE]
    .filter((i) => i.id)
    .map((item) => ({
      label: item.name,
      value: Number(item.id),
    }));
  const unitTypeOptions: SelectAdvancedFilterOptionType[] = apartmenttypes
    .filter((i) => i.id)
    .map((item) => ({
      label: item.type,
      value: Number(item.id),
    }));

  const updateFilters = (newFilters: RecommendationFilterType) => {
    setFilters((draft) => ({ ...draft, ...newFilters }));
  };

  const resetFilters = () => {
    setFilters({
      communities: [selectedCommunity?.id].filter(Boolean) as number[],
      careTypes: [],
      categories: [],
    });
  };

  if (!selectedCompany) {
    return null;
  }

  const value = {
    company: selectedCompany,
    categoryOptions,
    communityOptions,
    careTypeOptions,
    unitTypeOptions,
    filters,
    updateFilters,
    resetFilters,
  };
  return <RecommendationsContext.Provider value={value}>{children}</RecommendationsContext.Provider>;
};

const useRecommendationsContext = () => {
  const context = React.useContext(RecommendationsContext);
  if (!context) {
    throw new Error('useRecommendationsContext must be used within a RecommendationsProvider');
  }
  return context;
};

export default useRecommendationsContext;
