import React from 'react';

import AggregationTypePicker from 'components/lib/AggregationTypePicker';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';
import { AGGREGATION_TYPE } from 'components/View/constants';

import { CMASummaryFilters } from './types';

const CMASummaryGroupAggregationFilter = () => {
  const { viewFilters, setViewFilters } = useCommunityViewContext();
  const filters = viewFilters as CMASummaryFilters;

  return (
    <AggregationTypePicker
      aggregationType={filters?.aggregationType ?? AGGREGATION_TYPE.AVG}
      onSelect={(value: number) => setViewFilters((prevState: any) => ({ ...prevState, aggregationType: value }))}
      containerStyle={{
        borderTop: 'unset',
        maxHeight: 'unset',
        padding: '0',
      }}
      buttonsWrapperStyle={{ height: 'unset' }}
      filterButtonStyle={{ height: 'unset' }}
    />
  );
};

export default CMASummaryGroupAggregationFilter;
