import React from 'react';

import styled from 'styled-components';

import CareTypeFilter from 'common/Filters/CareTypeFilter';
import CompetitorsFilter from 'common/Filters/CompetitorsFilter';
import ReviewSourceFilter from 'common/Filters/ReviewSourceFilter';
import RoomTypeFilter from 'common/Filters/RoomTypeFilter';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import CMASummaryGroupAggregationFilter from './CMASummaryGroupAggregationFilter';
import CMASummaryRoomComparisonTypeFilter from './filters/CMASummaryRoomComparisonTypeFilter';
import CMASummaryViewFilter from './filters/CMASummaryViewFilter';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  gap: 5px;
`;

const CMASummaryFilters = () => {
  const { competitors, viewFilters } = useCommunityViewContext();

  const competitorsFilterOptions =
    competitors?.map((item) => ({
      label: item.comunity_name,
      value: item.id,
      color: item.color,
    })) || [];

  return (
    <Container>
      <CMASummaryGroupAggregationFilter />
      <CMASummaryViewFilter />
      <CMASummaryRoomComparisonTypeFilter />
      <ReviewSourceFilter reviewSources={viewFilters?.reviewSources} />
      {Boolean(competitors && competitors.length > 1) && (
        <CompetitorsFilter competitors={viewFilters?.competitors ?? []} options={competitorsFilterOptions} />
      )}
      <CareTypeFilter careTypes={viewFilters?.careTypes ?? []} />
      <RoomTypeFilter roomTypes={viewFilters?.roomTypes ?? []} />
    </Container>
  );
};

export default CMASummaryFilters;
