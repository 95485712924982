import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';
import { SelectAdvancedFilterOptionType } from 'types/common';
import { RecommendationDetailsFilterType } from 'types/recommendations';

const Container = styled.div`
  display: flex;
  gap: 5px;
`;

interface RecommendationCommunityListFiltersProps {
  filters: RecommendationDetailsFilterType;
  communityOptions: SelectAdvancedFilterOptionType[];
  onFiltersChange: (filters: Record<string, any>) => void;
}

const RecommendationCommunityListFilters = ({
  filters,
  communityOptions,
  onFiltersChange,
}: RecommendationCommunityListFiltersProps) => {
  const handleSelection = (filedName: string) => (selection: any) => {
    onFiltersChange({ [filedName]: selection.map((c: any) => c.value) });
  };

  return (
    <Container>
      <SelectAdvancedFilter
        placeholder="Community"
        options={communityOptions}
        defaultValue={filters.communities}
        onSelect={handleSelection('communities')}
        className={classNames({ active: filters.communities.length > 0 })}
      />
    </Container>
  );
};

export default RecommendationCommunityListFilters;
