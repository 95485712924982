import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';
import { SelectAdvancedFilterOptionType } from 'types/common';
import { RecommendationCommunityFilterType } from 'types/recommendations';

const Container = styled.div`
  display: flex;
  gap: 5px;
`;

const ResetContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 10px;
  padding: 0px 10px;
  margin: 10px 5px;
  border-left: 1px solid var(--line-gray);
`;

const Link = styled.span`
  color: var(--text-secondary);
  font-size: 10px;
  cursor: pointer;
`;

interface RecommendationCommunityFiltersProps {
  filters: RecommendationCommunityFilterType;
  careTypeOptions: SelectAdvancedFilterOptionType[];
  unitTypeOptions: SelectAdvancedFilterOptionType[];
  onFiltersChange: (filters: Record<string, any>) => void;
}

const RecommendationCommunityFilters = ({
  filters,
  careTypeOptions,
  unitTypeOptions,
  onFiltersChange,
}: RecommendationCommunityFiltersProps) => {
  const hasCareTypeOptions = careTypeOptions.length > 0;
  const hasUnitTypeOptions = unitTypeOptions.length > 0;
  const hasAnyOptions = hasCareTypeOptions || hasUnitTypeOptions;

  const handleSelection = (filedName: string) => (selection: any) => {
    onFiltersChange({ [filedName]: selection.map((c: any) => c.value) });
  };

  const resetFilters = () => {
    onFiltersChange({ careTypes: [], unitTypes: [] });
  };

  return (
    <Container>
      {hasCareTypeOptions && (
        <SelectAdvancedFilter
          placeholder="Care Type"
          options={careTypeOptions}
          defaultValue={filters.careTypes}
          onSelect={handleSelection('careTypes')}
          className={classNames({ active: filters.careTypes.length > 0 })}
        />
      )}
      {hasUnitTypeOptions && (
        <SelectAdvancedFilter
          placeholder="Room Type"
          options={unitTypeOptions}
          defaultValue={filters.unitTypes}
          onSelect={handleSelection('unitTypes')}
          className={classNames({ active: filters.unitTypes.length > 0 })}
        />
      )}
      {hasAnyOptions && (
        <ResetContainer>
          <Link onClick={resetFilters}>Reset</Link>
        </ResetContainer>
      )}
    </Container>
  );
};

export default RecommendationCommunityFilters;
