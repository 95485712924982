import React from 'react';

import { Popconfirm } from 'antd';
import styled from 'styled-components';

import Button from 'components/lib/Button';
import { useAppSelector } from 'store/hook';

import { useEditBatchShopRequestsModalContext } from './EditBatchShopRequestsModalContext';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
`;

const StyledDangerButton = styled(Button)`
  color: var(--negative-orange);
  border-color: var(--negative-orange);
  background-color: rgba(250, 117, 80, 0.09);
`;

type Props = {
  onOk: () => void;
  onCancel: () => void;
  onDelete: () => void;
};

const EditBatchShopRequestModalFooter = ({ onOk, onCancel, onDelete }: Props) => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { isLoading, batchShopRequests } = useEditBatchShopRequestsModalContext();
  const { status, shopper_id, due_date } = batchShopRequests;

  const hasRequiredFields = shopper_id || status || due_date;

  const disabled = isLoading || !hasRequiredFields;

  return (
    <Container>
      <div>
        {currentUser?.isSuperuser && (
          <Popconfirm
            title="Delete shop requests"
            description="Are you sure you want to delete shop requests?"
            okText="Yes"
            cancelText="Cancel"
            onConfirm={onDelete}
          >
            <StyledDangerButton danger style={{ width: '91px' }}>
              Delete
            </StyledDangerButton>
          </Popconfirm>
        )}
      </div>
      <div>
        <Button type="primary" onClick={onOk} disabled={disabled} style={{ width: '91px' }}>
          Save
        </Button>
        <Button onClick={onCancel} style={{ width: '91px' }}>
          Close
        </Button>
      </div>
    </Container>
  );
};

export default EditBatchShopRequestModalFooter;
