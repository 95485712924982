import React from 'react';

import { Divider as AntDivider } from 'antd';
import styled from 'styled-components';

import { RecommendationCommunityOverviewType } from 'types/recommendations';

import RecommendationCommunity from './RecommendationCommunity';
import RecommendationsNotFound from './RecommendationsNotFound';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Divider = styled(AntDivider)`
  margin: 10px 0px;
`;

interface RecommendationCommunityListProps {
  data: RecommendationCommunityOverviewType[] | undefined;
}

const RecommendationCommunityList = ({ data }: RecommendationCommunityListProps) => {
  if (!data) {
    return null;
  }

  const count = data.length;
  if (count === 0) {
    return <RecommendationsNotFound />;
  }

  return (
    <Container>
      {data.map((d, index) => (
        <div key={d.id}>
          <RecommendationCommunity data={d} />
          {index < count - 1 && <Divider />}
        </div>
      ))}
    </Container>
  );
};

export default RecommendationCommunityList;
