import { CareFeeDateType, LEVELS } from 'pages/price_healing/Fees/constants';

import {
  LivingInfoDataType,
  LivingInfoGroupedDataType,
  LivingInfoStatsType,
  OccupancyDataType,
  OccupancyStatsType,
} from './types';

export const getLevelsAverage = (data: CareFeeDateType | undefined) => {
  if (data) {
    const { levelsSum, levelsCount } = (LEVELS as Array<keyof CareFeeDateType>).reduce(
      (acc, level) => {
        const value = Number(data[level]) || 0;
        if (value) {
          acc.levelsSum += value;
          acc.levelsCount += 1;
        }
        return acc;
      },
      { levelsSum: 0, levelsCount: 0 }
    );

    if (levelsCount > 0) {
      return levelsSum / levelsCount;
    }
  }
};

export const groupLivingInfoByApartmentType = (data: LivingInfoDataType[]): LivingInfoGroupedDataType[] => {
  const grouped: Record<number, LivingInfoGroupedDataType> = {};
  const OTHER_GROUP_ID = -1;

  data.forEach((item) => {
    const group = item.apartment_type_groups[0];

    const groupId = group ? group.id : OTHER_GROUP_ID;
    const groupName = group ? group.name : 'Other';

    if (!grouped[groupId]) {
      grouped[groupId] = {
        group_name: groupName,
        group_id: groupId,
        apartments: [],
      };
    }

    grouped[groupId].apartments.push(item);
  });

  return Object.values(grouped);
};

export const calculateOccupancyStats = (data: OccupancyDataType[]): OccupancyStatsType => {
  return data.reduce(
    (acc, item) => {
      acc.totalUnits += item.total_apartments_units;
      acc.freeUnits += item.free_apartments_units;

      acc.occupancyPercentage = ((acc.totalUnits - acc.freeUnits) / acc.totalUnits) * 100;
      return acc;
    },
    { totalUnits: 0, freeUnits: 0, occupancyPercentage: 0 }
  );
};

export const formatNumberWithCommas = (number: number): string => {
  return new Intl.NumberFormat('en-US').format(number);
};

export const calculateLivingInfoStats = (data: LivingInfoDataType[]): LivingInfoStatsType => {
  let totalBaseRent = 0;
  let totalSqFootage = 0;
  let totalItemsWithSqFootage = 0;
  let minBaseRentItem: LivingInfoDataType | null = null;
  let maxBaseRentItem: LivingInfoDataType | null = null;

  data.forEach((item) => {
    totalBaseRent += item.base_rent;

    if (item.sq_footage !== null) {
      totalSqFootage += item.sq_footage;
      totalItemsWithSqFootage++;
    }

    if (!minBaseRentItem || item.base_rent < minBaseRentItem.base_rent) {
      minBaseRentItem = item;
    }

    if (!maxBaseRentItem || item.base_rent > maxBaseRentItem.base_rent) {
      maxBaseRentItem = item;
    }
  });

  const avgBaseRent = data.length > 0 ? parseFloat((totalBaseRent / data.length).toFixed(1)) : 0;

  const avgSqFootage =
    totalItemsWithSqFootage > 0 ? parseFloat((totalSqFootage / totalItemsWithSqFootage).toFixed(1)) : 0;

  return {
    minBaseRentItem,
    maxBaseRentItem,
    avgBaseRent,
    avgSqFootage,
  };
};
