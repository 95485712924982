import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { useAppSelector } from 'store/hook';

import { useAccommodationContext } from './AccommodationContext';
import { LivingInfoDataType } from './constants';
import LivingInfoDataTable from './LivingInfoDataTable';
import { mapCommunityLivingInfoDataToRequest, mapCommunityLivingInfoToData } from './utils';
import { createLivingInfo, deleteLivingInfo, getLivingInfo, updateLivingInfo } from '../../../apis/CommunityAPI';
import { useRootContext } from '../../../components/layout/RootContext';
import { STATUS } from '../../../constants';

interface Props {
  livingType: number;
}

const LivingInfoData = ({ livingType }: Props) => {
  const { showMessage } = useRootContext();
  const names = useAppSelector((state) => state.global.livingtypes);

  const { communityId } = useAccommodationContext();

  const [dataLoading, setDataLoading] = useState(STATUS.IDLE);

  const {
    data: accommodationData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['accommodationData', livingType, communityId],
    queryFn: () => getLivingInfo(communityId as number, { living_type: livingType, active: 1 }),
    select: (data: any[]) => {
      const formatted_data: LivingInfoDataType[] = data?.map((item: any) => mapCommunityLivingInfoToData(item));
      return formatted_data ?? [];
    },
    onError: () => {
      showMessage('error', 'Failed to fetch Accommodation Data');
      setDataLoading(STATUS.FAILURE);
    },
    enabled: Boolean(communityId),
    refetchOnWindowFocus: false,
  });

  const handleCreate = (item: Partial<LivingInfoDataType>) => {
    setDataLoading(STATUS.LOADING);

    // Append Living Type Id field not being used in the Table Form
    Object.assign(item, { livingTypeId: livingType });

    const request_data = mapCommunityLivingInfoDataToRequest(item);
    createLivingInfo(communityId as number, [request_data])
      .then((response) => {
        refetch();
        showMessage('success', 'Accommodation created successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to create Accommodation');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleUpdate = (item: Partial<LivingInfoDataType>) => {
    setDataLoading(STATUS.LOADING);

    // Append Living Type Id field not being used in the Table Form
    Object.assign(item, { livingTypeId: livingType });

    const request_data = mapCommunityLivingInfoDataToRequest(item);

    updateLivingInfo(communityId as number, item.id as number, request_data)
      .then((response: any) => {
        refetch();
        showMessage('success', 'Accommodation changed successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason: any) => {
        console.error(reason);
        showMessage('error', 'Failed to change Accommodation');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleDelete = (item: Partial<LivingInfoDataType>) => {
    setDataLoading(STATUS.LOADING);
    deleteLivingInfo(communityId as number, item.id as number)
      .then((response) => {
        refetch();
        showMessage('success', 'Accommodation deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Accommodation');
        setDataLoading(STATUS.FAILURE);
      });
  };

  return (
    <LivingInfoDataTable
      loading={dataLoading === STATUS.LOADING || isLoading}
      data={accommodationData ?? []}
      livingTypeName={names[livingType - 1].type}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  );
};

export default LivingInfoData;
