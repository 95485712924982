import React, { createContext, useContext } from 'react';

import { ShopNotesType } from '../types';

type LatestShopNotesContentType = {
  latestShopNotes: ShopNotesType | undefined;
};

export const LatestShopNotesContext = createContext<LatestShopNotesContentType>({
  latestShopNotes: undefined,
});

export const useLatestShopNotesContext = () => useContext(LatestShopNotesContext);
