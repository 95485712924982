import React from 'react';
import { Outlet } from 'react-router-dom';

import { RecommendationsProvider } from './RecommendationsContext';

const RecommendationsRoot = () => {
  return (
    <RecommendationsProvider>
      <Outlet />
    </RecommendationsProvider>
  );
};

export default RecommendationsRoot;
