import React from 'react';

import styled from 'styled-components';

import CommunityIncentivesCreateModal from './CommunityIncentivesCreateModal';
import CommunityIncentivesFilters from './CommunityIncentivesFilters';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CommunityIncentivesControls = () => {
  return (
    <Container>
      <CommunityIncentivesFilters />
      <CommunityIncentivesCreateModal />
    </Container>
  );
};

export default CommunityIncentivesControls;
