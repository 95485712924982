import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { uuid } from 'short-uuid';
import styled from 'styled-components';

import IncentiveUnitArchiveModal from './archive/IncentiveUnitArchiveModal';
import { INCENTIVES_PAGE_SIZE } from './constants';
import IncentivesControls from './controls/IncentivesControls';
import { useIncentivesContext } from './IncentivesContext';
import { IncentivesDataContext } from './IncentivesDataContext';
import IncentivesTable from './table/IncentivesTable';
import { useAppSelector } from '../../store/hook';
import useDataList from '../../utils/hooks/useDataList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  height: 100%;

  &.simple {
    width: 100%;
    padding: 0;
  }
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 20px;
  color: var(--text-primary);
  margin-bottom: 10px;
`;

interface Props {
  title?: string;
  createMode?: boolean;
  archiveMode?: boolean;
  onSelectionChanged: any;
}

const IncentivesData = ({ createMode = false, archiveMode = false, title = '', onSelectionChanged }: Props) => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const { setKey, currentTab, search } = useIncentivesContext();

  const [selectionMode, setSelectionMode] = useState(false);
  const [totalItemsUuids, setTotalItemsUuids] = useState<string[]>([]);
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
  const [archivedItemsIds, setArchivedItemsIds] = useState<string[]>([]);

  const { items, totalItemsCount, filters, ordering, setItems, setPage, setFilters, setOrdering } = useDataList({
    initialState: {
      ordering: undefined,
      filters: {
        page: 1,
        pageSize: INCENTIVES_PAGE_SIZE,
        search: '',
        communities: selectedCommunity ? [selectedCommunity.id] : [],
        roomTypes: [],
        careTypes: [],
        occupancyRange: [],
        unitAvailability: [],
        unitVacancyDayCount: undefined,
        incentiveStatus: [],
        proposalStatus: '',
      },
    },
  });

  useEffect(() => {
    resetFilters();
    setSelectionMode(Boolean(createMode));
    setItems({ results: [], count: 0 });
    setFilters({ search });
  }, [currentTab]);

  const resetFilters = () => {
    setFilters({
      page: 1,
      search: '',
      communities: selectedCommunity ? [selectedCommunity.id] : [],
      roomTypes: [],
      careTypes: [],
      occupancyRange: [],
      unitAvailability: [],
      unitVacancyDayCount: undefined,
      incentiveStatus: [],
      proposalStatus: '',
    });
  };

  const handleIncentiveDeleted = () => {
    setSelectedItemsIds([]);
    setArchivedItemsIds([]);
    setKey(uuid());
  };

  const handleSelection = (items: any[]) => {
    onSelectionChanged(items);
  };

  return (
    <IncentivesDataContext.Provider
      value={{
        createMode,
        selectionMode,
        archiveMode,
        items: items ?? [],
        totalItemsCount,
        filters,
        ordering,
        totalItemsUuids,
        setTotalItemsUuids,
        selectedItemsIds,
        archivedItemsIds,
        setSelectionMode,
        setSelectedItemsIds,
        setArchivedItemsIds,
        setItems,
        setPage,
        setFilters,
        setOrdering,
        resetFilters,
        handleSelection,
      }}
    >
      <Container className={classNames({ simple: createMode })}>
        <IncentiveUnitArchiveModal
          open={Boolean(archivedItemsIds.length)}
          incentives={archivedItemsIds}
          onClose={handleIncentiveDeleted}
        />
        {title && <Title>{title}</Title>}
        <IncentivesControls />
        <IncentivesTable />
      </Container>
    </IncentivesDataContext.Provider>
  );
};

export default IncentivesData;
