import React from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import { getRecommendations } from 'apis/RecommendationsAPI';
import Header from 'common/Header';
import ErrorMessage from 'components/lib/ErrorMessage';
import Loader from 'components/lib/Loader';

import RecommendationFilters from './RecommendationFilters';
import RecommendationList from './RecommendationList';
import useRecommendationsContext from './RecommendationsContext';
import { useAppSelector } from '../../store/hook';

const Container = styled.div`
  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 30px;
`;

const Recommendations = () => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const { company, filters } = useRecommendationsContext();
  const { isLoading, data, isError } = useQuery({
    queryKey: ['company', company.id, filters],
    queryFn: () =>
      getRecommendations(company.id, {
        communities: filters.communities,
        care_types: filters.careTypes,
        categories: filters.categories,
      }),
    refetchOnWindowFocus: false,
  });

  return (
    <Container>
      <Header title="AI Recommendations" breadcrumbs={company.name} iconSrc="/icons/ai-blob.svg" />
      <Main>
        <RecommendationFilters hideCommunitiesFilter={!!selectedCommunity} />
        <Loader active={isLoading} />
        <ErrorMessage text="Failed to load AI recommendations. Please try again." active={isError} />
        <RecommendationList data={data} />
      </Main>
    </Container>
  );
};

export default Recommendations;
