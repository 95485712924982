import React from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import { Divider } from 'components/lib/Divider';

import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';
import { IncentiveProposalPricingType } from './types';
import Checkbox from '../../lib/Checkbox';
import { INCENTIVE_TYPE } from '../constants';
import {
  INCENTIVES_FEES_TYPE,
  INCENTIVES_FEES_TYPE_LABELS,
  PROPOSAL_MODAL_INCENTIVES_FEES_TYPE,
} from '../create/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const StyledCheckbox = styled(Checkbox)`
  min-width: 150px;

  .ant-checkbox-inner {
    height: 18px;
    width: 18px;
  }
`;

const CheckboxLabel = styled.span`
  font-size: 14px;
  color: var(--text-primary);
  padding-top: 2px;
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  margin-bottom: 10px;
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
  margin-top: 20px;
`;

interface Props {
  type: string;
  pricingItem?: IncentiveProposalPricingType;
}

const DisplayCheckbox = ({ type, pricingItem }: Props) => {
  const { rates, readOnly } = useIncentivesProposalModalContext();

  const hasDiscountedRate = rates.some(
    (rate: IncentiveProposalPricingType) => rate.type === type && Boolean(rate.discount_type) && Boolean(rate.discount)
  );

  // we want these items to be checked and disabled in all cases
  const isAdditionalDiscount = pricingItem?.incentive_type === INCENTIVE_TYPE.ADDITIONAL_DISCOUNT;
  const alwaysDisabledItems = [PROPOSAL_MODAL_INCENTIVES_FEES_TYPE.MONTHLY_RENT].includes(type) || isAdditionalDiscount;

  return (
    <StyledCheckbox value={type} disabled={hasDiscountedRate || alwaysDisabledItems || readOnly} className={'green'}>
      <CheckboxLabel>{INCENTIVES_FEES_TYPE_LABELS[type] ?? type}</CheckboxLabel>
    </StyledCheckbox>
  );
};

const IncentivesProposalModalProposalDisplay = () => {
  const { rates } = useIncentivesProposalModalContext();

  const customRates = rates.filter((item: IncentiveProposalPricingType) => item.type === INCENTIVES_FEES_TYPE.CUSTOM);

  return (
    <Container>
      <Title>Rates to Display on Proposal</Title>
      <Divider />
      <Form.Item name={'rates'}>
        <StyledCheckboxGroup style={{ columnGap: 30, rowGap: 15, width: '100%' }}>
          <DisplayCheckbox type={INCENTIVES_FEES_TYPE.MONTHLY_RENT} />
          <DisplayCheckbox type={INCENTIVES_FEES_TYPE.COMMUNITY_FEE} />
          <DisplayCheckbox type={INCENTIVES_FEES_TYPE.MOVE_IN_FEE} />
          <DisplayCheckbox type={INCENTIVES_FEES_TYPE.DEPOSIT} />
          <DisplayCheckbox type={INCENTIVES_FEES_TYPE.PET_FEE} />
          <DisplayCheckbox type={INCENTIVES_FEES_TYPE.PARKING_FEE} />
          <DisplayCheckbox type={INCENTIVES_FEES_TYPE.SECOND_PERSON_FEE} />
          <DisplayCheckbox type={INCENTIVES_FEES_TYPE.ADDITIONAL_FEE} />
          {customRates.map((item: IncentiveProposalPricingType) => (
            <DisplayCheckbox key={item.name ?? ''} type={item.name ?? ''} pricingItem={item} />
          ))}
        </StyledCheckboxGroup>
      </Form.Item>
    </Container>
  );
};

export default IncentivesProposalModalProposalDisplay;
