import React from 'react';

import SelectAdvancedFilterDate from 'components/lib/SelectAdvancedFilterDate';

import { SHOP_REQUEST_DUE_DATE_RANGES } from '../constants';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';

const ShopRequestsDueDateStartFilter = () => {
  const { filters, setFilters } = useShopRequestsDataContext();
  const maxDate = filters.dueDateEnd ? new Date(filters.dueDateEnd) : undefined;
  return (
    <SelectAdvancedFilterDate
      selected={filters.dueDateStart ? new Date(filters.dueDateStart) : new Date()}
      minDate={new Date()}
      maxDate={maxDate}
      dueDate={filters.dueDateStart}
      label="Due Date Start"
      onChange={(date: any) =>
        setFilters({
          ...filters,
          dueDateStart: date,
          dueDateRange: SHOP_REQUEST_DUE_DATE_RANGES.ALL_REQUESTS,
        })
      }
    />
  );
};

export default ShopRequestsDueDateStartFilter;
