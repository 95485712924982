import React from 'react';

import styled from 'styled-components';

const Container = styled.p`
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--negative-orange);
  padding: 20px 30px;
`;

interface ErrorProps {
  text: string;
  active: boolean;
}

const ErrorMessage = ({ text, active }: ErrorProps) => {
  if (!text) {
    return null;
  }

  if (!active) {
    return null;
  }

  return <Container>{text}</Container>;
};

export default ErrorMessage;
