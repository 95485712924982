import React, { useMemo } from 'react';

import styled from 'styled-components';

import { useWidgetContext } from './WidgetContext';
import WidgetFiltersInfoLabel from './WidgetFiltersInfoLabel';
import WidgetFiltersInfoSelect from './WidgetFiltersInfoSelect';
import { useCommunityViewContext } from '../../../components/View/CommunityViewContext';
import { useAppSelector } from '../../../store/hook';
import { SWOT_CATEGORIES, WIDGET_FILTER_TYPES, WIDGET_FILTERS, WIDGET_TYPES } from '../constants';
import { DATA_RANGE_TYPE } from '../Widget/constants';

const Container = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--text-secondary);
  gap: 4px;

  span:last-child {
    display: none;
  }
`;

const WidgetFiltersInfo = () => {
  const { livingtypes, apartmenttypes } = useAppSelector((state) => state.global);

  const { communityId, competitors } = useCommunityViewContext();
  const { type, filters, rangeType, setMeta, meta = { reviewsCommunityOverride: undefined } } = useWidgetContext();
  const {
    careTypes,
    roomTypes,
    careLevels,
    reviewSources,
    reviewCategories,
    competitors: filteredCompetitors,
    swotCategories,
  } = filters;
  const { reviewsCommunityOverride } = meta;
  const handleReviewsCompetitorSelection = (selectedCommunityId: number) => {
    setMeta({ reviewsCommunityOverride: selectedCommunityId });
  };

  const isCompetitorInFilters = (competitorId: number) => {
    return !filteredCompetitors.length || filteredCompetitors.includes(competitorId);
  };
  const filteredReviewsCommunityOverride = useMemo(
    () => (isCompetitorInFilters(reviewsCommunityOverride) ? reviewsCommunityOverride : undefined),
    [reviewsCommunityOverride, filteredCompetitors]
  );
  const competitorOptions = [
    { id: communityId, name: 'My Community' },
    ...(competitors || [])
      .filter((competitor: any) => isCompetitorInFilters(competitor.id))
      .map((competitor: any) => ({ id: competitor.id, name: competitor.comunity_name })),
  ];

  const averageLabelConditions = [
    WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.CARE_TYPE) && careTypes?.length !== 1,
    WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.ROOM_TYPE) && roomTypes?.length !== 1,
    WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.CARE_LEVEL) && careLevels?.length !== 1,
    WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.REVIEW_CATEGORY) && reviewCategories?.length !== 1,
    WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.REVIEW_SOURCE) && reviewSources?.length !== 1,
    rangeType === DATA_RANGE_TYPE.OVERTIME,
  ];

  const showAverageLabel = averageLabelConditions.some((condition) => condition);
  const swotCategoryOptions = SWOT_CATEGORIES.map((category) => ({ id: category.value, name: category.label }));
  const livingTypeOptions = livingtypes.map((type) => ({ id: type.id, name: type.type }));
  const apartmentTypesOptions = apartmenttypes.map((type) => ({ id: type.id, name: type.type }));

  return (
    <Container>
      <WidgetFiltersInfoLabel
        widgetType={type}
        filterType={WIDGET_FILTER_TYPES.CARE_TYPE}
        selection={careTypes}
        options={livingTypeOptions}
      />
      <WidgetFiltersInfoLabel
        widgetType={type}
        filterType={WIDGET_FILTER_TYPES.ROOM_TYPE}
        selection={roomTypes}
        options={apartmentTypesOptions}
      />
      <WidgetFiltersInfoLabel widgetType={type} filterType={WIDGET_FILTER_TYPES.CARE_LEVEL} selection={careLevels} />
      <WidgetFiltersInfoLabel
        widgetType={type}
        filterType={WIDGET_FILTER_TYPES.REVIEW_SOURCE}
        selection={reviewSources}
      />
      <WidgetFiltersInfoLabel
        widgetType={type}
        filterType={WIDGET_FILTER_TYPES.REVIEW_CATEGORY}
        selection={reviewCategories}
      />
      {type === WIDGET_TYPES.REVIEWS && (
        <WidgetFiltersInfoSelect
          selection={filteredReviewsCommunityOverride ?? communityId}
          onSelect={handleReviewsCompetitorSelection}
          options={competitorOptions}
        />
      )}
      <WidgetFiltersInfoLabel
        widgetType={type}
        filterType={WIDGET_FILTER_TYPES.SWOT_CATEGORY}
        selection={swotCategories}
        options={swotCategoryOptions}
      />
      {showAverageLabel && (
        <>
          <span>Average</span>
          <span>·</span>
        </>
      )}
    </Container>
  );
};

export default WidgetFiltersInfo;
