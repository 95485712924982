import { RecommendationDetailsType, RecommendationType } from 'types/recommendations';

import { QueryParamsType } from './types';
import axios from '../config/axiosPrivate';

export const getRecommendations = async (
  companyId: number,
  filters: QueryParamsType
): Promise<RecommendationType[]> => {
  const response = await axios.get(`/recommendations/?company_id=${companyId}`, { params: filters });
  return response.data;
};

export const getRecommendationDetails = async (id: number): Promise<RecommendationDetailsType> => {
  const response = await axios.get(`/recommendations/${id}`);
  return response.data;
};
