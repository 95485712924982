import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

import { MYSTERY_SHOP_REQUEST_DUE_DATES } from 'components/MysteryShopping/constants';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export const getDateString = (period: string, exactDate = new Date()): Date => {
  const now = dayjs();
  let futureDate, date;

  switch (period) {
    case MYSTERY_SHOP_REQUEST_DUE_DATES.IN_A_WEEK:
      futureDate = now.add(1, 'week').set('hour', 12);
      date = futureDate;
      break;
    case MYSTERY_SHOP_REQUEST_DUE_DATES.IN_2_WEEKS:
      futureDate = now.add(2, 'week').set('hour', 12);
      date = futureDate;
      break;
    case MYSTERY_SHOP_REQUEST_DUE_DATES.SPECIFIC_DATE:
      date = dayjs(exactDate).set('hour', 12);
      break;
    case MYSTERY_SHOP_REQUEST_DUE_DATES.IN_3_DAYS:
    default:
      futureDate = now.add(3, 'day').set('hour', 12);
      date = futureDate;
      break;
  }

  return date.toDate();
};

export const getFormattedDate = (date: any, format: string) => dayjs(date).format(format);

export const isBeforeToday = (date: any) => dayjs(date).isBefore(new Date(), 'day');

export const getRelativeTime = (date: any) => dayjs(date).fromNow();

export const getDaysLeft = (targetDate: any) => {
  if (!targetDate) {
    return null;
  }

  const date = dayjs(targetDate);
  const daysLeft = Math.round(date.diff(dayjs(), 'day', true));

  if (daysLeft < 0) {
    return 'expired';
  }

  return `${daysLeft} days left`;
};
