import React from 'react';

import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import { CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE } from '../constants';
import { CMASummaryFilters } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: row;
`;

const Bold = styled.div`
  font-family: var(--font-bold);
  font-size: 12;
  margin-right: 3px;
`;

const CMASummaryViewFilterDropdownLabel = () => {
  const { viewFilters } = useCommunityViewContext();
  const filters = viewFilters as CMASummaryFilters;

  return filters?.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.DONT_DISPLAY ? (
    <span>View</span>
  ) : (
    <Container>
      <Bold>View:</Bold>
      <div>Display Value Differences</div>
    </Container>
  );
};

export default CMASummaryViewFilterDropdownLabel;
