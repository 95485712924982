import React from 'react';

import styled from 'styled-components';

const Container = styled.span``;

const Beta = styled.span`
  margin-left: 4px;
  padding: 4px 8px;
  color: var(--competitor-purple);
  background: var(--neutral-purple-light);
  font-family: var(--font-bold);
  border-radius: 6px;
`;

type Props = {
  label: string;
};

const SidebarMenuItemBetaTag = ({ label }: Props) => (
  <Container>
    {label}
    <Beta>Beta</Beta>
  </Container>
);

export default SidebarMenuItemBetaTag;
