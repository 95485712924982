import React, { useMemo } from 'react';
import Markdown from 'react-markdown';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'antd';
import styled from 'styled-components';

import ChevronRight from 'common/Icons/ChevronRight';
import { useAppSelector } from 'store/hook';
import { RecommendationType } from 'types/recommendations';

import RecommendationCategoryTag from './RecommendationCategoryTag';
import RecommendationMarkdown from './RecommendationMarkdown';
import useRecommendationsContext from './RecommendationsContext';
import { getRecommendationDefaultCategoryScheme, parseTextFromMarkDown } from './utils';

const Container = styled.div`
  width: 570px;
  min-height: 156px;
  max-height: 175px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-gray);
  background-color: #fff;
  display: flex;
`;

interface IndicatorProps {
  gradientFrom: string;
  gradientTo: string;
}

const Indicator = styled.div<IndicatorProps>`
  width: 5px;
  min-width: 5px;
  border-radius: 4px;
  background-image: ${({ gradientFrom, gradientTo }) => `linear-gradient(to bottom, ${gradientFrom}, ${gradientTo})`};
`;

const Details = styled.div`
  padding: 20px;
`;

const Reasoning = styled.div`
  width: 100%;
  height: 57px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);
`;

const Includes = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 520px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

const CategoryTagContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const DetailsButtonContainer = styled.div`
  .gradient-border-button {
    box-shadow: 0 0 4px 0 #ff9ac0;
    position: relative;
    z-index: 1;
    border: none;
    overflow: hidden;
    transition:
      color 0.3s,
      background-color 0.3s;
  }

  .gradient-border-button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(to left, #007aff, #fa7550 50%, #ff5b98);
    border-radius: inherit;
    padding: 1px;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  .content {
    font-size: 12px;
    font-weight: 500;
    background-image: linear-gradient(to left, var(--competitor-purple), #ff5b98);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    color: #ff5b98;
    width: 16px;
    height: 16px;
  }
`;

type RecommendationCardProps = {
  recommendation: RecommendationType;
};

const RecommendationCard = ({ recommendation }: RecommendationCardProps) => {
  const navigate = useNavigate();
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const { search } = useLocation();
  const { filters, communityOptions, careTypeOptions } = useRecommendationsContext();
  const colorScheme = getRecommendationDefaultCategoryScheme(recommendation);
  const filterSelections = useMemo(() => {
    const filteredCommunities = communityOptions
      .filter(
        (co) =>
          filters.communities.includes(Number(co.value)) &&
          recommendation.report.community_ids.includes(Number(co.value)) &&
          !(selectedCommunity && filters.communities.includes(selectedCommunity.id))
      )
      .map((co) => co.label);
    const filteredCareTypes = careTypeOptions
      .filter(
        (co) =>
          filters.careTypes.includes(Number(co.value)) && recommendation.report.care_types.includes(Number(co.value))
      )
      .map((co) => co.label);

    return filteredCommunities.concat(filteredCareTypes);
  }, [recommendation, filters, communityOptions, careTypeOptions, selectedCommunity]);

  const handleDetailsClick = () => {
    navigate(`/recommendations/${recommendation.id}` + search);
  };

  const categories = [
    ...recommendation.config.data_points.reduce((acc, dp) => {
      if (dp.category) {
        acc.add(dp.category);
      }
      return acc;
    }, new Set<string>()),
  ];

  const descriptionPlain = parseTextFromMarkDown(recommendation.description);

  return (
    <Container>
      <Indicator gradientFrom={colorScheme.gradientFrom} gradientTo={colorScheme.gradientTo} />
      <Details>
        {filterSelections.length > 0 && <Includes>Includes selection {filterSelections.join(', ')}</Includes>}
        <RecommendationMarkdown>{recommendation.title}</RecommendationMarkdown>
        <Reasoning>{descriptionPlain}</Reasoning>
        <Footer>
          <CategoryTagContainer>
            {categories.map((c, index) => (
              <RecommendationCategoryTag key={index} category={c} />
            ))}
          </CategoryTagContainer>
          <DetailsButtonContainer>
            <Button className="gradient-border-button" size="small" onClick={handleDetailsClick}>
              <div className="content">
                Details <ChevronRight fill="var(--competitor-purple)" width={16} height={16} />
              </div>
            </Button>
          </DetailsButtonContainer>
        </Footer>
      </Details>
    </Container>
  );
};

export default RecommendationCard;
