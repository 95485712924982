import React from 'react';

import { useAppSelector } from 'store/hook';

import SidebarMenuItem from './SidebarMenuItem';

const SidebarMenuCompany = () => {
  const { currentUser } = useAppSelector((state) => state.auth);

  return (
    <div>
      {currentUser?.feature_flags?.show_ai_recommendations && (
        <SidebarMenuItem to="recommendations" label="AI Recommendations" />
      )}
    </div>
  );
};

export default SidebarMenuCompany;
