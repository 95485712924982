import React, { useState } from 'react';

import styled from 'styled-components';

import { CareFeeDateType } from 'pages/price_healing/Fees/constants';

import Accomodation from './Accomodation/Accomodation';
import CareFees from './CareFees/CareFees';
import { CareTypesContext } from './CareTypesContext';
import CommunityFees from './CommunityFees';
import MedicationFees from './MedicationFees';
import { CareDataType } from './types';

type Props = {
  careType: CareDataType;
};

const Container = styled.div`
  display: flex;
  gap: 40px;
`;

const Column = styled.div`
  flex: 1;
`;

const CareTypes = ({ careType }: Props) => {
  const [careFees, setCareFees] = useState<CareFeeDateType>();
  return (
    <CareTypesContext.Provider
      value={{
        careType,
        careFees,
        setCareFees,
      }}
    >
      <Container>
        <Column>
          <CommunityFees />
          <CareFees />
          <MedicationFees />
        </Column>
        <Column>
          <Accomodation />
        </Column>
      </Container>
    </CareTypesContext.Provider>
  );
};

export default CareTypes;
