import { AGGREGATION_TYPE } from 'components/View/constants';

export const CMA_SUMMARY_DATA_ROW_TYPE = {
  COMMUNITY_NAME: 'COMMUNITY_NAME',
  ADDRESS: 'ADDRESS',
  WEBSITE: 'WEBSITE',
  PHONE: 'PHONE',
  REVIEWS: 'REVIEWS',
  INCENTIVES: 'INCENTIVES',
};

export const CMA_SUMMARY_LIVING_TYPE_DATA_ROW = 'CMA_SUMMARY_LIVING_TYPE_DATA_ROW';
export const CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW = 'CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW';
export const CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW = 'CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW';
export const CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON =
  'CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON';
export const CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW = 'CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW';
export const CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON =
  'CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON';
export const CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW = 'CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW';
export const CMA_SUMMARY_CARE_FEE_DATA_ROW = 'CMA_SUMMARY_CARE_FEE_DATA_ROW';

export const CMA_SUMMARY_TABLE_COLUMNS_OFFSET = 2;

export const CMA_SUMMARY_DATA_FEE_INCENTIVE_ROW_TYPE = {
  COMMUNITY_FEE: {
    id: 'COMMUNITY_FEE',
    label: 'Community Fee',
    field: 'communityFee',
  },
  SECOND_PERSON_FEE: {
    id: 'SECOND_PERSON_FEE',
    label: 'Second Person Fee',
    field: 'secondPersonFee',
  },
  PET_FEE: {
    id: 'PET_FEE',
    label: 'Pet Fee',
    field: 'petFee',
  },
  PARKING_FEE: {
    id: 'PARKING_FEE',
    label: 'Parking Fee',
    field: 'parkingFee',
  },
  RESPITE_FEE: {
    id: 'RESPITE_FEE',
    label: 'Respite Fee',
    field: 'respiteFee',
  },
  ADDITIONAL_FEE: {
    id: 'ADDITIONAL_FEE',
    label: 'Additional Fee',
    field: 'additionalFee',
  },
  MOVE_IN_FEE: {
    id: 'MOVE_IN_FEE',
    label: 'Move In Fee',
    field: 'moveInFee',
  },
  ENTRANCE_FEE: {
    id: 'ENTRANCE_FEE',
    label: 'Entrance Fee',
    field: 'entranceFee',
  },
  DEPOSIT: {
    id: 'DEPOSIT',
    label: 'Deposit',
    field: 'deposit',
  },
  EMERGENCY_PENDANT_FEE: {
    id: 'EMERGENCY_PENDANT_FEE',
    label: 'Emergency Pendant Fee',
    field: 'emergencyPendantFee',
  },
};

export const CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS = {
  SIMPLE_COMPARISON: 1,
  ADVANCED_COMPARISON: 2,
};

export const CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_LABELS = {
  [CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.SIMPLE_COMPARISON]: 'Simple Comparison',
  [CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON]: 'Advanced Comparison',
};

export const CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS = {
  RATE: 'Rate',
  CARE: 'Care',
  MEDICATION: 'Medication',
};

export const CMA_SUMMARY_ROOM_DATA_FILTER_TYPES = {
  AVG: {
    id: 1,
    label: 'Avg',
  },
  MIN: {
    id: 2,
    label: 'Min',
  },
  MAX: {
    id: 3,
    label: 'Max',
  },
};

export const CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELD_TYPE = {
  [CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.RATE]: [
    CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.AVG,
    CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MIN,
    CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MAX,
  ],
  [CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.CARE]: [
    CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.AVG,
    CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MIN,
    CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MAX,
  ],
  [CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.MEDICATION]: [
    CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.AVG,
    CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MIN,
    CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.MAX,
  ],
};

export const INDEPENDENT_LIVING_TYPE = 'Independent Living';
export const ROOM_COMPARISON_ROWS = 'roomComparisonRows';
export const ROOM_COMPARISON_TYPE = 'roomComparisonType';
export const CMA_SUMMARY_ROOM_COMPARISON_MINIMUM_FILTER_FIELDS = 2;
export const INITIAL_DISPLAYED_NUMBER_OF_COMMUNITIES = 5;
export const ANTD_TABLE_HEADER_CLASS = 'ant-table-header';
export const ANTD_TABLE_CONTAINER_CLASS = 'ant-table-container';
export const CMA_SUMMARY_TABLE_COLUMN_WIDTH = 280;
export const CMA_SUMMARY_ROOM_COMPARISON_FILTER_BASE_ROW = [
  [{ name: 'Rate', fieldType: CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.AVG.id }],
];

export const CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE = {
  DONT_DISPLAY: 0,
  PERCENTAGES: 1,
  AMOUNTS: 2,
};

export const CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE_LABELS = {
  [CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.DONT_DISPLAY]: "Don't Display",
  [CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.PERCENTAGES]: 'Percentages',
  [CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.AMOUNTS]: 'Amounts',
};

export const CMA_SUMMARY_INITIAL_FILTERS = {
  roomComparisonType: CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.SIMPLE_COMPARISON,
  roomComparisonRows: [],
  competitors: [],
  reviewSources: [],
  careTypes: [],
  roomTypes: [],
  viewOptions: {
    contactInformation: true,
    reviews: true,
    careTypes: true,
    roomType: true,
    communityFee: true,
    petFee: true,
    secondPersonFee: true,
    careFee: true,
    incentives: true,
    valueDisplay: CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.DONT_DISPLAY,
  },
  aggregationType: AGGREGATION_TYPE.AVG,
};
