import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { INCENTIVES_TABS } from './constants';
import { useIncentivesContext } from './IncentivesContext';

const Container = styled.div`
  display: flex;
  gap: 30px;
  padding: 0px 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--line-gray);
`;

const Item = styled.div`
  padding: 10px 0px;
  font-size: 14px;
  font-family: var(--font-bold);
  color: var(--text-secondary);
  cursor: pointer;

  &.active {
    color: var(--text-primary);
    border-bottom: 1px solid var(--text-primary);
    pointer-events: none;
  }
`;

const IncentivesTabs = () => {
  const { currentTab, setCurrentTab, setSearch } = useIncentivesContext();

  const handleTabChange = (value: string) => {
    setSearch('');
    setCurrentTab(value);
  };

  return (
    <Container>
      <Item
        className={classNames({ active: currentTab === INCENTIVES_TABS.UNIT })}
        onClick={() => handleTabChange(INCENTIVES_TABS.UNIT)}
      >
        Units
      </Item>
      <Item
        className={classNames({ active: currentTab === INCENTIVES_TABS.PROPOSAL })}
        onClick={() => handleTabChange(INCENTIVES_TABS.PROPOSAL)}
      >
        Sent Proposals
      </Item>
      <Item
        className={classNames({ active: currentTab === INCENTIVES_TABS.INCENTIVE })}
        onClick={() => handleTabChange(INCENTIVES_TABS.INCENTIVE)}
      >
        Active Incentives
      </Item>
      <Item
        className={classNames({ active: currentTab === INCENTIVES_TABS.ARCHIVE })}
        onClick={() => handleTabChange(INCENTIVES_TABS.ARCHIVE)}
      >
        Archived Incentives
      </Item>
    </Container>
  );
};

export default IncentivesTabs;
