import React from 'react';

import styled from 'styled-components';

import { useLatestShopNotesContext } from './LatestShopNotesContext';
import { getFileNameFromURL } from '../utils';

const Attachment = styled.div`
  display: flex;
  align-items: center;
`;

const AttachmentName = styled.a`
  color: var(--link-blue);
  margin-left: 5px;
  height: 16px;
  font-size: 12px;
  cursor: pointer;
`;

const LatestShopNotesAttachment = () => {
  const { latestShopNotes } = useLatestShopNotesContext();
  const fileUrl = latestShopNotes?.file_url;

  const file_name = getFileNameFromURL(latestShopNotes?.file_url);

  if (!file_name) {
    return null;
  }

  return (
    <Attachment>
      <img alt="attachment" src="/icons/attachment.svg" />
      <AttachmentName href={fileUrl} target="_blank" rel="noopener noreferrer">
        {file_name}
      </AttachmentName>
    </Attachment>
  );
};

export default LatestShopNotesAttachment;
