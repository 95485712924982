import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import styled from 'styled-components';

import LatestShopNotesAttachment from './LatestShopNotesAttachment';
import { LatestShopNotesContext } from './LatestShopNotesContext';
import LatestShopNotesDetails from './LatestShopNotesDetails';
import LatestShopNotesText from './LatestShopNotesText';
import { getCommunityShopNotes } from '../../../../apis/CommunityAPI';
import Header from '../../common/Header';
import { ShopNotesType } from '../types';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
`;

const LatestShopNotes = () => {
  const [latestShopNotes, setLatestShopNotes] = useState<ShopNotesType>();
  const { competitorId } = useParams();
  const { communityId } = useParams();
  const id = Number(competitorId || communityId);

  const { data } = useQuery({
    queryKey: ['community', 'shop', 'notes', id],
    queryFn: () => getCommunityShopNotes(id),
    onSuccess: (data) => {
      if (data.length > 0) {
        const latest = data[0];
        setLatestShopNotes(latest);
      }
    },
    enabled: Boolean(id),
  });

  const hasData = data?.length > 0;

  if (!hasData) {
    return null;
  }

  return (
    <LatestShopNotesContext.Provider
      value={{
        latestShopNotes,
      }}
    >
      <Container>
        <Header title="Latest Shop Notes" addon={<LatestShopNotesDetails />} />
        <Content>
          <LatestShopNotesText />
          <LatestShopNotesAttachment />
        </Content>
      </Container>
    </LatestShopNotesContext.Provider>
  );
};

export default LatestShopNotes;
