import React from 'react';

import styled from 'styled-components';

import AccomodationGroupCollapseHeaderStats from './AccomodationGroupCollapseHeaderStats';
import { useCareTypesContext } from '../CareTypesContext';
import { LivingInfoGroupedDataType } from '../types';

type Props = {
  item: LivingInfoGroupedDataType;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Column = styled.div`
  margin-left: 4px;
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary);
`;

const Type = styled.div`
  font-family: var(--font-regular);
  font-size: 12px;
  color: var(--text-secondary);
`;

const AccomodationGroupCollapseHeader = ({ item }: Props) => {
  const { careType } = useCareTypesContext();
  return (
    <Container>
      <Column>
        <Title>{item.group_name}</Title>
        <Type>Type · {careType?.type}</Type>
      </Column>
      <Column>
        <AccomodationGroupCollapseHeaderStats data={item.apartments} />
      </Column>
    </Container>
  );
};

export default AccomodationGroupCollapseHeader;
