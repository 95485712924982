import React from 'react';

import styled from 'styled-components';

import { useIncentivesDataContext } from 'components/Incentives/IncentivesDataContext';

import IncentivesTableUnitsUnitStatus from './IncentivesTableUnitsUnitStatus';
import { IncentiveType, UnitType } from '../../types';

interface Props {
  hideIcon?: boolean;
  record: UnitType;
}

const PaddingPlaceholder = styled.div`
  width: 44px;
`;

const IncentivesTableUnitsUnit = ({ record, hideIcon = false }: Props) => {
  const { selectionMode, archiveMode } = useIncentivesDataContext();
  const incentive = record?.incentives?.find((item: IncentiveType) => item.id === record.incentive_id);

  return (
    <span style={{ display: 'flex', flexFlow: 'row' }}>
      {!hideIcon && !selectionMode && incentive && (
        <span
          style={{
            paddingTop: 4,
            paddingRight: 20,
          }}
        >
          <img
            src={archiveMode ? '/icons/price-tag-gray.svg' : '/icons/price-tag-green.svg'}
            alt="Incentive"
            style={{
              width: 24,
              height: 24,
            }}
          />
        </span>
      )}
      {!hideIcon && !incentive && !selectionMode && <PaddingPlaceholder />}
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span>{record?.number}</span>
        <span
          style={{
            fontSize: 12,
            color: 'var(--text-secondary)',
          }}
        >
          {record?.name && <span>{record.name} · </span>}
          {record?.square_feet && <span>{record?.square_feet?.toLocaleString()} sq ft</span>}
        </span>
        <IncentivesTableUnitsUnitStatus unit={record} />
      </span>
    </span>
  );
};

export default IncentivesTableUnitsUnit;
