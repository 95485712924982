import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { CareFeeDateType } from 'pages/price_healing/Fees/constants';
import { mapCareFeeResponseToData } from 'pages/price_healing/Fees/utils';
import { useAppSelector } from 'store/hook';

import { CareDataType, CommunityFeesType, LivingInfoGroupedDataType } from './CareTypes/types';
import { groupLivingInfoByApartmentType } from './CareTypes/utils';
import CommunityInfoContent from './CommunityInfoContent';
import { CommunityInfoContext } from './CommunityInfoContext';
import CommunityInfoHeader from './CommunityInfoHeader';
import { CommunityDataType } from './types';
import { getCareFees, getFeesIncentives, getLivingInfo } from '../../apis/CommunityAPI';

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

const Content = styled.div`
  padding: 0 20px;
  overflow: auto;
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 900;
  }
  .ant-tabs-tab-btn {
    color: var(--text-secondary);
  }
`;

const CommunityInfo = () => {
  const careTypes = useAppSelector((state) => state.global.livingtypes);
  const [communityInfo, setCommunityInfo] = useState<CommunityDataType>();
  const [mappedCareData, setMappedCareData] = useState<CareDataType[]>([]);

  const { communityId, competitorId } = useParams();
  const id = Number(competitorId || communityId);

  const { data: communityFees, isFetched: communityFeesFetched } = useQuery({
    queryKey: ['community', 'feesIncentives', id],
    queryFn: () => getFeesIncentives(id, { active: 1 }),
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
  });

  const { data: careFees, isFetched: careFeesFetched } = useQuery({
    queryKey: ['community', 'careFees', id],
    queryFn: () => getCareFees(id, { active: 1 }),
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
  });

  const { data: livingInfos, isFetched: livingInfosFetched } = useQuery({
    queryKey: ['community', 'livingInfo', id],
    queryFn: () => getLivingInfo(id, { active: 1 }),
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (careTypes && careFeesFetched && communityFeesFetched && livingInfosFetched) {
      const mappedData = careTypes.map((careType) => {
        const communityFee: CommunityFeesType | null =
          communityFees?.find((fee: CommunityFeesType) => fee.living_type_id === careType.id) || null;

        const careFeeUnmapppedData = careFees?.find((fee: any) => fee.living_type_id === careType.id) || null;
        const careFee: CareFeeDateType | null = careFeeUnmapppedData
          ? mapCareFeeResponseToData(careFeeUnmapppedData)
          : null;

        const livingInfoUnGroupData = livingInfos?.filter((info: any) => info.living_type_id === careType.id) || [];
        const livingInfo: LivingInfoGroupedDataType[] = groupLivingInfoByApartmentType(livingInfoUnGroupData);

        return {
          ...careType,
          careFee,
          communityFee,
          livingInfo,
        };
      });

      setMappedCareData(mappedData);
    }
  }, [careFees, communityFees, livingInfos]);

  return (
    <CommunityInfoContext.Provider
      value={{
        communityInfo,
        setCommunityInfo,
        mappedCareData,
        setMappedCareData,
      }}
    >
      <Container>
        <CommunityInfoHeader />
        <Content>
          <CommunityInfoContent />
        </Content>
      </Container>
    </CommunityInfoContext.Provider>
  );
};

export default CommunityInfo;
