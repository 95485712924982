import React from 'react';

import { createCMASummaryFilter } from 'apis/FilterAPI';
import ConfirmModal from 'common/Modal/ConfirmModal';
import { transformCMASummaryFiltersOutbound } from 'components/CMASummary/filterUtils';
import {
  CMASummaryFilters,
  CMASummaryFiltersOutbound,
  CMASummary as CMASummaryType,
} from 'components/CMASummary/types';
import { useRootContext } from 'components/layout/RootContext';

import { useCommunityViewContext } from '../CommunityViewContext';

type Props = {
  CMASummary?: CMASummaryType;
  open: boolean;
  onCancel: () => void;
};

const CMASummarySaveFilterModal = ({ CMASummary, open, onCancel }: Props) => {
  const { showMessage } = useRootContext();
  const { viewFilters } = useCommunityViewContext();
  const filters = viewFilters as CMASummaryFilters;

  const handleCreate = async () => {
    if (viewFilters && CMASummary) {
      const payload: CMASummaryFiltersOutbound = transformCMASummaryFiltersOutbound(filters);
      await createCMASummaryFilter(CMASummary?.id, payload)
        .then(() => {
          onCancel();
          showMessage('success', "CMA Summary filter created.")
        })
        .catch(() => showMessage('error', 'Trouble creating new CMA Summary filter.'));
    }
  };

  return (
    <ConfirmModal
      open={open}
      title={'Save Filter'}
      description={'Would you like to save this filter so that it persists next time you visit this page?'}
      confirmLabel={'Save'}
      onConfirm={handleCreate}
      onCancel={onCancel}
      disabled={false}
    />
  );
};

export default CMASummarySaveFilterModal;
