import React from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import { Divider } from 'components/lib/Divider';

import IncentivesProposalModalProposalLeadSearch from './IncentivesProposalModalProposalLeadSearch';
import Input from '../../lib/Input';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  margin-top: 20px;
`;

const Label = styled.span`
  display: flex;
  font-family: var(--font-bold);
  font-size: 14px;
  justify-content: end;
  width: 75px;
  color: var(--text-primary);
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  margin-bottom: 10px;
`;

const IncentivesProposalModalProposalLead = () => (
  <Container>
    <Title>Proposal Recipient</Title>
    <Divider />
    <IncentivesProposalModalProposalLeadSearch />
    <Divider />
    <FormContainer>
      <Form.Item
        name={['lead', 'first_name']}
        label={<Label>First Name</Label>}
        rules={[{ required: true, message: 'This field is required!' }]}
        style={{ height: 36 }}
      >
        <Input placeholder={"Enter the recipient's first name"} />
      </Form.Item>
      <Form.Item
        name={['lead', 'last_name']}
        label={<Label>Last Name</Label>}
        rules={[{ required: true, message: 'This field is required!' }]}
        style={{ height: 36 }}
      >
        <Input placeholder={"Enter the recipient's last name"} />
      </Form.Item>
      <Form.Item
        name={['lead', 'email']}
        label={<Label>Email</Label>}
        rules={[
          {
            required: true,
            message: 'This field is required!',
          },
          {
            type: 'email',
            message: 'Please enter a valid email address!',
          },
        ]}
        style={{ height: 36 }}
      >
        <Input placeholder={"Enter the recipient's email address"} />
      </Form.Item>
      <Form.Item
        name={['lead', 'phone']}
        label={<Label>Phone</Label>}
        rules={[{ required: true, message: 'This field is required!' }]}
        style={{ height: 36 }}
      >
        <Input placeholder={"Enter the recipient's phone number"} />
      </Form.Item>
      <Form.Item hidden name={['lead', 'prospect_id']} />
    </FormContainer>
  </Container>
);

export default IncentivesProposalModalProposalLead;
