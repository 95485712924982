import { WIDGET_TYPES } from 'common/Widgets/constants';

export const RECOMMENDATION_CATEGORIES = {
  rates: {
    label: 'Rates',
    value: 'rates',
    background: '#e7fceb',
    color: 'var(--positive-green)',
    gradientFrom: '#17dcae',
    gradientTo: 'var(--positive-green)',
  },
  sentiment: {
    label: 'Sentiment',
    value: 'sentiment',
    background: '#fff5e6',
    color: 'var(--inactive-orange)',
    gradientFrom: '#f5d47d',
    gradientTo: 'var(--inactive-orange)',
  },
  occupancy: {
    label: 'Occupancy',
    value: 'occupancies',
    background: '#f7f7f7',
    color: '#666666',
    gradientFrom: '#d7d7d7',
    gradientTo: '#bfbfbf',
  },
  incentives: {
    label: 'Incentives',
    value: 'incentives',
    background: '#e4ffff',
    color: '#22cfd1',
    gradientFrom: '#47e9ea',
    gradientTo: '#22cfd1',
  },
  fees: {
    label: 'Fees',
    value: 'fees',
    background: '#efefff',
    color: 'var(--competitor-purple)',
    gradientFrom: '#9898ff',
    gradientTo: 'var(--competitor-purple)',
  },
};

export const UNDEFINED_CATEGORY = {
  label: 'Undefined',
  background: '#f9f9f9',
  color: '#666666',
  gradientFrom: '#d7d7d7',
  gradientTo: '#bfbfbf',
};

export const CATEGORY_VALUE_WIDGETS = {
  pricing: [WIDGET_TYPES.MARKET_RATE_GRAPH],
  community_fee: [WIDGET_TYPES.COMMUNITY_FEES_GRAPH],
  occupancy: [WIDGET_TYPES.OCCUPANCY_GRAPH],
  care_level: [WIDGET_TYPES.CARE_FEES_GRAPH],
  review_score: [WIDGET_TYPES.RATING_GRAPH],
  review_text: [WIDGET_TYPES.REVIEWS],
};
