import React from 'react';
import { Outlet } from 'react-router-dom';

import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  margin-top: 10px;
`;

const CommunityInfoCompetitorContent = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  );
};

export default CommunityInfoCompetitorContent;
