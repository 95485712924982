import React, { ReactNode } from 'react';

import styled from 'styled-components';

import Button from '../../components/lib/Button';
import Modal from '../../components/lib/Modal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  color: var(--text-secondary);
  white-space: pre-wrap;
`;

const Content = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  width: 120px;
  border-radius: 4px;
`;

const StyledModal = styled(Modal)`
  .ant-btn-primary:disabled {
    opacity: 0.3;
    color: #fff;
    background: #275aff;
  }
`;

interface Props {
  open: boolean;
  title: string;
  description: ReactNode;
  children?: ReactNode;
  loading?: boolean;
  confirmLabel: string;
  onConfirm: any;
  onCancel?: any;
  disabled?: boolean;
}

const ConfirmModal = ({
  open,
  title,
  description,
  loading,
  confirmLabel,
  onConfirm,
  onCancel,
  disabled,
  children,
}: Props) => {
  return (
    <StyledModal
      open={open}
      title={title}
      closable={true}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={[
        <StyledButton key="confirm" type="primary" loading={loading} disabled={disabled} onClick={onConfirm}>
          {confirmLabel}
        </StyledButton>,
        <StyledButton key="cancel" disabled={loading} onClick={onCancel}>
          Cancel
        </StyledButton>,
      ]}
    >
      <Container>
        <Description>{description}</Description>
        <Content>{children}</Content>
      </Container>
    </StyledModal>
  );
};

export default ConfirmModal;
