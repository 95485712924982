import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import SidebarMenuCategoryHeader from './SidebarMenuCategoryHeader';
import SidebarMenuItem from './SidebarMenuItem';
import SidebarMenuItemBetaTag from './SidebarMenuItemBetaTag';
import { ACTOR_TYPE } from '../../../constants';
import { isIncentivesCorporate, isIncentivesSalesperson } from '../../../helpers/permissions';

const Container = styled.div`
  margin-top: 10px;
`;

const SidebarMenuOther = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { selectedCommunity, selectedManagementCompany, selectedCompany } = useAppSelector((state) => state.global);

  const showIncentives =
    currentUser && selectedCompany && (isIncentivesCorporate(currentUser) || isIncentivesSalesperson(currentUser));
  const showSettings = currentUser?.feature_flags?.show_beta_settings;
  const showBetaCommunityData = currentUser?.feature_flags?.show_beta_community_data;

  return (
    <Container>
      <SidebarMenuCategoryHeader title={'OTHER'} />
      {Boolean(selectedCommunity) && (
        <SidebarMenuItem to={`/${selectedCommunity?.type}/${selectedCommunity?.id}/info`} label={'Community Data'} />
      )}

      {Boolean(selectedCommunity) && showBetaCommunityData && (
        <SidebarMenuItem
          to={`/${selectedCommunity?.type}/${selectedCommunity?.id}/info-beta`}
          label={<SidebarMenuItemBetaTag label="Community Data" />}
        />
      )}

      {Boolean(selectedCommunity) && (
        <SidebarMenuItem to={`/${selectedCommunity?.type}/${selectedCommunity?.id}/incentives`} label={'Incentives'} />
      )}

      {showIncentives && (
        <SidebarMenuItem
          to={selectedManagementCompany ? `/incentives/${selectedManagementCompany?.id}` : '/incentives'}
          label={'Proposals'}
        />
      )}
      {selectedCommunity?.type === ACTOR_TYPE.COMMUNITY && (
        <SidebarMenuItem to={`/community/${selectedCommunity?.id}/competitors`} label={'Competitors'} />
      )}

      {Boolean(selectedCommunity?.type === ACTOR_TYPE.COMMUNITY) && showBetaCommunityData && (
        <SidebarMenuItem
          to={`/${selectedCommunity?.type}/${selectedCommunity?.id}/competitor`}
          label={<SidebarMenuItemBetaTag label="Competitors" />}
          pattern={`/${selectedCommunity?.type}/${selectedCommunity?.id}/competitor/*`}
        />
      )}

      {showSettings && <SidebarMenuItem to={`/preferences/${selectedManagementCompany?.id}`} label={'Settings'} />}
    </Container>
  );
};

export default SidebarMenuOther;
