import React from 'react';

import SelectAdvancedFilterDate from 'components/lib/SelectAdvancedFilterDate';

import { SHOP_REQUEST_DUE_DATE_RANGES } from '../constants';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';

const ShopRequestsDueDateEndFilter = () => {
  const { filters, setFilters } = useShopRequestsDataContext();
  const minDate =
    filters.dueDateStart && new Date(filters.dueDateStart) > new Date() ? new Date(filters.dueDateStart) : new Date();
  return (
    <SelectAdvancedFilterDate
      selected={filters.dueDateEnd ? new Date(filters.dueDateEnd) : new Date()}
      minDate={minDate}
      dueDate={filters.dueDateEnd}
      label="Due Date End"
      onChange={(date: any) =>
        setFilters({
          ...filters,
          dueDateEnd: date,
          dueDateRange: SHOP_REQUEST_DUE_DATE_RANGES.ALL_REQUESTS,
        })
      }
    />
  );
};

export default ShopRequestsDueDateEndFilter;
