import React from 'react';

import { Table } from 'antd';
import styled from 'styled-components';

export const CommunityIncentivesStyledTable = styled(Table)`
  &&&&&& {
    width: 100%;

    tr {
      font-family: var(--font-regular);
      font-weight: 200;
      color: var(--text-primary);
      background-color: #fff;
      
      &.mixed-selection {
          .ant-table-selection-column {
        visibility: hidden;
      }
      
      &.selectable {
        .ant-table-selection-column {
          visibility: visible;
        }
      }
    }

    td {
      padding: 10px 10px 10px 10px;
      vertical-align: top;

      &.ant-table-cell {
        background: white;
      }

      &.ant-table-cell-row-hover {
        .hover-visible {
          visibility: visible;
        }
      }
    }

    th {
      font-family: var(--font-bold);
      text-transform: uppercase;
      padding-left: 0;
      background-color: #fff;

      &.ant-table-cell::before {
        background-color: transparent;
      }
    }
  }
  .ant-table-thead {
    .padded-column-header {
      padding-left: 44px;
    }
  }
`;

export default CommunityIncentivesStyledTable;
