import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Upload as AntDUpload } from 'antd';
import { UploadProps } from 'antd/es/upload/Upload';
import styled from 'styled-components';

const StyledUpload = styled(AntDUpload)`
  color: var(--link-blue);
  margin-left: 5px;
  font-size: 12px;
  cursor: pointer;
`;

const Upload = (props: UploadProps) => {
  return (
    <StyledUpload {...props}>
      <PlusOutlined style={{ color: 'var(--link-blue)', fontSize: 12, marginRight: '3px' }} />
      Upload Attachments
    </StyledUpload>
  );
};

export default Upload;
