import React, { useEffect, useRef } from 'react';

import { getDocument } from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';

interface PDFThumbnailProps {
  height?: number;
  width?: number;
  fileUrl: string;
  onError: React.Dispatch<React.SetStateAction<boolean>>;
}

const PDFThumbnail: React.FC<PDFThumbnailProps> = ({ fileUrl, onError, height = 134, width = 134 }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const renderThumbnail = () => {
      if (!canvasRef.current) return;

      getDocument(fileUrl)
        .promise.then((pdf) => pdf.getPage(1))
        .then((page) => {
          const originalViewport = page.getViewport({ scale: 1 });
          const scale = Math.min(width / originalViewport.width, height / originalViewport.height);
          const viewport = page.getViewport({ scale });
          const canvas = canvasRef.current;
          const context = canvas?.getContext('2d');

          if (canvas && context) {
            canvas.width = width;
            canvas.height = height;
            context.clearRect(0, 0, canvas.width, canvas.height);

            const offsetX = (canvas.width - viewport.width) / 2;
            const offsetY = (canvas.height - viewport.height) / 2;

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
              transform: [1, 0, 0, 1, offsetX, offsetY],
            };

            return page.render(renderContext).promise;
          }
        })
        .catch(() => {
          onError(true);
        });
    };

    renderThumbnail();
  }, [fileUrl]);

  return <canvas ref={canvasRef} />;
};

export default PDFThumbnail;
