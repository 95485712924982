import React from 'react';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { parseTextFromMarkDown } from './utils';

const Container = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-primary);
  font-family: var(--font-regular);

  a {
    color: #1f58b0;
    text-decoration: none;
    font-weight: 500;
    font-family: var(--font-regular);
    font-size: 16px;
  }

  strong {
    color: var(--text-primary);
    font-family: var(--font-bold);
    font-weight: 900;
  }
`;

interface RecommendationMarkdownProps {
  variant?: 'html' | 'plain';
  children: string;
}

const RecommendationMarkdown = ({ children, variant = 'html' }: RecommendationMarkdownProps) => {
  if (variant === 'plain') {
    return <Container>{parseTextFromMarkDown(children)}</Container>;
  }

  return (
    <Container>
      <Markdown
        urlTransform={(url: string) => {
          const urlParts = url.split(':');
          if (urlParts.length !== 2) {
            return url;
          }
          switch (urlParts[0]) {
            case 'community':
              return `/community/${urlParts[1]}`;
            default:
              return url;
          }
        }}
        components={{
          a: ({ node, children, href, ...props }) => {
            return (
              <Link to={String(href)} target="_blank" rel="noopener noreferrer" {...props}>
                {children}
              </Link>
            );
          },
        }}
      >
        {children}
      </Markdown>
    </Container>
  );
};

export default RecommendationMarkdown;
