import { FeesConfigurationType } from './types';

export const FREQUENCY_VALUES = {
  HALF_MONTH: 0.5,
  ONE_MONTH: 1,
  TWO_MONTHS: 2,
  THREE_MONTHS: 3,
};

export const FREQUENCY_OPTIONS = [
  {
    value: FREQUENCY_VALUES.HALF_MONTH,
    label: `Equal to half month's rent`,
  },
  {
    value: FREQUENCY_VALUES.ONE_MONTH,
    label: `Equal to one month's rent`,
  },
  {
    value: FREQUENCY_VALUES.TWO_MONTHS,
    label: `Equal to two month's rent`,
  },
  {
    value: FREQUENCY_VALUES.THREE_MONTHS,
    lalabel: `Equal to three month's rent`,
  },
];

export const FEES_CONFIGURATIONS: FeesConfigurationType[] = [
  { title: 'Second Person Fee', key: 'second_person_fee' },
  { title: 'Pet Fee', key: 'pet_fee' },
  { title: 'Parking Fee', key: 'parking_fee' },
  { title: 'Respite Fee', key: 'respite_fee' },
  { title: 'Additional Fee', key: 'additional_fee' },
  { title: 'Move-in Fee', key: 'move_in_fee' },
  { title: 'Entrance Fee', key: 'entrance_fee' },
  { title: 'Emergency Pendant Fee', key: 'emergency_pendant_fee' },
];
