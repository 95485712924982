import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import CommunityInfoCompetitorMenuItem from './CompetitorInfoCompetitorMenuItem';
import { SELECTOR_ITEM_TYPES } from '../layout/Sidebar/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Divider = styled.div`
  height: 10px;
  width: 100%;
  border-top: 1px solid var(--line-gray);
  border-bottom: 1px solid var(--line-gray);
`;

const CommunityInfoCompetitorMenu = () => {
  const navigate = useNavigate();
  const { selectedCommunity, communityCompetitorsList } = useAppSelector((state) => state.global);

  const isBasePath = location.pathname === `/community/${selectedCommunity?.id}/competitor`;

  const competitors = communityCompetitorsList
    .filter((competitor) => selectedCommunity?.id == competitor.parent_id)
    .map((item) => ({
      id: item.id,
      type: SELECTOR_ITEM_TYPES.COMPETITOR,
      name: item.apartmentname,
      address: item.apartmentaddress,
      description: item.apartmentaddress,
    }));

  useEffect(() => {
    if (isBasePath && competitors.length) {
      navigate(`/community/${selectedCommunity?.id}/competitor/${competitors[0].id}/info-beta`);
    }
  }, [competitors, selectedCommunity?.id, navigate]);

  return (
    <Container>
      <Divider />
      {competitors.map((item) => (
        <CommunityInfoCompetitorMenuItem
          key={item.id}
          to={`/community/${selectedCommunity?.id}/competitor/${item.id}/info-beta`}
          label={item.name}
          description={item.address}
        />
      ))}
    </Container>
  );
};

export default CommunityInfoCompetitorMenu;
