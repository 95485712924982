import React from 'react';

import styled from 'styled-components';

import CommunityIncentivesFiltersCareType from './filters/CommunityIncentivesFiltersCareType';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-right: 10px;
`;

const CommunityIncentivesFilters = () => {
  return (
    <Container>
      <CommunityIncentivesFiltersCareType />
    </Container>
  );
};

export default CommunityIncentivesFilters;
