import React from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import { CommunityIncentivesContext } from './CommunityIncentivesContext';
import CommunityIncentivesControls from './CommunityIncentivesControls';
import CommunityIncentivesTable from './CommunityIncentivesTable';
import Header from './header/Header';
import { getIncentives } from '../../apis/CommunityAPI';
import { useAppSelector } from '../../store/hook';
import useDataList from '../../utils/hooks/useDataList';

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

const Content = styled.div`
  padding: 0 20px;
  overflow: auto;
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 900;
  }
  .ant-tabs-tab-btn {
    color: var(--text-secondary);
  }
`;

const CommunityIncentives = () => {
  const { selectedCommunity } = useAppSelector((state: any) => state.global);
  const { filters, setFilters } = useDataList({
    initialState: {
      filters: {
        careTypes: [],
      },
    },
  });

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['incentives', selectedCommunity, filters],
    queryFn: () =>
      getIncentives(selectedCommunity.id, {
        status: 1,
        competitors: [selectedCommunity.id],
        care_types: filters.careTypes,
      }),
    enabled: Boolean(selectedCommunity),
    refetchOnWindowFocus: false,
  });

  return (
    <CommunityIncentivesContext.Provider
      value={{
        data,
        isLoading,
        isFetching,
        filters,
        setFilters,
        refetch,
      }}
    >
      <Container>
        <Header />
        <Content>
          <CommunityIncentivesControls />
          <CommunityIncentivesTable />
        </Content>
      </Container>
    </CommunityIncentivesContext.Provider>
  );
};

export default CommunityIncentives;
