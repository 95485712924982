import React from 'react';

import { useAccomodationContext } from './AccomodationContext';
import AccomodationGroupCollapseHeader from './AccomodationGroupCollapseHeader';
import AccomodationGroupCollapseItem from './AccomodationGroupCollapseItem';
import AccomodationGroupStyledCollapse from './AccomodationGroupStyledCollapse';

const { Panel } = AccomodationGroupStyledCollapse;

const Accomodation = () => {
  const { livingInfoData } = useAccomodationContext();

  const allKeys = livingInfoData.map((panel) => panel.group_id);

  return (
    <AccomodationGroupStyledCollapse defaultActiveKey={allKeys}>
      {livingInfoData.map((group) => (
        <Panel header={<AccomodationGroupCollapseHeader item={group} />} key={group.group_id}>
          {group.apartments.map((item) => (
            <AccomodationGroupCollapseItem key={item.id} item={item} />
          ))}
        </Panel>
      ))}
    </AccomodationGroupStyledCollapse>
  );
};

export default Accomodation;
