import { RateTrendsType } from './types';

export const formatUrl = (url: string) => {
  if (!url) return '';
  return url.startsWith('http') ? url : `https://${url}`;
};

export const transformRateTrendsInbound = (rateTrends: RateTrendsType) => ({
  annualRateRncreaseMin: parseFloat(rateTrends.annual_rate_increase_min),
  annualRateRncreaseMax: parseFloat(rateTrends.annual_rate_increase_max),
});

export const getFileNameFromURL = (url: string | undefined) => {
  if (!url) return '';
  const urlObject = new URL(url);
  const pathname = urlObject.pathname;
  const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);

  return fileName || '';
};
