import React from 'react';

import styled from 'styled-components';

import { CARE_FEES_TYPES } from 'pages/price_healing/Fees/constants';

import CareFeesLevels from './CareFeesLevels';
import Header from '../../common/Header';
import { useCareTypesContext } from '../CareTypesContext';
import FeesItems from '../common/FeesItems';
import { getLevelsAverage } from '../utils';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const CareFees = () => {
  const { careType } = useCareTypesContext();
  const careFees = careType?.careFee;

  if (!careFees) {
    return null;
  }

  const levelAverage = getLevelsAverage(careFees);
  const showLevels = careFees?.feesTypeId == CARE_FEES_TYPES.LEVELS;

  return (
    <Container>
      <Header title="Care Fees" />
      <Content>
        {!showLevels && <FeesItems title={careFees?.feesType || ''} amount={careFees.averageCareCost} />}
        {showLevels && levelAverage && <FeesItems title="Levels" amount={levelAverage} subtitle="Average" />}
        {showLevels && <CareFeesLevels />}
      </Content>
    </Container>
  );
};

export default CareFees;
