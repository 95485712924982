import { marked } from 'marked';

import { RecommendationDetailsType, RecommendationType } from 'types/recommendations';

import { CATEGORY_VALUE_WIDGETS, RECOMMENDATION_CATEGORIES, UNDEFINED_CATEGORY } from './constants';

export const getCategoryOrDefaultScheme = (category: string) =>
  RECOMMENDATION_CATEGORIES[category as keyof typeof RECOMMENDATION_CATEGORIES] || UNDEFINED_CATEGORY;

export const getRecommendationDefaultCategoryScheme = (recommendation: RecommendationType) => {
  const dataPoints = recommendation.config.data_points.sort((dp1, dp2) => dp1.order - dp2.order);
  if (dataPoints.length === 0) {
    return UNDEFINED_CATEGORY;
  }

  const categoryName = dataPoints[0].category;
  return getCategoryOrDefaultScheme(categoryName);
};

export const getAvailableWidgets = (recommendation: RecommendationDetailsType) => {
  const dataPointValues = recommendation.config.data_points.map((dp) => dp.value);
  const allWidgets = dataPointValues.reduce((acc, val) => {
    if (val in CATEGORY_VALUE_WIDGETS) {
      const widgets = CATEGORY_VALUE_WIDGETS[val as keyof typeof CATEGORY_VALUE_WIDGETS] || [];
      return acc.concat(widgets);
    }

    return acc;
  }, [] as number[]);

  return Array.from(new Set(allWidgets));
};

export const parseTextFromMarkDown = (value: string) => {
  const htmlString = marked(value, { async: false });
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const walker = document.createTreeWalker(doc, NodeFilter.SHOW_TEXT);

  const textList = [];
  let currentNode: Node | null = walker.currentNode;

  while (currentNode) {
    textList.push(currentNode.textContent);
    currentNode = walker.nextNode();
  }

  return textList;
};
