import React from 'react';

interface ChevronRightProps {
  fill: string;
  width: number;
  height: number;
}

const defaultProps = {
  fill: 'var(--gray-dark)',
  width: 24,
  height: 24,
};

const ChevronRight = ({ fill, width, height }: ChevronRightProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path fill={fill} fillRule="nonzero" d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </g>
    </svg>
  );
};

ChevronRight.defaultProps = defaultProps;

export default ChevronRight;
