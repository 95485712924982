import React from 'react';

import { CaretRightFilled } from '@ant-design/icons/lib';
import { Tabs } from 'antd';

import CareTypes from './CareTypes/CareTypes';
import { useCommunityInfoContext } from './CommunityInfoContext';
import General from './General/General';

const CommunityInfoContent = () => {
  const { mappedCareData } = useCommunityInfoContext();
  const careTypesTabs = mappedCareData
    .filter((careType) => careType.communityFee || careType.careFee || careType.livingInfo.length)
    .map((tab: any) => ({
      label: tab.type,
      key: tab.type,
      children: <CareTypes careType={tab} />,
    }));

  const tabs = [
    {
      label: 'General',
      key: 'general',
      children: <General />,
    },
    ...careTypesTabs,
  ];

  return (
    <Tabs
      moreIcon={<CaretRightFilled />}
      tabPosition="top"
      tabBarGutter={30}
      size="small"
      destroyInactiveTabPane
      items={tabs}
    />
  );
};

export default CommunityInfoContent;
