import React from 'react';

import classNames from 'classnames';

import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';

type Props = {
  roomTypes: any;
};

const RoomTypeFilter = ({ roomTypes }: Props) => {
  const { apartmentTypes, setViewFilters } = useCommunityViewContext();
  const handleSelect = (values: number[]) => {
    setViewFilters((prevState: any) => ({ ...prevState, roomTypes: values.map((item: any) => item.value) }));
  };

  return (
    <SelectAdvancedFilter
      className={classNames({ active: roomTypes && roomTypes.length > 0 })}
      placeholder={'Room Type'}
      options={apartmentTypes?.map((item) => ({
        label: item.type,
        value: item.id,
      }))}
      defaultValue={roomTypes ?? []}
      onSelect={handleSelect}
    />
  );
};

export default RoomTypeFilter;
