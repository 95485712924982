import React from 'react';

import styled from 'styled-components';

import { useLatestShopNotesContext } from './LatestShopNotesContext';
import LatestShopNotesMultiParagraphText from './LatestShopNotesMultiParagraphText';
import LatestShopNotesSingleParagraphText from './LatestShopNotesSingleParagraphText';

const Container = styled.div``;

const LatestShopNotesText = () => {
  const { latestShopNotes } = useLatestShopNotesContext();
  const text = latestShopNotes?.request_notes;
  const isMultiParagraph = text?.includes('\n');

  return (
    <Container>
      {!isMultiParagraph && <LatestShopNotesSingleParagraphText />}
      {isMultiParagraph && <LatestShopNotesMultiParagraphText />}
    </Container>
  );
};

export default LatestShopNotesText;
